import {
  styled as mstyled,
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  makeStyles,
  createMuiTheme,
  useMediaQuery,
  MuiThemeProvider,
} from '@material-ui/core';
import DataGrid, { Column } from 'react-data-grid';
import { ToggleButtonGroup } from '@material-ui/lab';
import React, { ReactElement, useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Provider, KeepAlive } from 'react-keep-alive';
import styled from 'styled-components';

import { useSort } from '../features/Sort';

import FilterButton from '../components/FilterButton';

import CCInterviewReview from '../fragments/CCInterviewReview';
import CCProfile from '../fragments/CCProfile';
import CounterOfferDetail from '../fragments/CounterOfferDetail';
import InterviewResultDetail from '../fragments/InterviewResultDetail';
import OfferDetail from '../fragments/OfferDetail';

import { VirtualRecomm } from '../modals/VirtualRecomm';
import { VirtualRecommConfirm } from '../modals/VirtualRecommConfirm';

import { ViewProps } from '../props/ViewProps';

import {
  fetchCC,
  fetchCCProfile,
  fetchCCRecommendations,
  removeRecommendation,
} from '../helpers/Api';
import Format from '../helpers/Format';

import CCColumns, {
  CCProfileData,
  CCRowModel,
  CCTalentRowModel,
} from '../schemas/CC';
import { RecommendationStage } from '../schemas/Recommendation';
import { VirtualRecommendation } from '../schemas/VirtualRecommendation';

import '@fortawesome/fontawesome-free/css/all.css';
import '../css/datagrid.css';
import IntentDetail from './IntentDetail';

const UpperWrapper = styled.section`
  padding: 40px 36px 32px;
`;
const TitleBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const TitleText = styled.h1`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  font-size: 34px;
  font-weight: bold;
  color: #222222;
`;
const Spacer = styled.span`
  margin: 0 auto;
`;
const InfoBarWrapper = styled.div`
  margin-top: 16px;
`;
const InfoBarInnerWrapper = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  & + & {
    margin-top: 14px;
  }
`;
const InfoHeaderText = styled.h2`
  margin: 0;
  width: 120px;
  font-size: 14px;
  font-weight: normal;
  color: #999999;
`;
const InfoText = styled.p`
  font-size: 14px;
  font-weight: normal;
  color: #222222;
`;
const MenuItemWrapper = styled.div`
  display: inline-block;
`;
const MenuItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0;
`;
const MenuTextBold = styled.p`
  min-width: 200px;
  font-size: 14px;
  font-weight: bold;
  color: #222222;
`;
const MenuTextNormalSpan = styled.span`
  font-weight: normal;
`;
const MenuLongTextBold = styled.p`
  min-width: 300px;
  font-size: 14px;
  font-weight: bold;
  color: #222222;
  margin-right: 20px;
`;
const MenuTextNormal = styled.p`
  font-size: 16px;
  font-weight: normal;
  color: #222222;
`;
const MenuLine = styled.hr`
  margin: 0;
  border: none;
  height: 1px;
  background-color: #dddddd;
`;
const MenuSpacer = styled.div`
  margin-top: 24px;
`;
const PositionEmptyText = styled.p`
  font-size: 14px;
  font-weight: normal;
  color: #999999;
`;
const SplitBar = styled.hr`
  margin: 0;
  border: 0;
  height: 1px;
  background-color: #999999;
`;
const LowerWrapper = styled.section`
  padding: 32px 36px 36px;
`;
const HiringStatsTitleText = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #222222;
`;
const FilterBarWrapper = styled.div`
  margin-top: 32px;
  overflow-x: scroll;
  white-space: nowrap;
`;
const FilterBarInnerWrapper = styled.div`
  display: inline-block;
  & + & {
    margin-left: 24px;
  }
`;
const DataGridWrapper = styled.div`
  margin-top: 35px;
  height: 1286px;
`;

const BackgroundCover = mstyled(Backdrop)({
  color: 'black',
  zIndex: 1,
});
const ProfileButton = mstyled(Button)({
  marginLeft: '16px',
  padding: '5px 8px',
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#444444',
});
const RefreshButton = mstyled(Button)({
  padding: '5px 8px',
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#222222',
});
const VirtualRecommButton = mstyled(Button)({
  padding: '5px 8px',
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#222222',
});
const DeleteButton = mstyled(Button)({
  fontSize: '20px',
  fontWeight: 'normal',
  color: '#666666',
  marginLeft: '4px',
});
const PositionChip = mstyled(Chip)({
  fontSize: '12px',
  fontWeight: 'normal',
  color: '#dddddd',
  backgroundColor: '#444444',
  '& + &': {
    marginLeft: '8px',
  },
});
const StatusBlueChip = mstyled(Chip)({
  fontSize: '12px',
  fontWeight: 'normal',
  color: '#dddddd',
  backgroundColor: '#0c46f2',
});
const StatusBlackChip = mstyled(Chip)({
  fontSize: '12px',
  fontWeight: 'normal',
  color: '#dddddd',
  backgroundColor: '#444444',
});
const dataGridLinkCellStyle = makeStyles({
  root: {
    fontSize: '14px',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
});

const filterCount = CCColumns.columns.filter((column) => column.filter).length;

export default withRouter(function CCDetail(
  props: RouteComponentProps<{ id: string }> &
    ViewProps & {
      token: string;
    },
): ReactElement {
  const [loading, setLoading] = useState(0);
  const [cc, setCC] = useState<CCRowModel | null>(null);
  const [rows, setRows] = useState<CCTalentRowModel[]>([]);
  const [profile, setProfile] = useState<CCProfileData | null>(null);
  const [filter, setFilter] = useState<string[]>(() => ['all']);
  const [filteredRows, setFilteredRows] = useState<CCTalentRowModel[]>([]);
  const [sort, setSort, sortedRows] = useSort(
    filteredRows,
    JSON.parse(localStorage.getItem(`${props.match.url}@sort`) || '[]'),
  );
  const [virtualRecomm, setVirtualRecomm] = useState(false);
  const [virtualRecommConfirm, setVirtualRecommConfirm] = useState<
    [boolean, VirtualRecommendation[]] | null
  >(null);

  const handleChangeFilter = (
    event: React.MouseEvent<HTMLElement>,
    filter: string[],
  ) => {
    const allIndex = filter.indexOf('all');

    if (allIndex < 0) {
      if (!filter.length || filter.length == filterCount) filter = ['all'];
    } else {
      if (allIndex === 0) filter.splice(allIndex, 1);
      else filter = ['all'];
    }

    setFilter(filter);
  };

  const updateData = async (purgeCache?: boolean) => {
    try {
      const [cc, profile, recommendations] = await Promise.all([
        fetchCC(
          props,
          props.match.params.id,
          (loading) =>
            setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
          purgeCache ?? false,
        ),
        fetchCCProfile(
          props,
          props.match.params.id,
          (loading) =>
            setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
          purgeCache ?? false,
        ),
        fetchCCRecommendations(
          props,
          props.match.params.id,
          (loading) =>
            setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
          purgeCache ?? false,
        ),
      ]);

      setCC(cc);
      setProfile(profile);
      setRows(recommendations);
    } catch (response) {
      if (response.status === 401) props.history.push('/login');
      else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
    }
  };

  useEffect(() => {
    updateData();
  }, []);
  useEffect(() => {
    if (filter.length === 1 && filter[0] === 'all') {
      setFilteredRows(rows);
      return;
    }

    const filterSet = new Set(filter);
    const recommStageSet = new Set(
      CCColumns.columns
        .filter((column) => filterSet.has(column.key))
        .map((column) => column.filter?.stage),
    );

    setFilteredRows(rows.filter((row) => recommStageSet.has(row.recommStage)));
  }, [rows, filter]);

  async function handleClickDeleteButton(id: number) {
    await removeRecommendation(props, id, (loading) =>
      setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
    );
    await updateData(true);
  }

  const dataGridLinkCellTheme = createMuiTheme({
    palette: {
      primary: {
        main: useMediaQuery('(prefers-color-scheme: dark)')
          ? '#ffffff'
          : '#000000',
      },
    },
  });

  if (!cc)
    return (
      <BackgroundCover open={loading !== 0}>
        <CircularProgress />
      </BackgroundCover>
    );

  const columns: Column<CCTalentRowModel>[] = CCColumns.talentColumns;

  columns[0].formatter = function TalentNameCell(innerProps) {
    return (
      <MuiThemeProvider theme={dataGridLinkCellTheme}>
        <Button
          variant="text"
          color="primary"
          className={dataGridLinkCellStyle().root}
          component={Link}
          to={`/talents/${innerProps.row.talentId}`}
        >
          {innerProps.row.name}
        </Button>
      </MuiThemeProvider>
    );
  };
  columns[1].formatter = function PositionCell(innerProps) {
    return <>{innerProps.row.recommDetail.positionDetail.position}</>;
  };
  columns[2].formatter = function MatchingScoreCell(props) {
    return (
      <>
        {Format.floatFormat.format(props.row.recommDetail.positionDetail.score)}{' '}
        점
      </>
    );
  };
  columns[3].formatter = function RecommDateCell(props) {
    return <>{Format.dateFormat.format(props.row.recommDetail.date)}</>;
  };
  columns[4].formatter = function ProfileEvalCell(props) {
    if (props.row.recommDetail.profileDetail)
      return (
        <>
          {`평균 ${Format.floatFormat.format(
            (props.row.recommDetail.profileDetail.firstImpression +
              props.row.recommDetail.profileDetail.skillSetSuitability +
              props.row.recommDetail.profileDetail.talentSimilarity) /
              3 /
              2,
          )} 점`}
        </>
      );
    else return <>-</>;
  };
  columns[5].formatter = function IntentCell(innerProps) {
    if (innerProps.row.recommDetail.intentDetail)
      return (
        <Button
          variant="text"
          color="primary"
          className={dataGridLinkCellStyle().root}
          onClick={() => {
            if (innerProps.row.recommDetail.intentDetail)
              props.onDisplayFloatView(
                '의향 파악 내역',
                <IntentDetail
                  companyName={cc.companyName}
                  talentName={innerProps.row.name}
                  recomm={innerProps.row.recommDetail}
                />,
              );
          }}
        >
          의향 파악 내역 보기
        </Button>
      );
    else return <>-</>;
  };
  columns[6].formatter = function OfferCell(innerProps) {
    if (innerProps.row.recommDetail.offerDetail)
      return (
        <Button
          variant="text"
          color="primary"
          className={dataGridLinkCellStyle().root}
          onClick={() => {
            if (innerProps.row.recommDetail.offerDetail)
              props.onDisplayFloatView(
                '면접 제안 내역',
                <OfferDetail
                  companyName={cc.companyName}
                  talentName={innerProps.row.name}
                  recomm={innerProps.row.recommDetail}
                />,
              );
          }}
        >
          제안 내역 보기
        </Button>
      );
    else return <>-</>;
  };
  columns[7].formatter = function CounterOfferCell(innerProps) {
    if (innerProps.row.recommDetail.counterOfferDetail)
      return (
        <Button
          variant="text"
          color="primary"
          className={dataGridLinkCellStyle().root}
          onClick={() => {
            if (innerProps.row.recommDetail.counterOfferDetail)
              props.onDisplayFloatView(
                '일정 변경 요청 내역',
                <CounterOfferDetail
                  companyName={cc.companyName}
                  talentName={innerProps.row.name}
                  recomm={innerProps.row.recommDetail}
                />,
              );
          }}
        >
          요청 내역 보기
        </Button>
      );
    else return <>-</>;
  };
  columns[8].formatter = function InterviewReviewCell(innerProps) {
    if (innerProps.row.recommDetail.interviewDetail) {
      const label = `${Format.dateFormatWithDay.format(
        innerProps.row.recommDetail.interviewDetail.date,
      )} ${Format.timeSpanFormat.format(
        innerProps.row.recommDetail.interviewDetail,
      )}`;

      if (innerProps.row.recommDetail.interviewReviewDetail)
        return (
          <Button
            variant="text"
            color="primary"
            className={dataGridLinkCellStyle().root}
            onClick={() => {
              if (innerProps.row.recommDetail.interviewReviewDetail)
                props.onDisplayFloatView(
                  '면접 후기',
                  <CCInterviewReview recomm={innerProps.row.recommDetail} />,
                );
            }}
          >
            {label}
          </Button>
        );
      else return <>{label}</>;
    } else return <>-</>;
  };
  columns[9].formatter = function InterviewResultCell(innerProps) {
    if (innerProps.row.recommDetail.resultDetail)
      return (
        <Button
          variant="text"
          color="primary"
          className={dataGridLinkCellStyle().root}
          onClick={() => {
            if (innerProps.row.recommDetail.resultDetail)
              props.onDisplayFloatView(
                '면접 결과',
                <InterviewResultDetail recomm={innerProps.row.recommDetail} />,
              );
          }}
        >{`${
          innerProps.row.recommDetail.resultDetail.passed
            ? '최종 합격'
            : '불합격'
        }(${Format.dateFormat.format(
          innerProps.row.recommDetail.resultDetail.date,
        )})`}</Button>
      );
    else return <>-</>;
  };
  columns[10].formatter = function StageCell(innerProps) {
    return innerProps.row.recommStage === RecommendationStage.FAILED ||
      innerProps.row.recommStage === RecommendationStage.CLOSED ? (
      <StatusBlackChip label={innerProps.row.recommStage} />
    ) : (
      <StatusBlueChip label={innerProps.row.recommStage} />
    );
  };
  columns[11].formatter = function ActionCell(innerProps): ReactElement {
    return (
      <DeleteButton
        style={{ color: '#ff5c5c' }}
        variant="text"
        onClick={() => handleClickDeleteButton(innerProps.row.id as number)}
      >
        <i className="far fa-trash-alt" />
      </DeleteButton>
    );
  };

  return (
    <>
      <UpperWrapper>
        <TitleBarWrapper>
          <TitleText>{cc.companyName}</TitleText>
          <ProfileButton
            variant="outlined"
            onClick={() =>
              profile &&
              props.onDisplaySideView(
                '기업 프로필',
                <CCProfile {...props} profile={profile} />,
              )
            }
          >
            프로필 보기
          </ProfileButton>
          <Spacer />
          <RefreshButton variant="text" onClick={() => updateData(true)}>
            <i className="fas fa-sync-alt" />
            <span className="fa-space">새로고침</span>
          </RefreshButton>
          <VirtualRecommButton
            variant="contained"
            onClick={() => setVirtualRecomm(true)}
          >
            <i className="fas fa-user-check" />
            <span className="fa-space">가추천</span>
          </VirtualRecommButton>
        </TitleBarWrapper>
        <InfoBarWrapper>
          <InfoBarInnerWrapper>
            <InfoHeaderText>가입일</InfoHeaderText>
            <InfoText>
              {Format.dateFormatWithDayLong.format(cc.joinedAt)}
            </InfoText>
          </InfoBarInnerWrapper>
          <InfoBarInnerWrapper>
            <InfoHeaderText>산업</InfoHeaderText>
            <InfoText>{cc.industry}</InfoText>
          </InfoBarInnerWrapper>
          <InfoBarInnerWrapper>
            <InfoHeaderText>채용 담당자</InfoHeaderText>
          </InfoBarInnerWrapper>
          <MenuItemWrapper>
            {cc.users.map((user) => (
              <React.Fragment key={user.email}>
                <MenuItemRow>
                  <MenuTextBold>
                    {user.name}{' '}
                    <MenuTextNormalSpan>
                      ( {user.phone}, {user.email} )
                    </MenuTextNormalSpan>
                  </MenuTextBold>
                  {/* <MenuTextNormal>{user.email}</MenuTextNormal> */}
                </MenuItemRow>
                <MenuLine />
              </React.Fragment>
            ))}
          </MenuItemWrapper>
        </InfoBarWrapper>
        <MenuSpacer />
        <InfoBarInnerWrapper>
          <InfoHeaderText>채용 희망 직무</InfoHeaderText>
        </InfoBarInnerWrapper>
        <MenuItemWrapper>
          {cc.targets.length &&
            cc.targets.map((target, targetIndex) => (
              <React.Fragment key={target.position}>
                <MenuItemRow>
                  <MenuLongTextBold>
                    {target.position}{' '}
                    <MenuTextNormalSpan>
                      ( {target.lowYears}~{target.highYears}Y,{' '}
                      {target.lowSalary}~{target.highSalary}만원 )
                    </MenuTextNormalSpan>
                  </MenuLongTextBold>
                  {target.skills.map((skill, skillIndex) => (
                    <PositionChip
                      key={`${targetIndex}-${skillIndex}-${skill}`}
                      label={skill}
                    />
                  ))}
                </MenuItemRow>
                <MenuLine />
              </React.Fragment>
            ))}
          {!cc.targets.length && <PositionEmptyText>N/A</PositionEmptyText>}
        </MenuItemWrapper>
      </UpperWrapper>
      <SplitBar />
      <LowerWrapper>
        <HiringStatsTitleText>채용 현황</HiringStatsTitleText>
        <FilterBarWrapper>
          <FilterBarInnerWrapper>
            <ToggleButtonGroup value={filter} onChange={handleChangeFilter}>
              {FilterButton({
                value: 'all',
                label: '전체',
                num: rows.length,
              })}
            </ToggleButtonGroup>
          </FilterBarInnerWrapper>
          <FilterBarInnerWrapper>
            <ToggleButtonGroup value={filter} onChange={handleChangeFilter}>
              {CCColumns.columns
                .filter((column) => column.filter && !column.filter.black)
                .map((column) =>
                  FilterButton({
                    key: column.key,
                    value: column.key,
                    label: column.name,
                    num: (cc as unknown as Record<string, number>)[column.key],
                  }),
                )}
            </ToggleButtonGroup>
          </FilterBarInnerWrapper>
          <FilterBarInnerWrapper>
            <ToggleButtonGroup value={filter} onChange={handleChangeFilter}>
              {CCColumns.columns
                .filter((column) => column.filter?.black)
                .map((column) =>
                  FilterButton({
                    key: column.key,
                    value: column.key,
                    label: column.name,
                    num: (cc as unknown as Record<string, number>)[column.key],
                    black: true,
                  }),
                )}
            </ToggleButtonGroup>
          </FilterBarInnerWrapper>
        </FilterBarWrapper>
        <DataGridWrapper>
          <DataGrid
            style={{ height: '100%' }}
            rowHeight={45}
            rows={sortedRows}
            columns={columns}
            sorts={sort}
            onSort={(sorts) => {
              setSort(sorts);
              localStorage.setItem(
                `${props.match.url}@sort`,
                JSON.stringify(sorts),
              );
            }}
          />
        </DataGridWrapper>
      </LowerWrapper>
      <BackgroundCover open={loading !== 0}>
        <CircularProgress />
      </BackgroundCover>
      <Provider>
        <BackgroundCover open={virtualRecomm}>
          <VirtualRecomm
            id={props.match.params.id}
            company={cc.companyName}
            positions={cc.targets.map((target) => target.position)}
            token={props.token}
            onRecomm={(isPeriodic, recomms) => {
              setVirtualRecommConfirm([isPeriodic, recomms]);
              setVirtualRecomm(false);
            }}
            onClose={() => {
              setVirtualRecomm(false);
            }}
          />
        </BackgroundCover>
      </Provider>
      <BackgroundCover open={!!virtualRecommConfirm}>
        {virtualRecommConfirm && (
          <VirtualRecommConfirm
            id={props.match.params.id}
            company={cc.companyName}
            token={props.token}
            isPeriodic={virtualRecommConfirm[0]}
            recomms={virtualRecommConfirm[1]}
            onCancel={() => {
              setVirtualRecommConfirm(null);
              setVirtualRecomm(true);
            }}
            onDone={() => {
              window.location.reload();
            }}
          />
        )}
      </BackgroundCover>
    </>
  );
});
