import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 50px;
  border-radius: 20px;
  width: 495px;
  height: calc(100vh - 100px);
  background-color: white;
`;

export const Header = styled.div`
  flex: 0 0 auto;
  border-bottom: 1px solid #c5c5c5;
  padding: 24px;
  text-align: center;
`;

export const Content = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 20px 24px;
  background-color: #eeeeee;
  overflow-y: auto;
`;

export const Footer = styled.div`
  border-top: 1px solid #c5c5c5;
  padding: 24px;
`;

export const HeaderTitleBigText = styled.p`
  color: #111111;
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
`;

export const HeaderTitleSmallText = styled.p`
  color: #787878;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
`;

export const ItemWrapper = styled.div`
  border: 2px solid transparent;
  border-radius: 16px;
  padding: 24px 20px 16px;
  background-color: white;
  & + & {
    margin-top: 20px;
  }
`;

export const ItemUpperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const ItemLowerWrapper = styled.div`
  margin-top: 12px;
`;

export const ItemThumbnail = styled.img`
  border-radius: 26px;
`;

export const ItemThumbnailPlaceholder = styled.div`
  border: 1px solid #c5c5c5;
  border-radius: 26px;
  width: 52px;
  height: 52px;
  background-color: #eeeeee;
`;

export const ItemThumbnailSpacer = styled.div`
  width: 20px;
`;

export const ItemProfileWrapper = styled.div`
  display: inline-block;
  width: 320px;
`;

export const ItemProfileBigText = styled.span`
  color: #2c2c2c;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
`;

export const ItemProfileBigTextSpacer = styled.div`
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  width: 1px;
  height: 14px;
  background-color: #c5c5c5;
`;

export const ItemProfileSmallText = styled.p`
  color: #5f5f5f;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
`;

export const ItemLowerText = styled.p`
  color: #3c6af5;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  text-align: right;
`;

export const FooterAgreementWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const FooterAgreementText = styled.p`
  color: #444444;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
`;

export const FooterSpacer = styled.div`
  height: 22px;
`;

export const FooterActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const FooterActionButtonSpacer = styled.div`
  flex: 0 0 auto;
  width: 12px;
`;

export const buttonStyles = makeStyles({
  ok: {
    width: '210px',
    height: '56px',
    fontSize: '14px',
    color: 'white',
    backgroundColor: '#0c46f2',
  },
  cancel: {
    width: '210px',
    height: '56px',
    fontSize: '14px',
    color: '#111111',
    backgroundColor: '#eeeeee',
  },
});
