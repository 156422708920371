const dates: Record<number, string> = {
  0: '일',
  1: '월',
  2: '화',
  3: '수',
  4: '목',
  5: '금',
  6: '토',
};

export default {
  numberFormat: new Intl.NumberFormat('ko-KR', {
    style: 'decimal',
    maximumFractionDigits: 0,
  }),
  curencyFormat: new Intl.NumberFormat('ko-KR', {
    style: 'currency',
    currency: 'KRW',
  }),
  floatFormat: new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 1,
  }),
  monthFormat: new (class {
    format(date: Date): string {
      return `${String(date.getFullYear()).padStart(4, '0')}.${String(
        date.getMonth() + 1,
      ).padStart(2, '0')}`;
    }
  })(),
  dateFormat: new (class {
    format(date: Date): string {
      return `${String(Math.floor(date.getFullYear() % 100)).padStart(
        2,
        '0',
      )}.${String(date.getMonth() + 1).padStart(2, '0')}.${String(
        date.getDate(),
      ).padStart(2, '0')}`;
    }
  })(),
  dateFormatWithDay: new (class {
    format(date: Date): string {
      return `${String(Math.floor(date.getFullYear() % 100)).padStart(
        2,
        '0',
      )}.${String(date.getMonth() + 1).padStart(2, '0')}.${String(
        date.getDate(),
      ).padStart(2, '0')}(${dates[date.getDay()]})`;
    }
  })(),
  dateFormatWithDayLong: new (class {
    format(date: Date): string {
      return `${String(date.getMonth() + 1).padStart(2, '0')}월 ${String(
        date.getDate(),
      ).padStart(2, '0')}일 ${dates[date.getDay()]}요일`;
    }
  })(),
  timeFormat: new (class {
    format(date: Date): string {
      return `${String(date.getHours()).padStart(2, '0')}:${String(
        date.getMinutes(),
      ).padStart(2, '0')}`;
    }
  })(),
  timeSpanFormat: new (class {
    format(time: { startTime: number; endTime: number }): string {
      return `${String(Math.floor(time.startTime / 60)).padStart(
        2,
        '0',
      )}:${String(Math.floor(time.startTime % 60)).padStart(2, '0')} ~ ${String(
        Math.floor(time.endTime / 60),
      ).padStart(2, '0')}:${String(Math.floor(time.endTime % 60)).padStart(
        2,
        '0',
      )}`;
    }
  })(),
};
