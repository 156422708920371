import {
  styled as mstyled,
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  createMuiTheme,
  useMediaQuery,
  MuiThemeProvider,
  makeStyles,
  Dialog,
} from '@material-ui/core';
import { ToggleButtonGroup } from '@material-ui/lab';
import React, { ReactElement, useEffect, useState } from 'react';
import DataGrid, { Column } from 'react-data-grid';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { useSort } from '../features/Sort';

import FilterButton from '../components/FilterButton';

import CounterOfferDetail from '../fragments/CounterOfferDetail';
import InterviewResultDetail from '../fragments/InterviewResultDetail';
import OfferDetail from '../fragments/OfferDetail';
import TalentInterviewReview from '../fragments/TalentInterviewReview';
import TalentProfile from '../fragments/TalentProfile';

import { ViewProps } from '../props/ViewProps';

import {
  deleteTalent,
  fetchTalent,
  fetchTalentProfile,
  fetchTalentRecommendations,
  removeRecommendation,
  updateTalentAllowedForRecommendation,
  updateTalentMembership,
  emailWebTalentProfileLink,
  getTalentUserToken,
} from '../helpers/Api';
import Format from '../helpers/Format';

import TalentColumns, {
  TalentRowModel,
  TalentProfileData,
  TalentCCRowModel,
} from '../schemas/Talent';
import { RecommendationStage } from '../schemas/Recommendation';

import '@fortawesome/fontawesome-free/css/all.css';
import '../css/datagrid.css';
import IntentDetail from './IntentDetail';

const UpperWrapper = styled.section`
  padding: 40px 36px 32px;
`;
const TitleBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const TitleText = styled.h1`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  font-size: 34px;
  font-weight: bold;
  color: #222222;
`;
const Spacer = styled.span`
  margin: 0 auto;
`;
const InfoText = styled.p`
  margin-top: 16px;
  font-size: 16px;
  font-weight: normal;
  color: #222222;
`;
const PositionBarTitleText = styled.h2`
  margin: 24px 0 0;
  font-size: 16px;
  font-weight: bold;
  color: #222222;
`;
const PositionBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
`;
const PositionEmptyText = styled.p`
  font-size: 14px;
  font-weight: normal;
  color: #999999;
`;
const SplitBar = styled.hr`
  margin: 0;
  border: 0;
  height: 1px;
  background-color: #999999;
`;
const LowerWrapper = styled.section`
  padding: 32px 36px 36px;
`;
const HiringStatsTitleText = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #222222;
`;
const FilterBarWrapper = styled.div`
  margin-top: 32px;
  overflow-x: scroll;
  white-space: nowrap;
`;
const FilterBarInnerWrapper = styled.div`
  display: inline-block;
  & + & {
    margin-left: 24px;
  }
`;
const DataGridWrapper = styled.div`
  margin-top: 35px;
  height: 1286px;
`;
const DialogWrapper = styled.div`
  padding: 32px 20px 20px;
`;
const DialogTitleText = styled.p`
  font-size: 18px;
  font-weight: normal;
  color: #222222;
  text-align: center;
`;
const DialogButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;

const BackgroundCover = mstyled(Backdrop)({
  color: 'black',
  zIndex: 1,
});
const ProfileButton = mstyled(Button)({
  marginLeft: '16px',
  padding: '5px 8px',
  fontSize: '13px',
  fontWeight: 'normal',
  color: '#444444',
});
const ProfileViewButton = mstyled(Button)({
  marginLeft: '16px',
  padding: '5px 8px',
  fontSize: '13px',
  fontWeight: 'normal',
  color: '#444444',
});
const ProfileEditButton = mstyled(Button)({
  marginLeft: '16px',
  padding: '5px 8px',
  fontSize: '13px',
  fontWeight: 'normal',
  color: '#444444',
});
const EmailWebProfileLinkButton = mstyled(Button)({
  marginLeft: '16px',
  padding: '5px 8px',
  fontSize: '13px',
  fontWeight: 'normal',
  color: '#444444',
});
const UserDeleteButton = mstyled(Button)({
  marginRight: '8px',
  padding: '5px 8px',
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#222222',
});
const RefreshButton = mstyled(Button)({
  padding: '5px 8px',
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#222222',
});
const RecommEnableButton = mstyled(Button)({
  fontSize: '12px',
  fontWeight: 'normal',
  marginLeft: '4px',
});
const GeneralMemberButton = mstyled(Button)({
  fontSize: '12px',
  fontWeight: 'normal',
  marginLeft: '4px',
});
const PremiumMemberButton = mstyled(Button)({
  fontSize: '12px',
  fontWeight: 'normal',
  marginLeft: '4px',
  backgroundColor: '#FBBC07',
});
const DeleteButton = mstyled(Button)({
  fontSize: '20px',
  fontWeight: 'normal',
  color: '#666666',
  marginLeft: '4px',
});
const PositionChip = mstyled(Chip)({
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#dddddd',
  backgroundColor: '#444444',
  '& + &': {
    marginLeft: '8px',
  },
});
const StatusBlueChip = mstyled(Chip)({
  fontSize: '12px',
  fontWeight: 'normal',
  color: '#dddddd',
  backgroundColor: '#0c46f2',
});
const StatusBlackChip = mstyled(Chip)({
  fontSize: '12px',
  fontWeight: 'normal',
  color: '#dddddd',
  backgroundColor: '#444444',
});
const dataGridLinkCellStyle = makeStyles({
  root: {
    fontSize: '14px',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
});
const DialogRowButton = mstyled(Button)({
  width: '142px',
  height: '56px',
  fontSize: '16px',
  fontWeight: 'normal',
  boxShadow: 'none',
  '&+&': {
    marginLeft: '12px',
  },
  '&:hover, &:active': {
    boxShadow: 'none',
  },
});

const filterCount = TalentColumns.columns.filter(
  (column) => column.filter,
).length;

export default withRouter(function TalentDetail(
  props: RouteComponentProps<{ id: string }> &
    ViewProps & {
      token: string;
    },
): ReactElement {
  const [loading, setLoading] = useState(0);
  const [talent, setTalent] = useState<TalentRowModel | null>(null);
  const [rows, setRows] = useState<TalentCCRowModel[]>([]);
  const [profile, setProfile] = useState<TalentProfileData | null>(null);
  const [filter, setFilter] = useState<string[]>(() => ['all']);
  const [filteredRows, setFilteredRows] = useState<TalentCCRowModel[]>([]);
  const [sort, setSort, sortedRows] = useSort(
    filteredRows,
    JSON.parse(localStorage.getItem(`${props.match.url}@sort`) || '[]'),
  );
  const [deleteDialog, setDeleteDialog] = useState<null | {
    id: number;
    talent: string;
    callback(id: number): void;
  }>(null);
  const [secondDeleteDialog, setSecondDeleteDialog] = useState<null | {
    id: number;
    talent: string;
    callback(id: number): void;
  }>(null);
  const [thirdDeleteDialog, setThirdDeleteDialog] = useState<null | {
    id: number;
    talent: string;
    callback(id: number): void;
  }>(null);
  const [emailDialog, setEmailDialog] = useState<null | {
    id: number;
    talent: string;
    callback(id: number): void;
  }>(null);

  const handleChangeFilter = (
    event: React.MouseEvent<HTMLElement>,
    filter: string[],
  ) => {
    const allIndex = filter.indexOf('all');

    if (allIndex < 0) {
      if (!filter.length || filter.length == filterCount) filter = ['all'];
    } else {
      if (allIndex === 0) filter.splice(allIndex, 1);
      else filter = ['all'];
    }

    setFilter(filter);
  };

  const updateData = async (purgeCache?: boolean) => {
    try {
      const [talent, profile, recommendations] = await Promise.all([
        fetchTalent(
          props,
          props.match.params.id,
          (loading) =>
            setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
          purgeCache ?? false,
        ),
        fetchTalentProfile(
          props,
          props.match.params.id,
          (loading) =>
            setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
          purgeCache ?? false,
        ),
        fetchTalentRecommendations(
          props,
          props.match.params.id,
          (loading) =>
            setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
          purgeCache ?? false,
        ),
      ]);
      setTalent(talent);
      setProfile(profile);
      setRows(recommendations);
    } catch (response) {
      if (response.status === 401) props.history.push('/login');
      else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
    }
  };

  useEffect(() => {
    updateData();
  }, []);
  useEffect(() => {
    if (filter.length === 1 && filter[0] === 'all') {
      setFilteredRows(rows);
      return;
    }

    const filterSet = new Set(filter);
    const recommStageSet = new Set(
      TalentColumns.columns
        .filter((column) => filterSet.has(column.key))
        .map((column) => column.filter?.stage),
    );

    setFilteredRows(rows.filter((row) => recommStageSet.has(row.recommStage)));
  }, [rows, filter]);

  async function handleClickAllowButton(allowed: boolean) {
    await updateTalentAllowedForRecommendation(
      props,
      props.match.params.id,
      allowed,
      (loading) =>
        setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
    );
    await updateData(true);
  }

  async function handleClickMembershipChangeButton(membership: string) {
    await updateTalentMembership(
      props,
      props.match.params.id,
      membership,
      (loading) =>
        setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
    );
    await updateData(true);
  }

  async function handleClickDeleteButton(id: number) {
    await removeRecommendation(props, id, (loading) =>
      setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
    );
    await updateData(true);
  }

  const dataGridLinkCellTheme = createMuiTheme({
    palette: {
      primary: {
        main: useMediaQuery('(prefers-color-scheme: dark)')
          ? '#ffffff'
          : '#000000',
      },
    },
  });

  if (!talent)
    return (
      <BackgroundCover open={loading !== 0}>
        <CircularProgress />
      </BackgroundCover>
    );

  const columns: Column<TalentCCRowModel>[] = TalentColumns.ccColumns;

  columns[0].formatter = function TalentNameCell(innerProps): ReactElement {
    return (
      <MuiThemeProvider theme={dataGridLinkCellTheme}>
        <Button
          variant="text"
          color="primary"
          className={dataGridLinkCellStyle().root}
          component={Link}
          to={`/ccs/${innerProps.row.ccId}`}
        >
          {innerProps.row.name}
        </Button>
      </MuiThemeProvider>
    );
  };
  columns[1].formatter = function PositionCell(innerProps) {
    return <>{innerProps.row.recommDetail.positionDetail.position}</>;
  };
  columns[2].formatter = function MatchingScoreCell(props): ReactElement {
    return (
      <>
        {Format.floatFormat.format(props.row.recommDetail.positionDetail.score)}{' '}
        점
      </>
    );
  };
  columns[3].formatter = function RecommDateCell(props): ReactElement {
    return <>{Format.dateFormat.format(props.row.recommDetail.date)}</>;
  };
  columns[4].formatter = function ProfileEvalCell(props): ReactElement {
    if (props.row.recommDetail.profileDetail)
      return (
        <>
          {`평균 ${Format.floatFormat.format(
            (props.row.recommDetail.profileDetail.firstImpression +
              props.row.recommDetail.profileDetail.skillSetSuitability +
              props.row.recommDetail.profileDetail.talentSimilarity) /
              3 /
              2,
          )} 점`}
        </>
      );
    else return <>-</>;
  };
  columns[5].formatter = function IntentCell(innerProps) {
    if (innerProps.row.recommDetail.intentDetail)
      return (
        <Button
          variant="text"
          color="primary"
          className={dataGridLinkCellStyle().root}
          onClick={() => {
            if (innerProps.row.recommDetail.intentDetail)
              props.onDisplayFloatView(
                '의향 파악 내역',
                <IntentDetail
                  companyName={innerProps.row.name}
                  talentName={talent.name}
                  recomm={innerProps.row.recommDetail}
                />,
              );
          }}
        >
          의향 파악 내역 보기
        </Button>
      );
    else return <>-</>;
  };
  columns[6].formatter = function OfferCell(innerProps): ReactElement {
    if (innerProps.row.recommDetail.offerDetail)
      return (
        <Button
          variant="text"
          color="primary"
          className={dataGridLinkCellStyle().root}
          onClick={() => {
            if (innerProps.row.recommDetail.offerDetail)
              props.onDisplayFloatView(
                '면접 제안 내역',
                <OfferDetail
                  companyName={innerProps.row.name}
                  talentName={talent.name}
                  recomm={innerProps.row.recommDetail}
                />,
              );
          }}
        >
          제안 내역 보기
        </Button>
      );
    else return <>-</>;
  };
  columns[7].formatter = function CounterOfferCell(innerProps): ReactElement {
    if (innerProps.row.recommDetail.counterOfferDetail)
      return (
        <Button
          variant="text"
          color="primary"
          className={dataGridLinkCellStyle().root}
          onClick={() => {
            if (innerProps.row.recommDetail.counterOfferDetail)
              props.onDisplayFloatView(
                '일정 변경 요청 내역',
                <CounterOfferDetail
                  companyName={innerProps.row.name}
                  talentName={talent.name}
                  recomm={innerProps.row.recommDetail}
                />,
              );
          }}
        >
          요청 내역 보기
        </Button>
      );
    else return <>-</>;
  };
  columns[8].formatter = function InterviewReviewCell(
    innerProps,
  ): ReactElement {
    if (innerProps.row.recommDetail.interviewDetail) {
      const label = `${Format.dateFormatWithDay.format(
        innerProps.row.recommDetail.interviewDetail.date,
      )} ${Format.timeSpanFormat.format(
        innerProps.row.recommDetail.interviewDetail,
      )}`;

      if (innerProps.row.recommDetail.interviewReviewDetail)
        return (
          <Button
            variant="text"
            color="primary"
            className={dataGridLinkCellStyle().root}
            onClick={() => {
              if (innerProps.row.recommDetail.interviewReviewDetail)
                props.onDisplayFloatView(
                  '면접 후기',
                  <TalentInterviewReview
                    recomm={innerProps.row.recommDetail}
                  />,
                );
            }}
          >
            {label}
          </Button>
        );
      else return <>{label}</>;
    } else return <>-</>;
  };
  columns[9].formatter = function InterviewResultCell(innerProps) {
    if (innerProps.row.recommDetail.resultDetail)
      return (
        <Button
          variant="text"
          color="primary"
          className={dataGridLinkCellStyle().root}
          onClick={() => {
            if (innerProps.row.recommDetail.resultDetail)
              props.onDisplayFloatView(
                '면접 결과',
                <InterviewResultDetail recomm={innerProps.row.recommDetail} />,
              );
          }}
        >{`${
          innerProps.row.recommDetail.resultDetail.passed
            ? '최종 합격'
            : '불합격'
        }(${Format.dateFormat.format(
          innerProps.row.recommDetail.resultDetail.date,
        )})`}</Button>
      );
    else return <>-</>;
  };
  columns[10].formatter = function StageCell(innerProps): ReactElement {
    return innerProps.row.recommStage === RecommendationStage.FAILED ||
      innerProps.row.recommStage === RecommendationStage.CLOSED ? (
      <StatusBlackChip label={innerProps.row.recommStage} />
    ) : (
      <StatusBlueChip label={innerProps.row.recommStage} />
    );
  };
  columns[11].formatter = function ActionCell(innerProps): ReactElement {
    return (
      <DeleteButton
        style={{ color: '#ff5c5c' }}
        variant="text"
        onClick={() => handleClickDeleteButton(innerProps.row.id as number)}
      >
        <i className="far fa-trash-alt" />
      </DeleteButton>
    );
  };

  return (
    <>
      <UpperWrapper>
        <TitleBarWrapper>
          <TitleText>{talent.name}</TitleText>
          <ProfileButton
            variant="outlined"
            onClick={() =>
              profile &&
              props.onDisplaySideView(
                '인재 프로필',
                <TalentProfile {...props} talent={talent} profile={profile} />,
              )
            }
          >
            프로필 정보
          </ProfileButton>
          <ProfileViewButton
            variant="outlined"
            onClick={async () => {
              const { token } = await getTalentUserToken(
                props,
                String(talent.id),
                (loading) =>
                  setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
              );
              console.log('getTalentUserToken token', token);
              if (token) {
                window.open(
                  `https://www.talentx.co.kr/profileview?token=${token}`,
                  '_blank',
                );
              }
            }}
          >
            웹프로필 검토
          </ProfileViewButton>
          <ProfileEditButton
            variant="outlined"
            onClick={async () => {
              const { token } = await getTalentUserToken(
                props,
                String(talent.id),
                (loading) =>
                  setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
              );
              console.log('getTalentUserToken token', token);
              if (token) {
                window.open(
                  `https://www.talentx.co.kr/profile?token=${token}`,
                  '_blank',
                );
              }
            }}
          >
            웹프로필 편집
          </ProfileEditButton>
          <EmailWebProfileLinkButton
            variant="outlined"
            onClick={() =>
              setEmailDialog({
                id: talent.id,
                talent: talent.name,
                callback: async (id) => {
                  await emailWebTalentProfileLink(
                    props,
                    String(id),
                    (loading) =>
                      setLoading(
                        (prevLoading) => prevLoading + (loading ? 1 : -1),
                      ),
                  );
                },
              })
            }
          >
            웹프로필 작성 링크 보내기
          </EmailWebProfileLinkButton>
          <Spacer />
          <UserDeleteButton
            variant="text"
            onClick={() =>
              setDeleteDialog({
                id: talent.id,
                talent: talent.name,
                callback: async (id) => {
                  await deleteTalent(props, String(id), (loading) =>
                    setLoading(
                      (prevLoading) => prevLoading + (loading ? 1 : -1),
                    ),
                  );
                },
              })
            }
          >
            <i className="fas fa-trash" />
            <span className="fa-space">회원 탈퇴</span>
          </UserDeleteButton>
          <RefreshButton variant="text" onClick={() => updateData(true)}>
            <i className="fas fa-sync-alt" />
            <span className="fa-space">새로고침</span>
          </RefreshButton>
        </TitleBarWrapper>
        <InfoText>
          {Format.dateFormatWithDayLong.format(talent.joinedAt)}에 가입함
        </InfoText>

        <InfoText>
          {talent.recommendationEnabled ? '추천 활성화 됨' : '추천 비활성화 됨'}
        </InfoText>
        <InfoText>
          {talent.allowedForRecommendation ? '추천 승인됨' : '추천 승인 안 됨'}
          {': '}
          <RecommEnableButton
            variant="outlined"
            color={talent.allowedForRecommendation ? 'secondary' : 'default'}
            onClick={() =>
              handleClickAllowButton(!talent.allowedForRecommendation)
            }
          >
            {talent.allowedForRecommendation ? '승인 거부' : '승인'}
          </RecommEnableButton>
        </InfoText>
        <InfoText>
          멤버십: {talent.membership}
          {'  '}
          <GeneralMemberButton
            variant="outlined"
            onClick={() => handleClickMembershipChangeButton('general')}
            disabled={talent.membership === 'general'}
          >
            일반
          </GeneralMemberButton>
          {'  '}
          <PremiumMemberButton
            variant="outlined"
            onClick={() => handleClickMembershipChangeButton('premium')}
            disabled={talent.membership === 'premium'}
          >
            프리미엄
          </PremiumMemberButton>
        </InfoText>
        <InfoText>{talent.email}</InfoText>
        <InfoText>{talent.phone}</InfoText>
        <PositionBarTitleText>취업 희망 직무</PositionBarTitleText>
        <PositionBarWrapper>
          {talent.positions.length ? (
            talent.positions.map((position) => (
              <PositionChip key={position} label={position} />
            ))
          ) : (
            <PositionEmptyText>N/A</PositionEmptyText>
          )}
        </PositionBarWrapper>
      </UpperWrapper>
      <SplitBar />
      <LowerWrapper>
        <HiringStatsTitleText>취업 현황</HiringStatsTitleText>
        <FilterBarWrapper>
          <FilterBarInnerWrapper>
            <ToggleButtonGroup value={filter} onChange={handleChangeFilter}>
              {FilterButton({
                value: 'all',
                label: '전체',
                num: rows.length,
              })}
            </ToggleButtonGroup>
          </FilterBarInnerWrapper>
          <FilterBarInnerWrapper>
            <ToggleButtonGroup value={filter} onChange={handleChangeFilter}>
              {TalentColumns.columns
                .filter((column) => column.filter && !column.filter.black)
                .map((column) =>
                  FilterButton({
                    key: column.key,
                    value: column.key,
                    label: column.name,
                    num: (talent as unknown as Record<string, number>)[
                      column.key
                    ],
                  }),
                )}
            </ToggleButtonGroup>
          </FilterBarInnerWrapper>
          <FilterBarInnerWrapper>
            <ToggleButtonGroup value={filter} onChange={handleChangeFilter}>
              {TalentColumns.columns
                .filter((column) => column.filter?.black)
                .map((column) =>
                  FilterButton({
                    key: column.key,
                    value: column.key,
                    label: column.name,
                    num: (talent as unknown as Record<string, number>)[
                      column.key
                    ],
                    black: true,
                  }),
                )}
            </ToggleButtonGroup>
          </FilterBarInnerWrapper>
        </FilterBarWrapper>
        <DataGridWrapper>
          <DataGrid
            style={{ height: '100%' }}
            rowHeight={45}
            rows={sortedRows}
            columns={columns}
            sorts={sort}
            onSort={(sorts) => {
              setSort(sorts);
              localStorage.setItem(
                `${props.match.url}@sort`,
                JSON.stringify(sorts),
              );
            }}
          />
        </DataGridWrapper>
      </LowerWrapper>
      <BackgroundCover open={loading !== 0}>
        <CircularProgress />
      </BackgroundCover>
      <Dialog open={!!emailDialog}>
        <DialogWrapper>
          <DialogTitleText>
            {emailDialog?.talent}에게 웹프로필 작성 링크를
          </DialogTitleText>
          <DialogTitleText>발송하시겠습니까?</DialogTitleText>
          <DialogButtonRow>
            <DialogRowButton
              variant="contained"
              onClick={() => setEmailDialog(null)}
            >
              아니오
            </DialogRowButton>
            <DialogRowButton
              variant="contained"
              color="secondary"
              onClick={() => {
                emailDialog?.callback(emailDialog.id);
                setEmailDialog(null);
              }}
            >
              네
            </DialogRowButton>
          </DialogButtonRow>
        </DialogWrapper>
      </Dialog>
      <Dialog open={!!deleteDialog}>
        <DialogWrapper>
          <DialogTitleText>
            {deleteDialog?.talent}님의 회원 탈퇴를
          </DialogTitleText>
          <DialogTitleText>진행하시겠습니까?</DialogTitleText>
          <DialogButtonRow>
            <DialogRowButton
              variant="contained"
              onClick={() => setDeleteDialog(null)}
            >
              아니오
            </DialogRowButton>
            <DialogRowButton
              variant="contained"
              color="secondary"
              onClick={() => {
                setSecondDeleteDialog(deleteDialog);
                setDeleteDialog(null);
              }}
            >
              네
            </DialogRowButton>
          </DialogButtonRow>
        </DialogWrapper>
      </Dialog>
      <Dialog open={!!secondDeleteDialog}>
        <DialogWrapper>
          <DialogTitleText>
            {secondDeleteDialog?.talent}님의 회원 탈퇴를
          </DialogTitleText>
          <DialogTitleText>정말로 진행하시겠습니까?</DialogTitleText>
          <DialogTitleText>
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              절대 복구할 수 없
            </span>
            습니다.
          </DialogTitleText>
          <DialogButtonRow>
            <DialogRowButton
              variant="contained"
              onClick={() => setSecondDeleteDialog(null)}
            >
              아니오
            </DialogRowButton>
            <DialogRowButton
              variant="contained"
              color="secondary"
              onClick={() => {
                setThirdDeleteDialog(secondDeleteDialog);
                setSecondDeleteDialog(null);
              }}
            >
              네
            </DialogRowButton>
          </DialogButtonRow>
        </DialogWrapper>
      </Dialog>
      <Dialog open={!!thirdDeleteDialog}>
        <DialogWrapper>
          <DialogTitleText>
            {thirdDeleteDialog?.talent}님의 회원 탈퇴를
          </DialogTitleText>
          <DialogTitleText>정말로 진행하시겠습니까?</DialogTitleText>
          <DialogTitleText>
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              절대 복구할 수 없
            </span>
            으며
          </DialogTitleText>
          <DialogTitleText>
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              삭제 이후 일어나는 모든 일에 대해 숙지한 것으로 간주
            </span>
            합니다.
          </DialogTitleText>
          <DialogButtonRow>
            <DialogRowButton
              variant="contained"
              onClick={() => setThirdDeleteDialog(null)}
            >
              아니오
            </DialogRowButton>
            <DialogRowButton
              variant="contained"
              color="secondary"
              onClick={() => {
                setThirdDeleteDialog(null);
                thirdDeleteDialog?.callback(thirdDeleteDialog.id);
              }}
            >
              네
            </DialogRowButton>
          </DialogButtonRow>
        </DialogWrapper>
      </Dialog>
    </>
  );
});
