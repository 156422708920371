import { Column } from 'react-data-grid';

export enum CCApplicationState {
  PENDING = 'pending',
  SENT_SERVICE_GUIDE = 'sent_service_guide',
  SENT_PRICING = 'sent_pricing',
  REJECTED = 'rejected',
  APPROVED = 'approved',
}

export enum CCApplicationRejectReason {
  BREAKDOWN = 'breakdown',
  NOT_QUALIFIED = 'not_qualified',
  NOT_COMPANY_EMAIL = 'not_company_email',
  UNAUTHORIZED = 'unauthorized',
  EXCEEDED_ACCOUNT_LIMIT = 'exceeded_account_limit',
  DUPLICATED = 'duplicated',
}

export enum TalentApplicationState {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  TESTING = 'testing',
  TESTED = 'tested',
}

export enum TalentApplicationRejectReason {
  NOT_QUALIFIED = 'not_qualified',
  TEST_IGNORED = 'test_ignored',
  TEST_BELOW = 'test_below',
  TEST_CHEAT = 'test_cheat',
  DUPLICATED = 'duplicated',
}

export interface CCApplicationRowModel {
  id: number;
  index: number;
  membership: string;
  state: CCApplicationState;
  rejectReason: CCApplicationRejectReason | null;
  name: string;
  registrationNumber: string;
  businessEmail: string;
  documentURL: string;
  numOfEmployees: number;
  numOfDev: number;
  hasDevLeader: boolean;
  revenue: number;
  fund: number;
  channel: string;
  phone: string;
  zipCode: string;
  address1: string;
  address2: string;
  users: {
    email: string;
    name: string;
    phone: string;
  }[];
  appliedAt: Date;
}

export interface TalentApplicationRowModel {
  id: number;
  index: number;
  state: TalentApplicationState;
  rejectReason: TalentApplicationRejectReason | null;
  email: string;
  name: string;
  phone: string;
  birth: Date;
  school: string;
  schoolState: string;
  major: string;
  bootcamp: boolean;
  working: boolean;
  yearsOfExp: string;
  channel: string;
  codingTestType: string;
  codingTestDate: Date;
  codingTestSentDate: Date;
  codingTestScore: number | null;
  codingTestCutoffScore: number | null;
  codingTestPlagiarismStatus: boolean | null;
  position: {
    name: string;
  };
  documentURL: string;
  websiteURL: string;
  appliedAt: Date;
}

interface CCFilter {
  displayName: string;
  state: CCApplicationState;
}

interface TalentFilter {
  displayName: string;
  state: TalentApplicationState;
}

const blueCCFilters: CCFilter[] = [
  {
    displayName: '가입 신청',
    state: CCApplicationState.PENDING,
  },
  {
    displayName: '가격 정책 보냄',
    state: CCApplicationState.SENT_SERVICE_GUIDE,
  },
  {
    displayName: '보증금 납부함',
    state: CCApplicationState.SENT_PRICING,
  },
  {
    displayName: '승인',
    state: CCApplicationState.APPROVED,
  },
];
const redCCFilters: CCFilter[] = [
  {
    displayName: '미승인',
    state: CCApplicationState.REJECTED,
  },
];
const ccColumns: Column<CCApplicationRowModel>[] = [
  {
    key: 'id',
    name: '신청일시',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging align-center',
  },
  {
    key: 'membership',
    name: '멤버십',
    sortable: true,
    resizable: true,
    width: 120,
    cellClass: 'enable-dragging',
  },
  {
    key: 'name',
    name: '기업명',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging',
  },
  {
    key: 'registrationNumber',
    name: '사업자등록번호',
    sortable: true,
    resizable: true,
    width: 160,
    cellClass: 'enable-dragging align-center',
  },
  {
    key: 'businessEmail',
    name: '전자세금계산서용 이메일',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging align-center',
  },
  {
    key: 'users',
    name: '채용 담당자',
    resizable: true,
    width: 250,
    cellClass: 'enable-dragging',
  },
  {
    key: 'numOfEmployees',
    name: '전체 사원 수',
    sortable: true,
    resizable: true,
    width: 100,
    cellClass: 'enable-dragging align-right',
  },
  {
    key: 'numOfDev',
    name: '개발팀 인원',
    sortable: true,
    resizable: true,
    width: 100,
    cellClass: 'enable-dragging align-right',
  },
  {
    key: 'hasDevLeader',
    name: '개발리더 유무',
    sortable: true,
    resizable: true,
    width: 100,
    cellClass: 'enable-dragging align-center',
  },
  {
    key: 'revenue',
    name: '매출액',
    sortKeyGetter: (row) => Number(row.revenue),
    sortable: true,
    resizable: true,
    width: 140,
    cellClass: 'enable-dragging align-right',
  },
  {
    key: 'fund',
    name: '투자액',
    sortKeyGetter: (row) => Number(row.fund),
    sortable: true,
    resizable: true,
    width: 140,
    cellClass: 'enable-dragging align-right',
  },
  {
    key: 'channel',
    name: '가입 경로',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging align-center',
  },
  {
    key: 'phone',
    name: '연락처',
    sortable: true,
    resizable: true,
    width: 120,
    cellClass: 'enable-dragging',
  },
  {
    key: 'zipCode',
    name: '우편번호',
    sortable: true,
    resizable: true,
    width: 80,
    cellClass: 'enable-dragging align-right',
  },
  {
    key: 'address1',
    name: '주소1',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging',
  },
  {
    key: 'address2',
    name: '주소2',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging',
  },
  {
    key: 'documentURL',
    name: '사업자등록증',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging',
  },
  {
    key: 'state',
    name: '상태',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
  },
  {
    key: 'action',
    name: '처리',
    resizable: true,
    width: 450,
    cellClass: 'align-center',
  },
];

const blueTalentFilters: TalentFilter[] = [
  {
    displayName: '가입 신청',
    state: TalentApplicationState.PENDING,
  },
  {
    displayName: '테스트 진행',
    state: TalentApplicationState.TESTING,
  },
  {
    displayName: '테스트 완료',
    state: TalentApplicationState.TESTED,
  },
  {
    displayName: '승인',
    state: TalentApplicationState.APPROVED,
  },
];
const redTalentFilters: TalentFilter[] = [
  {
    displayName: '미승인',
    state: TalentApplicationState.REJECTED,
  },
];
const talentColumns: Column<TalentApplicationRowModel>[] = [
  {
    key: 'appliedAt',
    name: '신청일시',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging align-center',
  },
  {
    key: 'name',
    name: '이름',
    sortable: true,
    resizable: true,
    width: 100,
    cellClass: 'enable-dragging',
  },
  {
    key: 'birth',
    name: '생년월일',
    sortable: true,
    resizable: true,
    width: 100,
    cellClass: 'enable-dragging align-center',
  },
  {
    key: 'email',
    name: '이메일',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging',
  },
  {
    key: 'phone',
    name: '연락처',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
  },
  {
    key: 'position.name',
    name: '희망 직무',
    sortKeyGetter: (row) => row.position?.name ?? '',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
  },
  {
    key: 'school',
    name: '최종 학력',
    sortable: true,
    resizable: true,
    width: 250,
    cellClass: 'enable-dragging',
  },
  {
    key: 'major',
    name: '전공',
    sortable: true,
    resizable: true,
    width: 300,
    cellClass: 'enable-dragging',
  },
  {
    key: 'bootcamp',
    name: '개발 교육 수료',
    sortable: true,
    resizable: true,
    width: 120,
    cellClass: 'enable-dragging align-center',
  },
  {
    key: 'working',
    name: '현업 유무',
    sortable: true,
    resizable: true,
    width: 120,
    cellClass: 'enable-dragging align-center',
  },
  {
    key: 'yearsOfExp',
    name: '총 개발 경력',
    sortable: true,
    resizable: true,
    width: 120,
    cellClass: 'enable-dragging',
  },
  {
    key: 'channel',
    name: '가입 경로',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging align-center',
  },
  {
    key: 'codingTestType',
    name: '코딩테스트 유형',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging align-center',
  },
  {
    key: 'codingTestDate',
    name: '코딩테스트 응시희망일',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging align-center',
  },
  {
    key: 'codingTestSentDate',
    name: '코딩테스트 발송일',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging align-center',
  },
  {
    key: 'codingTestScore',
    name: '코딩테스트 점수',
    sortKeyGetter: (row) => row.codingTestScore ?? -1,
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging align-center',
  },
  {
    key: 'documentURL',
    name: 'IT포트폴리오 문서',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging',
  },
  {
    key: 'websiteURL',
    name: 'IT포트폴리오 링크',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging',
  },
  {
    key: 'state',
    name: '상태',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging align-center',
  },
  {
    key: 'action',
    name: '처리',
    resizable: true,
    width: 350,
    cellClass: 'align-center',
  },
];

export default {
  blueCCFilters,
  redCCFilters,
  ccColumns,
  blueTalentFilters,
  redTalentFilters,
  talentColumns,
};
