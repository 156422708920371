import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 50px;
  border-radius: 20px;
  width: 1050px;
  height: calc(100vh - 100px);
  background-color: white;
`;

export const Header = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #c5c5c5;
  padding: 24px;
`;

export const Content = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 20px 24px;
  background-color: #eeeeee;
  overflow-y: auto;
`;

export const Footer = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid #c5c5c5;
  padding: 24px;
`;

export const HeaderWrapper = styled.div`
  flex: 0 0 auto;
`;

export const HeaderSpacer = styled.div`
  flex: 1 1 0;
  width: auto;
`;

export const HeaderTitleBigText = styled.p`
  color: #111111;
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
`;

export const HeaderTitleSmallText = styled.p`
  color: #787878;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
`;

export const ItemWrapper = styled.div`
  border: 2px solid transparent;
  border-radius: 16px;
  background-color: white;
  & + & {
    margin-top: 20px;
  }
`;

export const SelectedItemWrapper = styled(ItemWrapper)`
  border-color: #0c46f2;
`;

export const ItemUpperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 24px 20px;
`;

export const ItemLowerWrapper = styled(ItemUpperWrapper)`
  border-top: 1px solid #dddddd;
  border-radius: 0 0 16px 16px;
  background-color: #f8f8f8;
`;

export const ItemThumbnail = styled.img`
  border-radius: 26px;
`;

export const ItemThumbnailPlaceholder = styled.div`
  border: 1px solid #c5c5c5;
  border-radius: 26px;
  width: 52px;
  height: 52px;
  background-color: #eeeeee;
`;

export const ItemThumbnailSpacer = styled.div`
  width: 20px;
`;

export const ItemProfileWrapper = styled.div`
  display: inline-block;
  width: 320px;
`;

export const ItemProfileBigText = styled.span`
  color: #2c2c2c;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
`;

export const ItemProfileBigTextSpacer = styled.div`
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  width: 1px;
  height: 14px;
  background-color: #c5c5c5;
`;

export const ItemProfileSmallText = styled.p`
  color: #5f5f5f;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
`;

export const ItemProfileSpacer = styled.div`
  width: 20px;
`;

export const ItemScoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 12px;
  padding: 13px 20px;
  background-color: #f8f8f8;
  text-align: center;
`;

export const ItemScoreMainWrapper = styled.div`
  display: inline-block;
  width: 70px;
`;

export const ItemScoreBigText = styled.p`
  color: #111111;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
`;

export const ItemScoreBigBlueText = styled.p`
  color: #0c46f2;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
`;

export const ItemScoreSpacer = styled.div`
  display: inline-block;
  margin-left: 16px;
  margin-right: 16px;
  width: 1px;
  height: 40px;
  background-color: #d8d8d8;
`;

export const ItemScoreSmallText = styled.p`
  color: #5f5f5f;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
`;

export const ItemScoreSectionWrapper = styled.div`
  display: inline-block;
  width: 55px;
  & + & {
    margin-left: 4px;
  }
`;

export const ItemScoreSectionBigText = styled.p`
  color: #929292;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
`;

export const ItemScoreSectionSmallText = styled.p`
  color: #787878;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
`;

export const ItemSelectSpacer = styled.div`
  margin: auto;
`;

export const ItemSelectWrapper = styled.div`
  text-align: right;
`;

export const ItemSelectText = styled.p`
  color: #3c6af5;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
`;

export const ItemSelectTextSpacer = styled.div`
  height: 12px;
`;

export const ItemPositionListText = styled.p`
  flex: 0 0 auto;
  color: #ababab;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
`;

export const ItemPositionListSpacer = styled.div`
  flex: 0 0 auto;
  width: 20px;
`;

export const ItemPositionWrapper = styled.div`
  flex: 1 1 0;
`;

export const ItemPositionText = styled.p`
  display: inline;
  color: #787878;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  & + & {
    margin-left: 18px;
  }
`;

export const FooterWrapper = styled.div`
  flex: 0 0 auto;
`;

export const FooterSpacer = styled.div`
  flex: 1 1 0;
  width: auto;
`;

export const FooterSummaryText = styled.p`
  color: #929292;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
`;

export const FooterSummaryTextSpacer = styled.div`
  flex: 0 0 auto;
  width: 24px;
`;

export const FooterActionButtonSpacer = styled.div`
  display: inline-block;
  width: 8px;
`;

export const buttonStyles = makeStyles({
  selectItem: {
    fontSize: '14px',
    color: 'white',
    backgroundColor: '#0c46f2',
  },
  generateRecomm: {
    fontSize: '14px',
    color: '#3c6af5',
    backgroundColor: '#e6ecfe',
  },
  makeRecomm: {
    fontSize: '14px',
    color: 'white',
    backgroundColor: '#0c46f2',
  },
  closeRecomm: {
    fontSize: '14px',
    color: '#111111',
    backgroundColor: '#eeeeee',
  },
});
