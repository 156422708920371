import { time2Date } from '../helpers/Time';

export interface Recommendation<T = unknown> {
  date: Date;
  closed?: boolean;
  positionDetail: {
    position: string;
    score: number;
  };
  profileDetail?: {
    firstImpression: number;
    skillSetSuitability: number;
    talentSimilarity: number;
  };
  intentDetail?: {
    date: Date;
    state: string;
    response?: string;
    isSecond: boolean;
  };
  offerDetail?: {
    date: Date;
    position: string;
    duty: string;
    onlineDetail?: {
      url: string;
    };
    offlineDetail?: {
      address: string;
    };
    preparation?: string;
    times: {
      date: Date;
      startTime: number;
      endTime: number;
    }[];
  };
  counterOfferDetail?: {
    date: Date;
    times: {
      date: Date;
      startTime: number;
      endTime: number;
    }[];
  };
  interviewDetail?: {
    date: Date;
    startTime: number;
    endTime: number;
  };
  interviewReviewDetail?: {
    date: Date;
    score: number;
    comment?: string;
    review: T;
  };
  resultDetail?: {
    date: Date;
    passed: boolean;
    passedSalary?: number;
    nonPassReason?: string;
    nonPassReasonDetail?: string;
  };
}

export enum RecommendationStage {
  RECOMM = '인재 추천',
  PROFILE_EVAL = '프로필 평가',
  OFFERRED = '면접 제안',
  COUNTER_OFFERRED = '일정 변경 요청',
  PENDING_INTERVIEW = '면접 예정',
  DONE_INTERVIEW = '면접 완료',
  CLOSED = '종료됨',
  PASSED = '최종 합격',
  FAILED = '불합격',
}

export function classifyRecommendationStage(
  recomm: Recommendation,
): RecommendationStage {
  if (recomm.resultDetail)
    return recomm.resultDetail.passed
      ? RecommendationStage.PASSED
      : RecommendationStage.FAILED;

  if (recomm.closed) return RecommendationStage.CLOSED;

  if (recomm.interviewDetail) {
    const now = new Date();
    const { endTime } = time2Date(recomm.interviewDetail);
    return now < endTime
      ? RecommendationStage.PENDING_INTERVIEW
      : RecommendationStage.DONE_INTERVIEW;
  }

  if (recomm.counterOfferDetail) return RecommendationStage.COUNTER_OFFERRED;

  if (recomm.offerDetail) return RecommendationStage.OFFERRED;

  if (recomm.profileDetail) return RecommendationStage.PROFILE_EVAL;

  return RecommendationStage.RECOMM;
}
