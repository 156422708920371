import {
  createMuiTheme,
  styled as mstyled,
  Backdrop,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  ThemeProvider,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React, { ReactElement, useEffect, useState } from 'react';
import DataGrid, { Column } from 'react-data-grid';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { useSort } from '../features/Sort';

import {
  addNotification,
  deleteNotification,
  fetchNotifications,
} from '../helpers/Api';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  margin: 0 auto;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  padding-top: 16px;

  & > * + * {
    margin-left: 8px;
  }
`;
const Splitter = styled.hr`
  margin: 20px 0 0;
  border: none;
  height: 1px;
  background-color: #dddddd;
`;
const DataGridWrapper = styled.div`
  margin-top: 35px;
  height: 1286px;
`;

const SettingSelect = mstyled(Select)({
  flex: '1 1 auto',
});
const AddButton = mstyled(Button)({
  flex: '0 0 auto',
  width: '102px',
  height: 'auto',
  color: 'white',
});
const BackgroundCover = mstyled(Backdrop)({
  color: 'black',
  zIndex: 1,
});

export enum Trigger {
  // Simple application-related notifications for admins
  ADMIN_NOTIFICATION_APPLICATION_CC_INCOME = 'AN_application_cc_income',
  ADMIN_NOTIFICATION_APPLICATION_CC_REINCOME = 'AN_application_cc_reincome',
  ADMIN_NOTIFICATION_APPLICATION_TALENT_INCOME = 'AN_application_talent_income',
  ADMIN_NOTIFICATION_APPLICATION_TALENT_REINCOME = 'AN_application_talent_reincome',
  ADMIN_NOTIFICATION_APPLICATION_TALENT_TESTED = 'AN_application_talent_tested',

  // Simple platform-related notifications for admins
  ADMIN_NOTIFICATION_PLATFORM_CC_RECOMMENDATIONEVAL_HIGH = 'AN_platform_cc_recommendationeval_high',
  ADMIN_NOTIFICATION_PLATFORM_CC_INTERVIEW_PASSED = 'AN_platform_cc_interview_passed',
  ADMIN_NOTIFICATION_PLATFORM_CC_INTERVIEW_FAILED = 'AN_platform_cc_interview_failed',
  ADMIN_NOTIFICATION_PLATFORM_CC_INTERVIEWEVAL = 'AN_platform_cc_intervieweval',
  ADMIN_NOTIFICATION_PLATFORM_TALENT_INTERVIEWEVAL = 'AN_platform_talent_intervieweval',

  // Application-related notifications for talents
  APPLICATION_TALENT_INCOME = 'A_talent_income',
  APPLICATION_TALENT_TESTING = 'A_talent_testing',
  APPLICATION_TALENT_APPROVED = 'A_talent_approved',
  APPLICATION_TALENT_REJECTED_NOT_QUALIFIED = 'A_talent_rejected_not_qualified',
  APPLICATION_TALENT_REJECTED_TEST_BELOW = 'A_talent_rejected_test_below',
  APPLICATION_TALENT_REJECTED_TEST_CHEAT = 'A_talent_rejected_test_cheat',
  APPLICATION_TALENT_REJECTED_TEST_IGNORED = 'A_talent_rejected_test_ignored',

  // Application-related notifications for talents (for Open Scouts)
  APPLICATION_OPEN_SCOUT_TALENT_INCOME = 'AOC_talent_income',
  APPLICATION_OPEN_SCOUT_TALENT_APPROVED = 'AOC_talent_approved',
  APPLICATION_OPEN_SCOUT_TALENT_REJECTED_NOT_QUALIFIED = 'AOC_talent_rejected_not_qualified',

  // Application-related notifications for companies
  APPLICATION_CC_INCOME = 'A_cc_income',
  APPLICATION_CC_APPROVED = 'A_cc_approved',
  APPLICATION_CC_SENT_SERVICE_GUIDE = 'A_cc_sent_service_guide',
  APPLICATION_CC_SENT_PRICING = 'A_cc_sent_pricing',
  APPLICATION_CC_REJECTED = 'A_cc_rejected',

  // Platform activity notifications
  PLATFORM_TALENT_INTERVIEWOFFER_INCOME = 'P_talent_interviewoffer_income',
  PLATFORM_TALENT_COUNTERINTERVIEWOFFER_EXPIRED = 'P_talent_counterinterviewoffer_expired',
  PLATFORM_TALENT_COUNTERINTERVIEWOFFER_ACCEPTED = 'P_talent_counterinterviewoffer_accepted',
  PLATFORM_TALENT_COUNTERINTERVIEWOFFER_REJECTED = 'P_talent_counterinterviewoffer_rejected',
  PLATFORM_TALENT_INTERVIEW_EXECUTED = 'P_talent_interview_executed',
  PLATFORM_TALENT_INTERVIEW_PASSED = 'P_talent_interview_passed',
  PLATFORM_TALENT_INTERVIEW_NOTPASSED = 'P_talent_interview_notpassed',
  PLATFORM_TALENT_REMIND_INTERVIEWOFFERDD1 = 'P_talent_remind_interviewofferdd1',
  PLATFORM_TALENT_REMIND_INTERVIEWDD1 = 'P_talent_remind_interviewdd1',
  PLATFORM_TALENT_REMIND_INTERVIEWDH3 = 'P_talent_remind_interviewdh3',
  PLATFORM_TALENT_REQUEST_INTERVIEWOFFERREVIEW = 'P_talent_request_interviewofferreview',
  PLATFORM_TALENT_REQUEST_INTERVIEWEVAL = 'P_talent_request_intervieweval',
  PLATFORM_TALENT_NOTIFY_HIGHLYRATED = 'P_talent_notify_highlyrated',

  PLATFORM_CC_RECOMMENDATION_NEW = 'P_cc_recommendation_new',
  PLATFORM_CC_COUNTERINTERVIEWOFFER_INCOME = 'P_cc_counterinterviewoffer_income',
  PLATFORM_CC_INTERVIEWOFFER_EXPIRED = 'P_cc_interviewoffer_expired',
  PLATFORM_CC_INTERVIEWOFFER_ACCEPTED = 'P_cc_interviewoffer_accepted',
  PLATFORM_CC_INTERVIEWOFFER_REJECTED = 'P_cc_interviewoffer_rejected',
  PLATFORM_CC_INTERVIEW_EXECUTED = 'P_cc_interview_executed',
  PLATFORM_CC_INTERVIEW_PASSED = 'P_cc_interview_passed',
  PLATFORM_CC_REMIND_RECOMMENDATIONDD5 = 'P_cc_remind_recommendationdd5',
  PLATFORM_CC_REMIND_COUNTERINTERVIEWOFFERDD1 = 'P_cc_remind_counterinterviewofferdd1',
  PLATFORM_CC_REMIND_INTERVIEWDD1 = 'P_cc_remind_interviewdd1',
  PLATFORM_CC_REMIND_INTERVIEWDH3 = 'P_cc_remind_interviewdh3',
  PLATFORM_CC_REQUEST_RECOMMENDATIONREVIEW = 'P_cc_request_recommendationreview',
  PLATFORM_CC_REQUEST_RECOMMENDATIONEVAL = 'P_cc_request_recommendationeval',
  PLATFORM_CC_REQUEST_INTERVIEWOFFER = 'P_cc_request_interviewoffer',
  PLATFORM_CC_REQUEST_INTERVIEWRESULT = 'P_cc_request_interviewresult',

  // New notifcations after the talentX point policy applied
  APP_TALENT_SIGNUP = 'APP_TALENT_SIGNUP',
  APP_TALENT_PROFILE_CREATED = 'APP_TALENT_PROFILE_CREATED',
  APP_TALENT_PROFILE_APPROVED = 'APP_TALENT_PROFILE_APPROVED',
  APP_TALENT_MEMBERSHIP_UPGRADED_TO_PREMIUM = 'APP_TALENT_MEMBERSHIP_UPGRADED_TO_PREMIUM',
  APP_TALENT_PROFILE_PORTFOLIO_URL_INVALID = 'APP_TALENT_PROFILE_PORTFOLIO_URL_INVALID',
  APP_TALENT_PROFILE_RECOMMENDED = 'APP_TALENT_PROFILE_RECOMMENDED',
  APP_TALENT_PROFILE_HIGHLY_RATED = 'APP_TALENT_PROFILE_HIGHLY_RATED',
  APP_TALENT_INTENT_ASKED_1ST = 'APP_TALENT_INTENT_ASKED_1ST',
  APP_TALENT_INTENT_ASKED_2ND = 'APP_TALENT_INTENT_ASKED_2ND',
  APP_TALENT_INTENT_ANSWER = 'APP_TALENT_INTENT_ANSWER',
  APP_TALENT_INTERVIEW_OFFERED = 'APP_TALENT_INTERVIEW_OFFERED',
  APP_TALENT_INTERVIEW_OFFER_ANSWER = 'APP_TALENT_INTERVIEW_OFFER_ANSWER',
  APP_TALENT_INTERVIEW_OFFER_UNREAD = 'APP_TALENT_INTERVIEW_OFFER_UNREAD',
  APP_TALENT_INTERVIEW_OFFER_EXPIRE_DD1 = 'APP_TALENT_INTERVIEW_OFFER_EXPIRE_DD1',
  APP_TALENT_INTERVIEW_COUNTEROFFER_ACCEPTED = 'APP_TALENT_INTERVIEW_COUNTEROFFER_ACCEPTED',
  APP_TALENT_INTERVIEW_COUNTEROFFER_REJECTED = 'APP_TALENT_INTERVIEW_COUNTEROFFER_REJECTED',
  APP_TALENT_INTERVIEW_COUNTEROFFER_EXPIRED = 'APP_TALENT_INTERVIEW_COUNTEROFFER_EXPIRED',
  APP_TALENT_INTERVIEW_DD1 = 'APP_TALENT_INTERVIEW_DD1',
  APP_TALENT_INTERVIEW_DH3 = 'APP_TALENT_INTERVIEW_DH3',
  APP_TALENT_INTERVIEW_FINISHED = 'APP_TALENT_INTERVIEW_FINISHED',
  APP_TALENT_INTERVIEW_REVIEW_NOT_YET = 'APP_TALENT_INTERVIEW_REVIEW_NOT_YET',
  APP_TALENT_INTERVIEW_REVIEW = 'APP_TALENT_INTERVIEW_REVIEW',
  APP_TALENT_INTERVIEW_PASSED = 'APP_TALENT_INTERVIEW_PASSED',
  APP_TALENT_INTERVIEW_NOT_PASSED = 'APP_TALENT_INTERVIEW_NOT_PASSED',

  APP_TALENT_INVITEE_MEMBERSHIP_UPGRADED_TO_PREMIUM = 'APP_TALENT_INVITEE_MEMBERSHIP_UPGRADED_TO_PREMIUM',
}

function humanizeTrigger(trigger: Trigger): string {
  switch (trigger) {
    case Trigger.ADMIN_NOTIFICATION_APPLICATION_CC_INCOME:
      return '어드민_기업_신청';
    case Trigger.ADMIN_NOTIFICATION_APPLICATION_CC_REINCOME:
      return '어드민_기업_신청(업데이트)';
    case Trigger.ADMIN_NOTIFICATION_APPLICATION_TALENT_INCOME:
      return '어드민_인재_신청';
    case Trigger.ADMIN_NOTIFICATION_APPLICATION_TALENT_REINCOME:
      return '어드민_인재_신청(업데이트)';
    case Trigger.ADMIN_NOTIFICATION_APPLICATION_TALENT_TESTED:
      return '어드민_인재_코딩테스트_완료';
    case Trigger.ADMIN_NOTIFICATION_PLATFORM_CC_RECOMMENDATIONEVAL_HIGH:
      return '어드민_기업_인재_고평가';
    case Trigger.ADMIN_NOTIFICATION_PLATFORM_CC_INTERVIEW_PASSED:
      return '어드민_기업_인재_합격';
    case Trigger.ADMIN_NOTIFICATION_PLATFORM_CC_INTERVIEW_FAILED:
      return '어드민_기업_인재_불합격';
    case Trigger.ADMIN_NOTIFICATION_PLATFORM_CC_INTERVIEWEVAL:
      return '어드민_기업_면접_평가';
    case Trigger.ADMIN_NOTIFICATION_PLATFORM_TALENT_INTERVIEWEVAL:
      return '어드민_인재_면접_평가';
    case Trigger.APPLICATION_TALENT_INCOME:
      return 'web_인재_회원신청서_제출';
    case Trigger.APPLICATION_TALENT_TESTING:
      return 'web_인재_회원신청서_합격_코딩테스트_발송';
    case Trigger.APPLICATION_TALENT_APPROVED:
      return 'web_인재_코딩테스트_합격_승인';
    case Trigger.APPLICATION_TALENT_REJECTED_NOT_QUALIFIED:
      return 'web_인재_회원신청서_불합격_미승인';
    case Trigger.APPLICATION_TALENT_REJECTED_TEST_BELOW:
      return 'web_인재_코딩테스트_불합격_미승인';
    case Trigger.APPLICATION_TALENT_REJECTED_TEST_CHEAT:
      return 'web_인재_코딩테스트_부정행위_불합격_미승인';
    case Trigger.APPLICATION_TALENT_REJECTED_TEST_IGNORED:
      return 'web_인재_코딩테스트_미응시_미승인';
    case Trigger.APPLICATION_OPEN_SCOUT_TALENT_INCOME:
      return 'web_인재_오픈스카우트_지원서_제출';
    case Trigger.APPLICATION_OPEN_SCOUT_TALENT_APPROVED:
      return 'web_인재_오픈스카우트_지원서_합격';
    case Trigger.APPLICATION_OPEN_SCOUT_TALENT_REJECTED_NOT_QUALIFIED:
      return 'web_인재_오픈스카우트_지원서_불합격_미승인';
    case Trigger.APPLICATION_CC_INCOME:
      return 'web_기업_회원신청서_제출';
    case Trigger.APPLICATION_CC_APPROVED:
      return 'web_기업_회원신청서_합격_승인';
    case Trigger.APPLICATION_CC_SENT_SERVICE_GUIDE:
      return 'web_기업_회원신청서_가격정책전송';
    case Trigger.APPLICATION_CC_SENT_PRICING:
      return 'web_기업_회원신청서_보증금납부';
    case Trigger.APPLICATION_CC_REJECTED:
      return 'web_기업_회원신청서_불합격_미승인';
    case Trigger.PLATFORM_TALENT_INTERVIEWOFFER_INCOME:
      return 'app_인재_면접제안_받음';
    case Trigger.PLATFORM_TALENT_COUNTERINTERVIEWOFFER_EXPIRED:
      return 'app_인재_일정변경요청_만료';
    case Trigger.PLATFORM_TALENT_COUNTERINTERVIEWOFFER_ACCEPTED:
      return 'app_인재_일정변경요청_수락';
    case Trigger.PLATFORM_TALENT_COUNTERINTERVIEWOFFER_REJECTED:
      return 'app_인재_일정변경요청_거절';
    case Trigger.PLATFORM_TALENT_INTERVIEW_EXECUTED:
      return 'app_인재_면접_완료';
    case Trigger.PLATFORM_TALENT_INTERVIEW_PASSED:
      return 'app_인재_면접_합격';
    case Trigger.PLATFORM_TALENT_INTERVIEW_NOTPASSED:
      return 'app_인재_면접_불합격';
    case Trigger.PLATFORM_TALENT_REMIND_INTERVIEWOFFERDD1:
      return 'app_인재_리마인더_면접제안만료_하루전';
    case Trigger.PLATFORM_TALENT_REMIND_INTERVIEWDD1:
      return 'app_인재_리마인더_면접_하루전';
    case Trigger.PLATFORM_TALENT_REMIND_INTERVIEWDH3:
      return 'app_인재_리마인더_면접_세시간전';
    case Trigger.PLATFORM_TALENT_REQUEST_INTERVIEWOFFERREVIEW:
      return 'app_인재_면접제안_확인독려';
    case Trigger.PLATFORM_TALENT_REQUEST_INTERVIEWEVAL:
      return 'app_인재_면접완료_평가독려';
    case Trigger.PLATFORM_TALENT_NOTIFY_HIGHLYRATED:
      return 'app_인재_고평가_알림';
    case Trigger.PLATFORM_CC_RECOMMENDATION_NEW:
      return 'app_기업_인재추천_받음';
    case Trigger.PLATFORM_CC_COUNTERINTERVIEWOFFER_INCOME:
      return 'app_기업_일정변경요청_받음';
    case Trigger.PLATFORM_CC_INTERVIEWOFFER_EXPIRED:
      return 'app_기업_면접제안_만료';
    case Trigger.PLATFORM_CC_INTERVIEWOFFER_ACCEPTED:
      return 'app_기업_면접제안_수락';
    case Trigger.PLATFORM_CC_INTERVIEWOFFER_REJECTED:
      return 'app_기업_면접제안_거절';
    case Trigger.PLATFORM_CC_INTERVIEW_EXECUTED:
      return 'app_기업_면접_완료';
    case Trigger.PLATFORM_CC_INTERVIEW_PASSED:
      return 'app_기업_면접_합격';
    case Trigger.PLATFORM_CC_REMIND_RECOMMENDATIONDD5:
      return 'app_기업_리마인더_인재추천_5일전';
    case Trigger.PLATFORM_CC_REMIND_COUNTERINTERVIEWOFFERDD1:
      return 'app_기업_리마인더_일정변경요청_하루전';
    case Trigger.PLATFORM_CC_REMIND_INTERVIEWDD1:
      return 'app_기업_리마인더_면접_하루전';
    case Trigger.PLATFORM_CC_REMIND_INTERVIEWDH3:
      return 'app_기업_리마인더_면접_세시간전';
    case Trigger.PLATFORM_CC_REQUEST_RECOMMENDATIONREVIEW:
      return 'app_기업_인재추천_확인독려';
    case Trigger.PLATFORM_CC_REQUEST_RECOMMENDATIONEVAL:
      return 'app_기업_인재추천_평가독려';
    case Trigger.PLATFORM_CC_REQUEST_INTERVIEWOFFER:
      return 'app_기업_면접_제안독려';
    case Trigger.PLATFORM_CC_REQUEST_INTERVIEWRESULT:
      return 'app_기업_면접_결과독려';

    default:
      return trigger;
  }
}

export default withRouter(function EmailSetting(
  props: RouteComponentProps & {
    token: string;
    registerOnRefresh(listener: () => void): void;
  },
): ReactElement {
  const [loading, setLoading] = useState(0);
  const [notifications, setNotifications] = useState<null | {
    triggers: {
      admin: Trigger[];
      platform: Trigger[];
    };
    notifications: {
      id: string;
      trigger: Trigger;
      emailTemplateName?: string;
      notificationTemplateName?: string;
    }[];
    templates: {
      emails: {
        templateId: string;
        name: string;
        subject: string;
        content: string;
        createdAt: Date;
      }[];
      notifications: {
        templateId: string;
        name: string;
        content: string;
        buttons: string;
        createdAt: Date;
      }[];
    };
  }>(null);
  const [sort, setSort, sortedRows] = useSort(
    notifications?.notifications ?? [],
    JSON.parse(localStorage.getItem(`${props.match.url}@sort`) || '[]'),
  );
  const [selectedTrigger, setSelectedTrigger] = useState<'' | string>('');
  const [selectedEmailTemplateIndex, setSelectedEmailTemplateIndex] = useState<
    '' | number
  >('');
  const [
    selectedNotificationTemplateIndex,
    setSelectedNotificationTemplateIndex,
  ] = useState<'' | number>('');

  const handleClickAddButton = () => {
    (async () => {
      try {
        await addNotification(
          props,
          selectedTrigger,
          notifications && typeof selectedEmailTemplateIndex === 'number'
            ? {
                id: notifications.templates.emails[selectedEmailTemplateIndex]
                  .templateId,
                name: notifications.templates.emails[selectedEmailTemplateIndex]
                  .name,
                subject:
                  notifications.templates.emails[selectedEmailTemplateIndex]
                    .subject,
                content:
                  notifications.templates.emails[selectedEmailTemplateIndex]
                    .content,
              }
            : undefined,
          notifications && typeof selectedNotificationTemplateIndex === 'number'
            ? {
                id: notifications.templates.notifications[
                  selectedNotificationTemplateIndex
                ].templateId,
                name: notifications.templates.notifications[
                  selectedNotificationTemplateIndex
                ].name,
                content:
                  notifications.templates.notifications[
                    selectedNotificationTemplateIndex
                  ].content,
                buttons:
                  notifications.templates.notifications[
                    selectedNotificationTemplateIndex
                  ].buttons,
              }
            : undefined,
          (loading) =>
            setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
        );
        updateData(true);
      } catch (response) {
        console.log(await response.text());
        if (response.status === 401) props.history.push('/login');
        else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
      }
    })();
  };
  const handleClickRemoveButton = (id: string) => {
    (async () => {
      try {
        await deleteNotification(props, id, (loading) =>
          setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
        );
        updateData(true);
      } catch (response) {
        console.log(await response.text());
        if (response.status === 401) props.history.push('/login');
        else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
      }
    })();
  };

  const updateData = async (purgeCache?: boolean) => {
    try {
      const notifications = await fetchNotifications(
        props,
        (loading) =>
          setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
        purgeCache ?? false,
      );
      console.log('notifications!!!!!!!!!!!!!!!!!!!!!', notifications);
      setNotifications(notifications);
    } catch (response) {
      if (response.status === 401) props.history.push('/login');
      else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
    }
  };

  useEffect(() => {
    props.registerOnRefresh(() => {
      updateData(true);
    });
  }, []);
  useEffect(() => {
    updateData();
  }, []);

  const columns: Column<{
    id: string;
    trigger: Trigger;
    emailTemplateName?: string;
    notificationTemplateName?: string;
  }>[] = [
    {
      key: 'action',
      name: '처리',
      frozen: true,
      resizable: true,
      width: 100,
      cellClass: 'align-center',
      formatter: function ActionCell(props): ReactElement {
        return (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleClickRemoveButton(props.row.id)}
          >
            삭제
          </Button>
        );
      },
    },
    {
      key: 'trigger',
      name: '전송 조건',
      frozen: true,
      sortable: true,
      resizable: true,
      width: 350,
      cellClass: 'enable-dragging',
      formatter: function TriggerCell(props): ReactElement {
        return <>{humanizeTrigger(props.row.trigger)}</>;
      },
    },
    {
      key: 'emailTemplateName',
      name: '이메일 템플릿',
      sortable: true,
      resizable: true,
      width: 350,
      cellClass: 'enable-dragging',
    },
    {
      key: 'talkTemplateName',
      name: '알림톡 템플릿',
      sortable: true,
      resizable: true,
      width: 350,
      cellClass: 'enable-dragging',
    },
  ];

  return (
    <>
      <Wrapper>
        <InputWrapper>
          <SettingSelect
            variant="outlined"
            value={selectedTrigger}
            SelectDisplayProps={{
              style: {
                whiteSpace: 'normal',
              },
            }}
            onChange={(event) =>
              setSelectedTrigger(event.target.value as string)
            }
            displayEmpty
            fullWidth
          >
            <MenuItem value="" disabled>
              조건을 선택해주세요
            </MenuItem>
            {notifications?.triggers.admin.map((trigger) => (
              <MenuItem key={trigger} value={trigger}>
                {humanizeTrigger(trigger)}
              </MenuItem>
            ))}
            {notifications?.triggers.platform.map((trigger) => (
              <MenuItem key={trigger} value={trigger}>
                {humanizeTrigger(trigger)}
              </MenuItem>
            ))}
          </SettingSelect>
          <SettingSelect
            variant="outlined"
            value={selectedEmailTemplateIndex}
            SelectDisplayProps={{
              style: {
                whiteSpace: 'normal',
              },
            }}
            MenuProps={{
              style: {
                whiteSpace: 'normal',
              },
            }}
            onChange={(event) =>
              setSelectedEmailTemplateIndex(event.target.value as number)
            }
            displayEmpty
            fullWidth
          >
            <MenuItem value="">이메일을 선택해주세요</MenuItem>
            {notifications?.templates.emails.map((email, index) => (
              <MenuItem key={index} value={index}>
                {email.name}
              </MenuItem>
            ))}
          </SettingSelect>
          <SettingSelect
            variant="outlined"
            value={selectedNotificationTemplateIndex}
            SelectDisplayProps={{
              style: {
                whiteSpace: 'normal',
              },
            }}
            MenuProps={{
              style: {
                whiteSpace: 'normal',
              },
            }}
            onChange={(event) =>
              setSelectedNotificationTemplateIndex(event.target.value as number)
            }
            displayEmpty
            fullWidth
          >
            <MenuItem value="">알림톡을 선택해주세요</MenuItem>
            {notifications?.templates.notifications.map((noti, index) => (
              <MenuItem key={index} value={index}>
                {noti.name}
              </MenuItem>
            ))}
          </SettingSelect>
          <ThemeProvider
            theme={createMuiTheme({
              palette: {
                primary: grey,
              },
            })}
          >
            <AddButton
              variant="contained"
              color="primary"
              onClick={handleClickAddButton}
              disabled={
                !(
                  selectedTrigger &&
                  (typeof selectedEmailTemplateIndex === 'number' ||
                    typeof selectedNotificationTemplateIndex === 'number')
                )
              }
            >
              추가하기
            </AddButton>
          </ThemeProvider>
        </InputWrapper>
        <Splitter />
        <DataGridWrapper>
          <DataGrid
            style={{ height: '100%' }}
            rowHeight={45}
            rows={sortedRows}
            columns={columns}
            sorts={sort}
            onSort={(sorts) => {
              setSort(sorts);
              localStorage.setItem(
                `${props.match.url}@sort`,
                JSON.stringify(sorts),
              );
            }}
          />
        </DataGridWrapper>
      </Wrapper>
      <BackgroundCover open={loading !== 0}>
        <CircularProgress />
      </BackgroundCover>
    </>
  );
});
