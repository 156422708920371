import {
  makeStyles,
  styled as mstyled,
  Backdrop,
  Button,
  CircularProgress,
  Menu,
  createMuiTheme,
  MuiThemeProvider,
  useMediaQuery,
} from '@material-ui/core';
import { ArrowDropDown, Close } from '@material-ui/icons';
import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import DataGrid, { Column } from 'react-data-grid';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { useSort } from '../features/Sort';

import CCColumns, { CCRowModel, CCTalentRowModel } from '../schemas/CC';

import { searchClient } from '../search';

import {
  fetchCCProfile,
  fetchCCs,
  fetchCCRecommendations,
  queryCCs,
  TARGET_SERVER_SEARCH_INDEX_PREFIX,
} from '../helpers/Api';

import '@fortawesome/fontawesome-free/css/all.css';
import '../css/datagrid.css';
import Format from '../helpers/Format';
import { throttle } from 'lodash';

const Wrapper = styled.div`
  padding: 64px 36px 36px;
`;
const SearchBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const SearchBarInput = styled.input`
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  border: 0;
  border-radius: 20px;
  padding: 0 16px;
  height: 40px;
  background-color: #eeeeee;
  width: 100%;
  min-width: 150px;
  max-width: 330px;
`;
const Spacer = styled.span`
  margin: 0 auto;
`;
const TitleText = styled.h1`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #222222;
`;
const DataGridWrapper = styled.div`
  margin-top: 24px;
  height: 1286px;
`;
const MenuInnerWrapper = styled.div`
  padding: 8px 16px;
  outline: none;
`;
const MenuTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const MenuTitleText = styled.h2`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #444444;
`;
const MenuTitleSpacer = styled.span`
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  width: auto;
`;
const MenuItemWrapper = styled.div`
  margin-top: 8px;
`;
const MenuItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0;
`;
const MenuTextBold = styled.p`
  min-width: 200px;
  font-size: 14px;
  font-weight: bold;
  color: #222222;
`;
const MenuTextNormal = styled.p`
  font-size: 16px;
  font-weight: normal;
  color: #222222;
`;
const MenuLine = styled.hr`
  margin: 0;
  border: none;
  height: 1px;
  background-color: #dddddd;
`;

const BackgroundCover = mstyled(Backdrop)({
  color: 'black',
  zIndex: 1,
});
const MenuButton = mstyled(Button)({
  marginLeft: '16px',
  padding: '5px 8px',
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#222222',
});
const dataGridLinkCellStyle = makeStyles({
  root: {
    fontSize: '14px',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
});
const UserDetailButton = mstyled(Button)({
  padding: '5px 8px',
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#222222',
  boxShadow: 'none',
  '&:hover, &:active': {
    boxShadow: 'none',
  },
});
const MenuTitleCloseButton = mstyled(Button)({
  padding: '6px',
  minWidth: '1em',
  minHeight: '1em',
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#222222',
});

export default withRouter(function CCDashboard(
  props: RouteComponentProps & {
    token: string;
  },
): ReactElement {
  const [loading, setLoading] = useState(false);
  const [oldRows, setOldRows] = useState<CCRowModel[]>([]);
  const [rows, setRows] = useState<CCRowModel[]>([]);
  const [sort, setSort, sortedRows] = useSort(
    rows,
    JSON.parse(localStorage.getItem(`${props.match.url}@sort`) || '[]'),
  );
  const [userAnchor, setUserAnchor] = useState<null | HTMLElement>(null);
  const [userMenuContent, setUserMenuContent] = useState<
    { name: string; email: string; phone: string }[]
  >([]);
  const [onExport, setOnExport] = useState<(() => void) | null>(null);

  const handleClickUserMenuButton = (
    event: React.MouseEvent<HTMLElement>,
    users: { name: string; email: string; phone: string }[],
  ) => {
    setUserAnchor(event.currentTarget);
    setUserMenuContent(users);
  };
  const handleClickUserMenuCloseButton = () => {
    setUserAnchor(null);
  };

  const updateData = async (purgeCache?: boolean) => {
    try {
      const ccs = await fetchCCs(props, setLoading, purgeCache ?? false);
      setOldRows(ccs);
      setRows(ccs);
    } catch (response) {
      if (response.status === 401) props.history.push('/login');
      else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
    }
  };

  useEffect(() => {
    updateData();
  }, []);
  useEffect(() => {
    setOnExport(() => async () => {
      function startToDate(
        time: { date: Date; startTime: number } | undefined,
      ): Date | undefined {
        if (!time) return undefined;
        return new Date(time.date.getTime() + time.startTime * 60 * 1000);
      }
      function endToDate(
        time: { date: Date; endTime: number } | undefined,
      ): Date | undefined {
        if (!time) return undefined;
        return new Date(time.date.getTime() + time.endTime * 60 * 1000);
      }

      const profiles = await Promise.all(
        sortedRows.map((row) =>
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          fetchCCProfile(props, String(row.id), () => {}, true),
        ),
      );
      const recomms = await Promise.all<[string, CCTalentRowModel[]]>(
        sortedRows.map(async (row) => [
          row.companyName,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          await fetchCCRecommendations(props, String(row.id), () => {}, true),
        ]),
      );

      const xlsx = await import('xlsx');
      const wb = xlsx.utils.book_new();
      const ws = xlsx.utils.aoa_to_sheet([
        [
          '번호',
          '가입일',
          '기업명',
          '멤버십',
          '프로필 생성 여부',
          '산업',
          '채용 담당자 1 이름',
          '채용 담당자 1 이메일',
          '채용 담당자 1 전화번호',
          '채용 담당자 1 마케팅 수신 동의 여부',
          '채용 담당자 2 이름',
          '채용 담당자 2 이메일',
          '채용 담당자 2 전화번호',
          '채용 담당자 2 마케팅 수신 동의 여부',
          '채용 담당자 3 이름',
          '채용 담당자 3 이메일',
          '채용 담당자 3 전화번호',
          '채용 담당자 3 마케팅 수신 동의 여부',
          '채용 담당자 4 이름',
          '채용 담당자 4 이메일',
          '채용 담당자 4 전화번호',
          '채용 담당자 4 마케팅 수신 동의 여부',
          '인재 추천',
          '프로필 평가',
          '면접 제안',
          '일정 변경 요청',
          '면접 예정',
          '면접 완료',
          '최종 합격',
          '불합격',
          '종료됨',
          '대표',
          '홈페이지',
          '기업 규모',
          '기업 슬로건',
          '기업 소개',
          '기업 설립연도',
          '전체 사원 수',
          '회사 주소',
          '서비스 명',
          '서비스 설명',
        ],
        ...sortedRows.map((row, index) => [
          index + 1,
          row.joinedAt,
          row.companyName,
          row.plan,
          row.hasProfile ? 'YES' : 'NO',
          row.industry,
          row.users[0]?.name ?? '',
          row.users[0]?.email ?? '',
          row.users[0]?.phone ?? '',
          row.users[0]?.notifyMarketingRelated ? 'YES' : 'NO',
          row.users[1]?.name ?? '',
          row.users[1]?.email ?? '',
          row.users[1]?.phone ?? '',
          row.users[1]?.notifyMarketingRelated ? 'YES' : 'NO',
          row.users[2]?.name ?? '',
          row.users[2]?.email ?? '',
          row.users[2]?.phone ?? '',
          row.users[2]?.notifyMarketingRelated ? 'YES' : 'NO',
          row.users[3]?.name ?? '',
          row.users[3]?.email ?? '',
          row.users[3]?.phone ?? '',
          row.users[3]?.notifyMarketingRelated ? 'YES' : 'NO',
          row.numOfRecomm,
          row.numOfProfileEval,
          row.numOfOffer,
          row.numOfCounterOffer,
          row.numOfInterview,
          row.numOfDoneInterview,
          row.numOfPass,
          row.numOfFail,
          row.numOfTerm,
          profiles[index].ceo ?? '',
          profiles[index].url ?? '',
          profiles[index].scale ?? '',
          profiles[index].slogan ?? '',
          profiles[index].description ?? '',
          profiles[index].since ?? '',
          profiles[index].numOfEmployees ?? '',
          profiles[index].address ?? '',
          profiles[index].services[0]?.name ?? '',
          profiles[index].services[0]?.description ?? '',
        ]),
      ]);
      const ws2 = xlsx.utils.aoa_to_sheet([
        [
          '번호',
          '기업명',
          '이름',
          '추천 직무',
          '매칭 점수',
          '추천 날짜',
          '프로핑 평가: 첫인상 호감도',
          '프로핑 평가: 기술과 경력 매력도',
          '프로핑 평가: 인재 추천 만족도',
          '면접 제안: 제안일',
          '면접 제안: 면접 형태',
          '면접 제안: 제안 직무',
          '면접 제안: 상세 업무',
          '면접 제안: 면접 장소(대면 면접인 경우)',
          '면접 제안: 화상 면접 링크(비대면 면접인 경우)',
          '면접 제안: 준비 사항',
          '면접 제안: 일정 0 시작',
          '면접 제안: 일정 0 끝',
          '면접 제안: 일정 1 시작',
          '면접 제안: 일정 1 끝',
          '면접 제안: 일정 2 시작',
          '면접 제안: 일정 2 끝',
          '면접 제안: 일정 3 시작',
          '면접 제안: 일정 3 끝',
          '면접 제안: 일정 4 시작',
          '면접 제안: 일정 4 끝',
          '면접 제안: 일정 5 시작',
          '면접 제안: 일정 5 끝',
          '면접 제안: 일정 6 시작',
          '면접 제안: 일정 6 끝',
          '면접 제안: 일정 7 시작',
          '면접 제안: 일정 7 끝',
          '면접 제안: 일정 8 시작',
          '면접 제안: 일정 8 끝',
          '면접 제안: 일정 9 시작',
          '면접 제안: 일정 9 끝',
          '일정 변경 요청: 요청일',
          '일정 변경 요청: 일정 0 시작',
          '일정 변경 요청: 일정 0 끝',
          '일정 변경 요청: 일정 1 시작',
          '일정 변경 요청: 일정 1 끝',
          '일정 변경 요청: 일정 2 시작',
          '일정 변경 요청: 일정 2 끝',
          '일정 변경 요청: 일정 3 시작',
          '일정 변경 요청: 일정 3 끝',
          '일정 변경 요청: 일정 4 시작',
          '일정 변경 요청: 일정 4 끝',
          '일정 변경 요청: 일정 5 시작',
          '일정 변경 요청: 일정 5 끝',
          '일정 변경 요청: 일정 6 시작',
          '일정 변경 요청: 일정 6 끝',
          '일정 변경 요청: 일정 7 시작',
          '일정 변경 요청: 일정 7 끝',
          '일정 변경 요청: 일정 8 시작',
          '일정 변경 요청: 일정 8 끝',
          '일정 변경 요청: 일정 9 시작',
          '일정 변경 요청: 일정 9 끝',
          '면접 예정 시작일',
          '면접 예정 종료일',
          '면접 후기: 작성일',
          '면접 후기: 별점',
          '면접 후기: 인재가 면접 시간을 잘 맞추었나요?',
          '면접 후기: 인재의 면접 태도는 좋았나요?',
          '면접 후기: 인재의 경력과 능력 등이 프로필에 적힌 내용과 일치했나요?',
          '면접 후기: 그 외',
          '면접 결과',
          '면접 결과 통보 일자',
          '상태',
        ],
        ...recomms
          .map(([name, recomms]) =>
            recomms.map<[string, CCTalentRowModel]>((recomm) => [name, recomm]),
          )
          .reduce((prev, recomms) => [...prev, ...recomms], [])
          .map((recomm, index) => [
            index + 1,
            recomm[0],
            recomm[1].name,
            recomm[1].recommDetail.positionDetail.position,
            recomm[1].recommDetail.positionDetail.score,
            recomm[1].recommDetail.date,
            recomm[1].recommDetail.profileDetail?.firstImpression ?? '',
            recomm[1].recommDetail.profileDetail?.skillSetSuitability ?? '',
            recomm[1].recommDetail.profileDetail?.talentSimilarity ?? '',
            recomm[1].recommDetail.offerDetail?.date ?? '',
            recomm[1].recommDetail.offerDetail?.onlineDetail
              ? '비대면 면접'
              : recomm[1].recommDetail.offerDetail?.onlineDetail
              ? '대면 면접'
              : '',
            recomm[1].recommDetail.offerDetail?.position ?? '',
            recomm[1].recommDetail.offerDetail?.duty ?? '',
            recomm[1].recommDetail.offerDetail?.offlineDetail?.address ?? '',
            recomm[1].recommDetail.offerDetail?.onlineDetail?.url ?? '',
            recomm[1].recommDetail.offerDetail?.preparation,
            startToDate(recomm[1].recommDetail.offerDetail?.times[0]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[0]) ?? '',
            startToDate(recomm[1].recommDetail.offerDetail?.times[1]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[1]) ?? '',
            startToDate(recomm[1].recommDetail.offerDetail?.times[2]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[2]) ?? '',
            startToDate(recomm[1].recommDetail.offerDetail?.times[3]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[3]) ?? '',
            startToDate(recomm[1].recommDetail.offerDetail?.times[4]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[4]) ?? '',
            startToDate(recomm[1].recommDetail.offerDetail?.times[5]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[5]) ?? '',
            startToDate(recomm[1].recommDetail.offerDetail?.times[6]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[6]) ?? '',
            startToDate(recomm[1].recommDetail.offerDetail?.times[7]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[7]) ?? '',
            startToDate(recomm[1].recommDetail.offerDetail?.times[8]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[8]) ?? '',
            startToDate(recomm[1].recommDetail.offerDetail?.times[9]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[9]) ?? '',
            recomm[1].recommDetail.counterOfferDetail?.date ?? '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[0]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[0]) ??
              '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[1]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[1]) ??
              '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[2]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[2]) ??
              '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[3]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[3]) ??
              '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[4]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[4]) ??
              '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[5]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[5]) ??
              '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[6]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[6]) ??
              '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[7]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[7]) ??
              '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[8]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[8]) ??
              '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[9]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[9]) ??
              '',
            startToDate(recomm[1].recommDetail.interviewDetail) ?? '',
            endToDate(recomm[1].recommDetail.interviewDetail) ?? '',
            recomm[1].recommDetail.interviewReviewDetail?.date ?? '',
            recomm[1].recommDetail.interviewReviewDetail?.score ?? '',
            recomm[1].recommDetail.interviewReviewDetail?.review.talentTime
              ? 'YES'
              : recomm[1].recommDetail.interviewReviewDetail?.review
                  .talentTime === false
              ? 'NO'
              : '',
            recomm[1].recommDetail.interviewReviewDetail?.review.talentManner
              ? 'YES'
              : recomm[1].recommDetail.interviewReviewDetail?.review
                  .talentManner === false
              ? 'NO'
              : '',
            recomm[1].recommDetail.interviewReviewDetail?.review.talentSuitable
              ? 'YES'
              : recomm[1].recommDetail.interviewReviewDetail?.review
                  .talentSuitable === false
              ? 'NO'
              : '',
            recomm[1].recommDetail.interviewReviewDetail?.comment ?? '',
            recomm[1].recommDetail.resultDetail?.passed
              ? '합격'
              : recomm[1].recommDetail.resultDetail?.passed === false
              ? '불합격'
              : '',
            recomm[1].recommDetail.resultDetail?.date ?? '',
            recomm[1].recommStage,
          ]),
      ]);
      xlsx.utils.book_append_sheet(wb, ws, 'cc');
      xlsx.utils.book_append_sheet(wb, ws2, 'cc recomm stats');
      xlsx.writeFile(wb, 'cc.xlsx');
    });
  }, [sortedRows]);

  const applySearchQuery = useCallback(
    throttle(
      (event: ChangeEvent<HTMLInputElement>) => {
        const query = (event.target.value ?? '').trim();

        if (!query) {
          setRows(oldRows);
          return;
        }

        (async () => {
          const result = await searchClient.search([
            {
              facet: '',
              indexName: `${TARGET_SERVER_SEARCH_INDEX_PREFIX}_cc`,
              query,
            },
          ]);
          const maxPage = result.results[0]?.nbPages ?? 1;
          const pages: number[] = [];

          for (let page = 1; page < maxPage; ++page) pages.push(page);

          const extraResults = await Promise.all(
            pages.map((page) =>
              searchClient.search([
                {
                  facet: '',
                  indexName: `${TARGET_SERVER_SEARCH_INDEX_PREFIX}_cc`,
                  query,
                  params: {
                    page,
                  },
                },
              ]),
            ),
          );

          setRows(
            await queryCCs(
              props,
              [
                ...result.results,
                ...extraResults.flatMap((result) => result.results),
              ]
                .flatMap((result) => result.hits ?? [])
                .map((hit) => Number(hit.objectID)),
            ),
          );
        })().catch(console.error);
      },
      1000,
      {
        leading: false,
        trailing: true,
      },
    ),
    [oldRows],
  );

  const dataGridLinkCellTheme = createMuiTheme({
    palette: {
      primary: {
        main: useMediaQuery('(prefers-color-scheme: dark)')
          ? '#ffffff'
          : '#000000',
      },
    },
  });

  const columns: Column<CCRowModel>[] = CCColumns.columns.filter(
    (column) => column.display,
  );
  columns[0].formatter = function JoinedAtCell(props) {
    return <>{Format.dateFormat.format(props.row.joinedAt)}</>;
  };
  columns[1].formatter = function CompanyNameCell(props) {
    return (
      <MuiThemeProvider theme={dataGridLinkCellTheme}>
        <Button
          variant="text"
          color="primary"
          className={dataGridLinkCellStyle().root}
          component={Link}
          to={`/ccs/${rows[props.row.index].id}`}
        >
          {props.row.companyName}
        </Button>
      </MuiThemeProvider>
    );
  };
  columns[3].formatter = function HasProfileCell(props) {
    return <>{props.row.hasProfile ? 'O' : 'X'}</>;
  };
  columns[5].formatter = function UserCell(innerProps) {
    return (
      <UserDetailButton
        aria-controls="cc-user-menu"
        aria-haspopup="true"
        variant="contained"
        endIcon={<ArrowDropDown />}
        onClick={(event) =>
          handleClickUserMenuButton(event, innerProps.row.users)
        }
      >
        {innerProps.row.users.map((user) => user.name).join(', ')}
      </UserDetailButton>
    );
  };

  return (
    <>
      <Wrapper>
        <SearchBarWrapper>
          <TitleText>기업 목록</TitleText>
          <Spacer />
          <SearchBarInput
            type="text"
            placeholder="기업 검색"
            onChange={applySearchQuery}
          />
          <MenuButton
            variant="text"
            onClick={() => {
              if (onExport) onExport();
            }}
          >
            <i className="fas fa-download" />
            <span className="fa-space">내보내기</span>
          </MenuButton>
          <MenuButton variant="text" onClick={() => updateData(true)}>
            <i className="fas fa-sync-alt" />
            <span className="fa-space">새로고침</span>
          </MenuButton>
        </SearchBarWrapper>
        <DataGridWrapper>
          <DataGrid
            style={{ height: '100%' }}
            rowHeight={45}
            rows={sortedRows}
            columns={columns}
            sorts={sort}
            onSort={(sorts) => {
              setSort(sorts);
              localStorage.setItem(
                `${props.match.url}@sort`,
                JSON.stringify(sorts),
              );
            }}
          />
        </DataGridWrapper>
      </Wrapper>
      <BackgroundCover open={loading}>
        <CircularProgress />
      </BackgroundCover>
      <Menu
        id="cc-user-menu"
        anchorEl={userAnchor}
        open={!!userAnchor}
        keepMounted
      >
        <MenuInnerWrapper>
          <MenuTitleWrapper>
            <MenuTitleText>채용 담당자</MenuTitleText>
            <MenuTitleSpacer />
            <MenuTitleCloseButton
              variant="text"
              onClick={handleClickUserMenuCloseButton}
            >
              <Close />
            </MenuTitleCloseButton>
          </MenuTitleWrapper>
          <MenuItemWrapper>
            {userMenuContent.map((user) => (
              <React.Fragment key={user.email}>
                <MenuItemRow>
                  <MenuTextBold>
                    {user.name} ({user.phone})
                  </MenuTextBold>
                  <MenuTextNormal>{user.email}</MenuTextNormal>
                </MenuItemRow>
                <MenuLine />
              </React.Fragment>
            ))}
          </MenuItemWrapper>
        </MenuInnerWrapper>
      </Menu>
    </>
  );
});
