import { Column } from 'react-data-grid';

import { Recommendation, RecommendationStage } from './Recommendation';

export interface TalentRowModel {
  id: number;
  index: number;
  name: string;
  membership: string;
  email: string;
  phone: string;
  recommendationEnabled: boolean;
  allowedForRecommendation: boolean;
  notifyMarketingRelated: boolean;
  hopePosition: string;
  numOfRecomm: number;
  numOfProfileEval: number;
  numOfPendingIntents: number;
  numOfDoneIntentsYes: number;
  numOfDoneIntentsLater: number;
  numOfDoneIntentsNo: number;
  numOfDoneIntentsNoResponse: number;
  numOfOffer: number;
  numOfCounterOffer: number;
  numOfInterview: number;
  numOfDoneInterview: number;
  numOfPass: number;
  numOfDoneHire: number;
  numOfFail: number;
  numOfTerm: number;
  numOfHardSkills: number;
  numOfCareers: number;
  numOfProjects: number;
  numOfEducations: number;
  lastProfileUpdatedAt: Date;
  positions: string[];
  joinedAt: Date;
}

export interface TalentProfileData {
  name: string;
  thumbnailUrl?: string;
  photoUrls: string[];
  birth?: Date;
  phone?: string;
  address?: string;
  educations: {
    name: string;
    major?: string;
    degree?: string;
    beginAt: Date;
    endAt?: Date;
  }[];
  mainPosition?: string;
  mainCareer?: number;
  totalCareer?: number;
  skills: {
    skill: string;
    score: number;
  }[];
  portfolioUrl?: string;
  careers: {
    name: string;
    position: string;
    beginAt: Date;
    endAt?: Date;
    description?: string;
  }[];
  projects: {
    name: string;
    organization: string;
    team: string;
    position: string;
    beginAt: Date;
    endAt?: Date;
    description: string;
    url?: string;
  }[];
  hopeSalary?: number;
  hopeServiceDuration?: string;
  joinedAt: Date;
}

export interface TalentCCRowModel {
  id: number;
  ccId: number;
  index: number;
  name: string;
  recommDetail: Recommendation<{
    ccTime: boolean;
    ccAmbience: boolean;
    ccClear: boolean;
  }>;
  recommStage: RecommendationStage;
}

interface TalentColumn {
  name: string;
  key: string;
  display?: boolean;
  filter?: {
    black?: boolean;
    stage: RecommendationStage;
  };
}

interface TalentCCColumn {
  name: string;
  key: string;
}

const columns: (TalentColumn & Column<TalentRowModel>)[] = [
  {
    name: '회원번호',
    key: 'id',
    sortable: true,
    resizable: true,
    width: 100,
    cellClass: 'enable-dragging align-center',
    display: true,
  },
  {
    name: '가입일',
    key: 'joinedAt',
    sortable: true,
    resizable: true,
    width: 100,
    cellClass: 'enable-dragging align-center',
    display: true,
  },
  {
    name: '이름',
    key: 'name',
    sortable: true,
    resizable: true,
    width: 100,
    cellClass: 'enable-dragging',
    display: true,
  },
  {
    name: '멤버십',
    key: 'membership',
    sortable: true,
    resizable: true,
    width: 100,
    cellClass: 'enable-dragging align-center',
    display: true,
  },
  {
    name: '이메일',
    key: 'email',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging',
    display: true,
  },
  {
    name: '연락처',
    key: 'phone',
    sortable: true,
    resizable: true,
    width: 170,
    cellClass: 'enable-dragging',
    display: true,
  },
  {
    name: '최종 프로필 변경일',
    key: 'lastProfileUpdatedAt',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging align-center',
    display: true,
  },
  {
    name: '프로필 생성 여부',
    key: 'profileCreated',
    sortKeyGetter: (row) =>
      0 < row.numOfHardSkills &&
      0 < row.numOfCareers + row.numOfProjects &&
      0 < row.numOfEducations,
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging align-center',
    display: true,
  },
  {
    name: '추천 활성화 여부',
    key: 'recommendationEnabled',
    sortable: true,
    resizable: true,
    width: 120,
    cellClass: 'enable-dragging align-center',
    display: true,
  },
  {
    name: '추천 승인 여부',
    key: 'allowedForRecommendation',
    sortable: true,
    resizable: true,
    width: 120,
    cellClass: 'enable-dragging align-center',
    display: true,
  },
  {
    name: '희망 취업 직무',
    key: 'hopePosition',
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging',
    display: true,
  },
  {
    name: '기업 추천',
    key: 'numOfRecomm',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
    filter: {
      stage: RecommendationStage.RECOMM,
    },
  },
  {
    name: '프로필 평가',
    key: 'numOfProfileEval',
    filter: {
      stage: RecommendationStage.PROFILE_EVAL,
    },
  },
  {
    name: '진행중인 의향 파악',
    key: 'numOfPendingIntents',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
  },
  {
    name: '의향 있음',
    key: 'numOfDoneIntentsYes',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
  },
  {
    name: '지금은 의향 없음',
    key: 'numOfDoneIntentsLater',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
  },
  {
    name: '의향 없음',
    key: 'numOfDoneIntentsNo',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
  },
  {
    name: '의향 응답 없음',
    key: 'numOfDoneIntentsNoResponse',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
  },
  {
    name: '면접 제안',
    key: 'numOfOffer',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
    filter: {
      stage: RecommendationStage.OFFERRED,
    },
  },
  {
    name: '일정 변경 요청',
    key: 'numOfCounterOffer',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
    filter: {
      stage: RecommendationStage.COUNTER_OFFERRED,
    },
  },
  {
    name: '면접 예정',
    key: 'numOfInterview',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
    filter: {
      stage: RecommendationStage.PENDING_INTERVIEW,
    },
  },
  {
    name: '면접 완료',
    key: 'numOfDoneInterview',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
    filter: {
      stage: RecommendationStage.DONE_INTERVIEW,
    },
  },
  {
    name: '최종 합격',
    key: 'numOfPass',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
    filter: {
      stage: RecommendationStage.PASSED,
    },
  },
  {
    name: '채용 완료',
    key: 'numOfDoneHire',
  },
  {
    name: '불합격',
    key: 'numOfFail',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
    filter: {
      black: true,
      stage: RecommendationStage.FAILED,
    },
  },
  {
    name: '종료됨',
    key: 'numOfTerm',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
    filter: {
      black: true,
      stage: RecommendationStage.CLOSED,
    },
  },
];
const ccColumns: (TalentCCColumn & Column<TalentCCRowModel>)[] = [
  {
    name: '기업',
    key: 'name',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging',
  },
  {
    name: '추천 직무',
    key: 'position',
    sortKeyGetter: (row) => row.recommDetail.positionDetail.position,
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging',
  },
  {
    name: '매칭 점수',
    key: 'score',
    sortKeyGetter: (row) => row.recommDetail.positionDetail.score,
    sortable: true,
    resizable: true,
    width: 100,
    cellClass: 'enable-dragging align-right',
  },
  {
    name: '추천 날짜',
    key: 'recommDate',
    sortKeyGetter: (row) => row.recommDetail.date,
    sortable: true,
    resizable: true,
    width: 100,
    cellClass: 'enable-dragging align-center',
  },
  {
    name: '프로필 평가',
    key: 'profileEval',
    sortKeyGetter: (row) =>
      (row.recommDetail.profileDetail?.firstImpression ?? 0) +
      (row.recommDetail.profileDetail?.skillSetSuitability ?? 0) +
      (row.recommDetail.profileDetail?.talentSimilarity ?? 0),
    sortable: true,
    resizable: true,
    width: 120,
    cellClass: 'enable-dragging align-right',
  },
  {
    name: '의향 파악',
    key: 'intent',
    sortKeyGetter: (row) => !!row.recommDetail.intentDetail,
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging align-center',
  },
  {
    name: '면접 제안',
    key: 'offer',
    sortKeyGetter: (row) => !!row.recommDetail.offerDetail,
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging align-center',
  },
  {
    name: '일정 변경 요청',
    key: 'counterOffer',
    sortKeyGetter: (row) => !!row.recommDetail.counterOfferDetail,
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging align-center',
  },
  {
    name: '면접 예정',
    key: 'interviewDate',
    sortKeyGetter: (row) =>
      (row.recommDetail.interviewDetail?.date.getTime() ?? 0) +
      (row.recommDetail.interviewDetail?.endTime ?? 0) * 1000 * 60,
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging align-center',
  },
  {
    name: '면접 결과(통보 일자)',
    key: 'result',
    sortKeyGetter: (row) => row.recommDetail.interviewReviewDetail?.date,
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging align-center',
  },
  {
    name: '채용 현황',
    key: 'status',
    sortKeyGetter: (row) => row.recommStage,
    sortable: true,
    resizable: true,
    width: 120,
    cellClass: 'enable-dragging align-center',
  },
  {
    key: 'action',
    name: '처리',
    resizable: true,
    width: 120,
    cellClass: 'align-center',
  },
];

export default {
  columns,
  ccColumns,
};
