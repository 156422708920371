import React, { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';

const InnerWrapper = styled.section`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 600px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  border-right: 1px solid #999999;
  width: 400px;
  background-color: white;
`;
const ContentWrapper = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  overflow-wrap: break-word;
`;

export default function ExtendedSideView(
  props: PropsWithChildren<unknown>,
): ReactElement {
  return (
    <InnerWrapper onClick={(event) => event.stopPropagation()}>
      <ContentWrapper>{props.children}</ContentWrapper>
    </InnerWrapper>
  );
}
