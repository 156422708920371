import {
  styled as mstyled,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  Menu,
  MenuItem,
  Select,
  Tooltip,
  ThemeProvider,
  Link,
} from '@material-ui/core';
import { ArrowDropDown, Close } from '@material-ui/icons';
import { ToggleButtonGroup } from '@material-ui/lab';
import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useState,
  useCallback,
} from 'react';
import DataGrid, { Column } from 'react-data-grid';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import throttle from 'lodash/throttle';
import styled from 'styled-components';

import { useSort } from '../features/Sort';

import FilterButton from '../components/FilterButton';

import approveButtonTheme from '../themes/ApproveButton';
import rejectButtonTheme from '../themes/RejectButton';
import sendButtonTheme from '../themes/SendButton';
import send2ButtonTheme from '../themes/Send2Button';

import Format from '../helpers/Format';

import {
  fetchCCApplications,
  sendServiceGuideCCApplication,
  sendPricingCCApplication,
  approveCCApplication,
  rejectCCApplication,
  deleteCCApplication,
  queryCCApplications,
  TARGET_SERVER_SEARCH_INDEX_PREFIX,
} from '../helpers/Api';

import ApplicationScheme, {
  CCApplicationRejectReason,
  CCApplicationRowModel,
  CCApplicationState,
} from '../schemas/Application';

import '@fortawesome/fontawesome-free/css/all.css';
import '../css/datagrid.css';

import { searchClient } from '../search';

const FilterBarWrapper = styled.div`
  overflow-x: scroll;
  white-space: nowrap;
`;
const FilterBarInnerWrapper = styled.div`
  display: inline-block;
  & + & {
    margin-left: 24px;
  }
`;
const SearchBarInput = styled.input`
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  margin-top: 35px;
  border: 0;
  border-radius: 20px;
  padding: 0 16px;
  height: 40px;
  background-color: #eeeeee;
  width: 100%;
  min-width: 150px;
  max-width: 330px;
`;
const DataGridWrapper = styled.div`
  margin-top: 35px;
  height: 1286px;
`;
const StateText = styled.span`
  font-size: 14px;
  font-weight: normal;
`;
const StateRejectedText = styled.span`
  font-size: 14px;
  font-weight: normal;
  color: #ff5c5c;
`;
const PendingIcon = styled.span`
  display: inline-block;
  margin-right: 4px;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  color: white;
  background-color: #999999;
  text-align: center;
  user-select: none;
`;
const ApproveIcon = styled.span`
  display: inline-block;
  margin-right: 4px;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  color: white;
  background-color: #0c46f2;
  text-align: center;
  user-select: none;
`;
const RejectIcon = styled.span`
  display: inline-block;
  margin-right: 4px;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  color: white;
  background-color: #ff5c5c;
  text-align: center;
  user-select: none;
`;
const MenuInnerWrapper = styled.div`
  padding: 8px 16px;
  outline: none;
`;
const MenuTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const MenuTitleText = styled.h2`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #444444;
`;
const MenuTitleSpacer = styled.span`
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  width: auto;
`;
const MenuItemWrapper = styled.div`
  margin-top: 8px;
`;
const MenuItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0;
`;
const MenuTextBold = styled.p`
  min-width: 200px;
  font-size: 14px;
  font-weight: bold;
  color: #222222;
`;
const MenuTextNormal = styled.p`
  font-size: 16px;
  font-weight: normal;
  color: #222222;
`;
const MenuLine = styled.hr`
  margin: 0;
  border: none;
  height: 1px;
  background-color: #dddddd;
`;
const DialogWrapper = styled.div`
  padding: 32px 20px 20px;
`;
const DialogTitleText = styled.p`
  font-size: 18px;
  font-weight: normal;
  color: #222222;
  text-align: center;
`;
const DialogButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;
const DialogItemRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 32px;
  & > * + * {
    margin-top: 12px;
  }
`;
const DialogItemText = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #222222;
`;

const BackgroundCover = mstyled(Backdrop)({
  color: 'black',
  zIndex: 1,
});
const ApproveButton = mstyled(Button)({
  fontSize: '12px',
  fontWeight: 'normal',
  marginLeft: '4px',
});
const RejectButton = mstyled(Button)({
  fontSize: '12px',
  fontWeight: 'normal',
  marginLeft: '4px',
});
const DeleteButton = mstyled(Button)({
  fontSize: '20px',
  fontWeight: 'normal',
  color: '#666666',
  marginLeft: '4px',
});
const UserDetailButton = mstyled(Button)({
  padding: '5px 8px',
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#222222',
  boxShadow: 'none',
  '&:hover, &:active': {
    boxShadow: 'none',
  },
});
const MenuTitleCloseButton = mstyled(Button)({
  padding: '6px',
  minWidth: '1em',
  minHeight: '1em',
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#222222',
});
const DialogRowButton = mstyled(Button)({
  width: '142px',
  height: '56px',
  fontSize: '16px',
  fontWeight: 'normal',
  boxShadow: 'none',
  '&+&': {
    marginLeft: '12px',
  },
  '&:hover, &:active': {
    boxShadow: 'none',
  },
});
const DialogVerticalButton = mstyled(Button)({
  width: '296px',
  height: '56px',
  fontSize: '16px',
  fontWeight: 'normal',
  boxShadow: 'none',
  '&:hover, &:active': {
    boxShadow: 'none',
  },
});

const filterCount =
  ApplicationScheme.blueCCFilters.length +
  ApplicationScheme.redCCFilters.length;

export default withRouter(function CCApplication(
  props: RouteComponentProps & {
    token: string;
    registerOnExport(listener: () => void): void;
    registerOnRefresh(listener: () => void): void;
  },
): ReactElement {
  const [loading, setLoading] = useState(0);
  const [oldRows, setOldRows] = useState<CCApplicationRowModel[]>([]);
  const [rows, setRows] = useState<CCApplicationRowModel[]>([]);
  const [filter, setFilter] = useState<string[]>(() => ['all']);
  const [filteredRows, setFilteredRows] = useState<CCApplicationRowModel[]>([]);
  const [sort, setSort, sortedRows] = useSort(
    filteredRows,
    JSON.parse(localStorage.getItem(`${props.match.url}@sort`) || '[]'),
  );
  const [userAnchor, setUserAnchor] = useState<null | HTMLElement>(null);
  const [userMenuContent, setUserMenuContent] = useState<
    { name: string; email: string; phone: string }[]
  >([]);
  const [sendServiceGuideDialog, setSendServiceGuideDialog] = useState<null | {
    id: number;
    cc: string;
    callback(id: number): void;
  }>(null);
  const [sendPricingDialog, setSendPricingDialog] = useState<null | {
    id: number;
    cc: string;
    callback(id: number): void;
  }>(null);
  const [approveDialog, setApproveDialog] = useState<null | {
    id: number;
    cc: string;
    callback(id: number): void;
  }>(null);
  const [rejectDialog, setRejectDialog] = useState<null | {
    id: number;
    cc: string;
    callback(id: number, rejectReason: '' | CCApplicationRejectReason): void;
  }>(null);
  const [rejectReason, setRejectReason] = useState<
    '' | CCApplicationRejectReason
  >('');
  const [deleteDialog, setDeleteDialog] = useState<null | {
    id: number;
    cc: string;
    callback(id: number): void;
  }>(null);

  const sendServiceGuideButtonCallback = (id: number) => {
    (async () => {
      await sendServiceGuideCCApplication(props, id, (loading) =>
        setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
      );
      await updateData(true);
    })().catch((response) => {
      if (response.status === 401) props.history.push('/login');
      else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
    });
  };
  const sendPricingButtonCallback = (id: number) => {
    (async () => {
      await sendPricingCCApplication(props, id, (loading) =>
        setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
      );
      await updateData(true);
    })().catch((response) => {
      if (response.status === 401) props.history.push('/login');
      else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
    });
  };
  const approveButtonCallback = (id: number) => {
    (async () => {
      await approveCCApplication(props, id, (loading) =>
        setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
      );
      await updateData(true);
    })().catch((response) => {
      if (response.status === 401) props.history.push('/login');
      else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
    });
  };
  const rejectButtonCallback = (
    id: number,
    rejectReason: '' | CCApplicationRejectReason,
  ) => {
    (async () => {
      await rejectCCApplication(props, id, rejectReason || null, (loading) =>
        setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
      );
      await updateData(true);
    })().catch((response) => {
      if (response.status === 401) props.history.push('/login');
      else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
    });
  };
  const deleteButtonCallback = (id: number) => {
    (async () => {
      await deleteCCApplication(props, id, (loading) =>
        setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
      );
      await updateData(true);
    })().catch((response) => {
      if (response.status === 401) props.history.push('/login');
      else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
    });
  };

  const handleChangeFilter = (
    event: React.MouseEvent<HTMLElement>,
    filter: string[],
  ) => {
    const allIndex = filter.indexOf('all');

    if (allIndex < 0) {
      if (!filter.length || filter.length == filterCount) filter = ['all'];
    } else {
      if (allIndex === 0) filter.splice(allIndex, 1);
      else filter = ['all'];
    }

    setFilter(filter);
  };
  const handleClickSendServiceGuideButton = (id: number, name: string) => {
    setSendServiceGuideDialog({
      id,
      cc: name,
      callback: sendServiceGuideButtonCallback,
    });
  };
  const handleClickSendPricingButton = (id: number, name: string) => {
    setSendPricingDialog({
      id,
      cc: name,
      callback: sendPricingButtonCallback,
    });
  };
  const handleClickApproveButton = (id: number, name: string) => {
    setApproveDialog({
      id,
      cc: name,
      callback: approveButtonCallback,
    });
  };
  const handleClickRejectButton = (id: number, name: string) => {
    setRejectReason('');
    setRejectDialog({
      id,
      cc: name,
      callback: rejectButtonCallback,
    });
  };
  const handleClickDeleteButton = (id: number, name: string) => {
    setDeleteDialog({
      id,
      cc: name,
      callback: deleteButtonCallback,
    });
  };
  const handleClickUserMenuButton = (
    event: React.MouseEvent<HTMLElement>,
    users: { name: string; email: string; phone: string }[],
  ) => {
    setUserAnchor(event.currentTarget);
    setUserMenuContent(users);
  };
  const handleClickUserMenuCloseButton = () => {
    setUserAnchor(null);
  };
  const handleChangeRejectReasonSelect = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setRejectReason(event.target.value as CCApplicationRejectReason);
  };

  const updateData = async (purgeCache?: boolean) => {
    try {
      const applications = await fetchCCApplications(
        props,
        (loading) =>
          setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
        purgeCache ?? false,
      );
      setOldRows(applications);
      setRows(applications);
    } catch (response) {
      if (response.status === 401) props.history.push('/login');
      else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
    }
  };

  useEffect(() => {
    props.registerOnExport(async () => {
      const xlsx = await import('xlsx');
      const wb = xlsx.utils.book_new();
      const ws = xlsx.utils.aoa_to_sheet([
        [
          '번호',
          '신청일자',
          '기업명',
          '사업자등록번호',
          '채용 담당자 1 이름',
          '채용 담당자 1 이메일',
          '채용 담당자 1 전화번호',
          '채용 담당자 2 이름',
          '채용 담당자 2 이메일',
          '채용 담당자 2 전화번호',
          '채용 담당자 3 이름',
          '채용 담당자 3 이메일',
          '채용 담당자 3 전화번호',
          '채용 담당자 4 이름',
          '채용 담당자 4 이메일',
          '채용 담당자 4 전화번호',
          '전채 사원 수',
          '개발팀 인원',
          '개발리더 유무',
          '매출액',
          '투자액',
          '가입 경로',
          '상태',
          '미승인 사유',
        ],
        ...sortedRows.map((row, index) => [
          index + 1,
          row.appliedAt,
          row.name,
          row.registrationNumber,
          row.users[0]?.name ?? '',
          row.users[0]?.email ?? '',
          row.users[0]?.phone ?? '',
          row.users[1]?.name ?? '',
          row.users[1]?.email ?? '',
          row.users[1]?.phone ?? '',
          row.users[2]?.name ?? '',
          row.users[2]?.email ?? '',
          row.users[2]?.phone ?? '',
          row.users[3]?.name ?? '',
          row.users[3]?.email ?? '',
          row.users[3]?.phone ?? '',
          row.numOfEmployees,
          row.numOfDev,
          row.hasDevLeader ? 'YES' : 'NO',
          { v: Number(row.revenue), t: 'n', z: '₩#,##0_);(₩#,##0)' },
          { v: Number(row.fund), t: 'n', z: '₩#,##0_);(₩#,##0)' },
          row.channel || '',
          row.state,
          row.rejectReason,
        ]),
      ]);
      xlsx.utils.book_append_sheet(wb, ws, 'cc applications');
      xlsx.writeFile(wb, 'cc_applications.xlsx');
    });
  }, [sortedRows]);
  useEffect(() => {
    props.registerOnRefresh(() => {
      updateData(true);
    });
  }, []);
  useEffect(() => {
    updateData();
  }, []);
  useEffect(() => {
    if (filter.length === 1 && filter[0] === 'all') {
      setFilteredRows(rows);
      return;
    }

    const filterSet = new Set(filter);
    setFilteredRows(rows.filter((row) => filterSet.has(row.state)));
  }, [rows, filter]);

  const applySearchQuery = useCallback(
    throttle(
      (event: ChangeEvent<HTMLInputElement>) => {
        const query = (event.target.value ?? '').trim();

        if (!query) {
          setRows(oldRows);
          return;
        }

        (async () => {
          const result = await searchClient.search([
            {
              facet: '',
              indexName: `${TARGET_SERVER_SEARCH_INDEX_PREFIX}_cc_application`,
              query,
            },
          ]);
          const maxPage = result.results[0]?.nbPages ?? 1;
          const pages: number[] = [];

          for (let page = 1; page < maxPage; ++page) pages.push(page);

          const extraResults = await Promise.all(
            pages.map((page) =>
              searchClient.search([
                {
                  facet: '',
                  indexName: `${TARGET_SERVER_SEARCH_INDEX_PREFIX}_cc_application`,
                  query,
                  params: {
                    page,
                  },
                },
              ]),
            ),
          );

          setRows(
            await queryCCApplications(
              props,
              [
                ...result.results,
                ...extraResults.flatMap((result) => result.results),
              ]
                .flatMap((result) => result.hits ?? [])
                .map((hit) => Number(hit.objectID)),
            ),
          );
        })().catch(console.error);
      },
      1000,
      {
        leading: false,
        trailing: true,
      },
    ),
    [oldRows],
  );

  const columns: Column<CCApplicationRowModel>[] = ApplicationScheme.ccColumns;

  columns[0].formatter = function AppliedAtCell(innerProps): ReactElement {
    return (
      <>
        {Format.dateFormatWithDay.format(innerProps.row.appliedAt)}{' '}
        {Format.timeFormat.format(innerProps.row.appliedAt)}
      </>
    );
  };
  columns[1].formatter = function MembershipCell(innerProps): ReactElement {
    return <>{innerProps.row.membership ?? '(미선택)'}</>;
  };
  columns[3].formatter = function RegistrationNumberCell(
    innerProps,
  ): ReactElement {
    return (
      <>
        {innerProps.row.registrationNumber.substr(0, 3)}-
        {innerProps.row.registrationNumber.substr(3, 2)}-
        {innerProps.row.registrationNumber.substr(5)}
      </>
    );
  };
  columns[4].formatter = function BusinessEmailCell(innerProps): ReactElement {
    return <>{innerProps.row.businessEmail ?? '(미기입)'}</>;
  };
  columns[5].formatter = function UserCell(innerProps): ReactElement {
    return (
      <UserDetailButton
        aria-controls="cc-user-menu"
        aria-haspopup="true"
        variant="contained"
        endIcon={<ArrowDropDown />}
        onClick={(event) =>
          handleClickUserMenuButton(event, innerProps.row.users)
        }
      >
        {innerProps.row.users.map((user) => user.name).join(', ')}
      </UserDetailButton>
    );
  };
  columns[6].formatter = function NumOfEmployeesCell(innerProps): ReactElement {
    return <>{Format.numberFormat.format(innerProps.row.numOfEmployees)} 명</>;
  };
  columns[7].formatter = function NumOfDevCell(innerProps): ReactElement {
    return <>{Format.numberFormat.format(innerProps.row.numOfDev)} 명</>;
  };
  columns[8].formatter = function HasDevLeaderCell(innerProps): ReactElement {
    return <>{innerProps.row.hasDevLeader ? 'O' : 'X'}</>;
  };
  columns[9].formatter = function RevenueCell(innerProps): ReactElement {
    return <>{Format.curencyFormat.format(innerProps.row.revenue)}</>;
  };
  columns[10].formatter = function FundCell(innerProps): ReactElement {
    return <>{Format.curencyFormat.format(innerProps.row.fund)}</>;
  };
  columns[11].formatter = function ChannelCell(innerProps): ReactElement {
    return <>{innerProps.row.channel ?? '-'}</>;
  };
  columns[16].formatter = function DocumentURLCell(innerProps): ReactElement {
    return innerProps.row.documentURL ? (
      <Link href={innerProps.row.documentURL} target="_blank" rel="noopener">
        {innerProps.row.documentURL}
      </Link>
    ) : (
      <>(미제출)</>
    );
  };
  columns[17].formatter = function StateCell(innerProps): ReactElement {
    switch (innerProps.row.state) {
      case CCApplicationState.PENDING:
        return (
          <>
            <PendingIcon>
              <i className="fas fa-check" />
            </PendingIcon>
            <StateText>가입 신청함</StateText>
          </>
        );
      case CCApplicationState.SENT_SERVICE_GUIDE:
        return (
          <>
            <PendingIcon>
              <i className="fas fa-check" />
            </PendingIcon>
            <StateText>가격 정책 보냄</StateText>
          </>
        );
      case CCApplicationState.SENT_PRICING:
        return (
          <>
            <PendingIcon>
              <i className="fas fa-check" />
            </PendingIcon>
            <StateText>보증금 납부함</StateText>
          </>
        );
      case CCApplicationState.APPROVED:
        return (
          <>
            <ApproveIcon>
              <i className="fas fa-check" />
            </ApproveIcon>
            <StateText>승인됨</StateText>
          </>
        );
      case CCApplicationState.REJECTED: {
        let rejectReason;

        switch (innerProps.row.rejectReason) {
          case CCApplicationRejectReason.BREAKDOWN:
            rejectReason = '계약 결렬';
            break;
          case CCApplicationRejectReason.NOT_QUALIFIED:
            rejectReason = '가입 조건 미달';
            break;
          case CCApplicationRejectReason.UNAUTHORIZED:
            rejectReason = '확인되지 않는 사업자';
            break;
          case CCApplicationRejectReason.NOT_COMPANY_EMAIL:
            rejectReason = '개인 이메일로 신청 불가';
            break;
          case CCApplicationRejectReason.EXCEEDED_ACCOUNT_LIMIT:
            rejectReason = '가입 가능 계정 수 초과';
            break;
          case CCApplicationRejectReason.DUPLICATED:
            rejectReason = '이미 승인되어 있는 사용자';
            break;
          default:
            rejectReason = null;
            break;
        }

        return rejectReason ? (
          <Tooltip title={rejectReason}>
            <span>
              <RejectIcon>
                <i className="fas fa-times" />
              </RejectIcon>
              <StateRejectedText>미승인</StateRejectedText>
            </span>
          </Tooltip>
        ) : (
          <>
            <RejectIcon>
              <i className="fas fa-times" />
            </RejectIcon>
            <StateRejectedText>미승인</StateRejectedText>
          </>
        );
      }
    }
  };
  columns[18].formatter = function ActionCell(innerProps): ReactElement {
    let buttons: [boolean, boolean, boolean, boolean];

    switch (innerProps.row.state) {
      case CCApplicationState.PENDING:
        buttons = [true, false, false, true];
        break;
      case CCApplicationState.SENT_SERVICE_GUIDE:
        buttons = [false, true, false, true];
        break;
      case CCApplicationState.SENT_PRICING:
        buttons = [false, false, true, true];
        break;
      case CCApplicationState.APPROVED:
        buttons = [false, false, false, true];
        break;
      case CCApplicationState.REJECTED:
        buttons = [true, true, true, false];
        break;
    }

    return (
      <>
        <ThemeProvider theme={sendButtonTheme}>
          <ApproveButton
            color="primary"
            variant="contained"
            onClick={() =>
              handleClickSendServiceGuideButton(
                innerProps.row.id as number,
                innerProps.row.name,
              )
            }
            disabled={!buttons[0]}
          >
            가격 정책 전송
          </ApproveButton>
          <ThemeProvider theme={send2ButtonTheme}>
            <ApproveButton
              color="primary"
              variant="contained"
              onClick={() =>
                handleClickSendPricingButton(
                  innerProps.row.id as number,
                  innerProps.row.name,
                )
              }
              disabled={!buttons[1]}
            >
              보증금 납부
            </ApproveButton>
            <ThemeProvider theme={approveButtonTheme}>
              <ApproveButton
                color="primary"
                variant="contained"
                onClick={() =>
                  handleClickApproveButton(
                    innerProps.row.id as number,
                    innerProps.row.name,
                  )
                }
                disabled={!buttons[2]}
              >
                승인
              </ApproveButton>
              <ThemeProvider theme={rejectButtonTheme}>
                <RejectButton
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    handleClickRejectButton(
                      innerProps.row.id as number,
                      innerProps.row.name,
                    )
                  }
                  disabled={!buttons[3]}
                >
                  미승인
                </RejectButton>
              </ThemeProvider>
            </ThemeProvider>
          </ThemeProvider>
        </ThemeProvider>
        <DeleteButton
          style={{ color: '#ff5c5c' }}
          variant="text"
          onClick={() =>
            handleClickDeleteButton(
              innerProps.row.id as number,
              innerProps.row.name,
            )
          }
        >
          <i className="far fa-trash-alt" />
        </DeleteButton>
      </>
    );
  };

  return (
    <>
      <FilterBarWrapper>
        <FilterBarInnerWrapper>
          <ToggleButtonGroup value={filter} onChange={handleChangeFilter}>
            {FilterButton({
              value: 'all',
              label: '전체',
              num: rows.length,
            })}
          </ToggleButtonGroup>
        </FilterBarInnerWrapper>
        <FilterBarInnerWrapper>
          <ToggleButtonGroup value={filter} onChange={handleChangeFilter}>
            {ApplicationScheme.blueCCFilters.map((filter) =>
              FilterButton({
                key: filter.state,
                value: filter.state,
                label: filter.displayName,
                num: rows.filter((row) => row.state === filter.state).length,
              }),
            )}
          </ToggleButtonGroup>
        </FilterBarInnerWrapper>
        <FilterBarInnerWrapper>
          <ToggleButtonGroup value={filter} onChange={handleChangeFilter}>
            {ApplicationScheme.redCCFilters.map((filter) =>
              FilterButton({
                key: filter.state,
                value: filter.state,
                label: filter.displayName,
                num: rows.filter((row) => row.state === filter.state).length,
                red: true,
              }),
            )}
          </ToggleButtonGroup>
        </FilterBarInnerWrapper>
      </FilterBarWrapper>
      <SearchBarInput
        placeholder="기업 신청 검색"
        onChange={applySearchQuery}
      />
      <DataGridWrapper>
        <DataGrid
          style={{ height: '100%' }}
          rowHeight={45}
          rows={sortedRows}
          columns={columns}
          sorts={sort}
          onSort={(sorts) => {
            setSort(sorts);
            localStorage.setItem(
              `${props.match.url}@sort`,
              JSON.stringify(sorts),
            );
          }}
        />
      </DataGridWrapper>
      <BackgroundCover open={loading !== 0}>
        <CircularProgress />
      </BackgroundCover>
      <Menu
        id="cc-user-menu"
        anchorEl={userAnchor}
        open={!!userAnchor}
        keepMounted
      >
        <MenuInnerWrapper>
          <MenuTitleWrapper>
            <MenuTitleText>채용 담당자</MenuTitleText>
            <MenuTitleSpacer />
            <MenuTitleCloseButton
              variant="text"
              onClick={handleClickUserMenuCloseButton}
            >
              <Close />
            </MenuTitleCloseButton>
          </MenuTitleWrapper>
          <MenuItemWrapper>
            {userMenuContent.map((user) => (
              <React.Fragment key={user.email}>
                <MenuItemRow>
                  <MenuTextBold>
                    {user.name} ({user.phone})
                  </MenuTextBold>
                  <MenuTextNormal>{user.email}</MenuTextNormal>
                </MenuItemRow>
                <MenuLine />
              </React.Fragment>
            ))}
          </MenuItemWrapper>
        </MenuInnerWrapper>
      </Menu>
      <Dialog open={!!sendServiceGuideDialog}>
        <DialogWrapper>
          <DialogTitleText>{sendServiceGuideDialog?.cc}</DialogTitleText>
          <DialogTitleText>가격 정책를 보내셨나요?</DialogTitleText>
          <DialogButtonRow>
            <DialogRowButton
              variant="contained"
              onClick={() => setSendServiceGuideDialog(null)}
            >
              아니오
            </DialogRowButton>
            <DialogRowButton
              variant="contained"
              color="primary"
              onClick={() => {
                setSendServiceGuideDialog(null);
                sendServiceGuideDialog?.callback(sendServiceGuideDialog.id);
              }}
            >
              네
            </DialogRowButton>
          </DialogButtonRow>
        </DialogWrapper>
      </Dialog>
      <Dialog open={!!sendPricingDialog}>
        <DialogWrapper>
          <DialogTitleText>{sendPricingDialog?.cc}</DialogTitleText>
          <DialogTitleText>보증금 납부를 확인하셨나요?</DialogTitleText>
          <DialogButtonRow>
            <DialogRowButton
              variant="contained"
              onClick={() => setSendPricingDialog(null)}
            >
              아니오
            </DialogRowButton>
            <DialogRowButton
              variant="contained"
              color="primary"
              onClick={() => {
                setSendPricingDialog(null);
                sendPricingDialog?.callback(sendPricingDialog.id);
              }}
            >
              네
            </DialogRowButton>
          </DialogButtonRow>
        </DialogWrapper>
      </Dialog>
      <Dialog open={!!approveDialog}>
        <DialogWrapper>
          <DialogTitleText>{approveDialog?.cc}</DialogTitleText>
          <DialogTitleText>가입 신청을 승인하시겠습니까?</DialogTitleText>
          <DialogButtonRow>
            <DialogRowButton
              variant="contained"
              onClick={() => setApproveDialog(null)}
            >
              아니오
            </DialogRowButton>
            <DialogRowButton
              variant="contained"
              color="primary"
              onClick={() => {
                setApproveDialog(null);
                approveDialog?.callback(approveDialog.id);
              }}
            >
              네
            </DialogRowButton>
          </DialogButtonRow>
        </DialogWrapper>
      </Dialog>
      <Dialog open={!!rejectDialog}>
        <DialogWrapper>
          <DialogTitleText>{rejectDialog?.cc}</DialogTitleText>
          <DialogTitleText>가입 신청을 미승인합니다.</DialogTitleText>
          <DialogItemRow>
            <DialogItemText>미승인 사유</DialogItemText>
            <Select
              variant="outlined"
              value={rejectReason}
              onChange={handleChangeRejectReasonSelect}
              displayEmpty
              fullWidth
            >
              <MenuItem value="">없음</MenuItem>
              <MenuItem value={CCApplicationRejectReason.BREAKDOWN}>
                협상 결렬
              </MenuItem>
              <MenuItem value={CCApplicationRejectReason.NOT_QUALIFIED}>
                가입 조건 미달
              </MenuItem>
              <MenuItem value={CCApplicationRejectReason.UNAUTHORIZED}>
                확인되지 않는 사업자
              </MenuItem>
              <MenuItem value={CCApplicationRejectReason.NOT_COMPANY_EMAIL}>
                개인 이메일로 신청 불가
              </MenuItem>
              <MenuItem
                value={CCApplicationRejectReason.EXCEEDED_ACCOUNT_LIMIT}
              >
                가입 가능 계정 수 초과
              </MenuItem>
              <MenuItem value={CCApplicationRejectReason.DUPLICATED}>
                이미 승인되어 있는 사용자
              </MenuItem>
            </Select>
            <DialogVerticalButton
              variant="contained"
              color="secondary"
              onClick={() => {
                setRejectDialog(null);
                rejectDialog?.callback(rejectDialog.id, rejectReason);
              }}
            >
              확인
            </DialogVerticalButton>
            <DialogVerticalButton
              variant="text"
              onClick={() => setRejectDialog(null)}
            >
              취소
            </DialogVerticalButton>
          </DialogItemRow>
        </DialogWrapper>
      </Dialog>
      <Dialog open={!!deleteDialog}>
        <DialogWrapper>
          <DialogTitleText>{deleteDialog?.cc}</DialogTitleText>
          <DialogTitleText>신청 내역을 삭제하시겠습니까?</DialogTitleText>
          <DialogButtonRow>
            <DialogRowButton
              variant="contained"
              onClick={() => setDeleteDialog(null)}
            >
              아니오
            </DialogRowButton>
            <DialogRowButton
              variant="contained"
              color="secondary"
              onClick={() => {
                setDeleteDialog(null);
                deleteDialog?.callback(deleteDialog.id);
              }}
            >
              네
            </DialogRowButton>
          </DialogButtonRow>
        </DialogWrapper>
      </Dialog>
    </>
  );
});
