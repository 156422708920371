import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { Recommendation } from '../schemas/Recommendation';

import Format from '../helpers/Format';

const Title = styled.h2`
  margin: 0;
  margin-top: 32px;
  font-size: 16px;
  font-weight: bold;
  color: #222222;
  &:first-of-type {
    margin-top: 0;
  }
`;
const SubTitle = styled.p`
  display: block;
  margin-top: 4px;
  font-size: 14px;
  font-weight: normal;
  color: #999999;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
`;
const Header = styled.h3`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  margin-top: 16px;
  width: 83px;
  font-size: 14px;
  font-weight: bold;
  color: #222222;
`;
const Text = styled.p`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  margin-top: 8px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: #222222;
`;

export default function CounterOfferDetail(props: {
  companyName: string;
  talentName: string;
  recomm: Recommendation;
}): ReactElement {
  return (
    <>
      <Title>제안 기업</Title>
      <Text>{props.companyName}</Text>
      <Title>인재 정보</Title>
      <Text>{props.talentName}</Text>
      {props.recomm.counterOfferDetail && (
        <>
          <Title>변경 요청한 면접 일정</Title>
          <SubTitle>
            {Format.dateFormatWithDay.format(
              props.recomm.counterOfferDetail.date,
            )}{' '}
            {Format.timeFormat.format(props.recomm.counterOfferDetail.date)}
            {' 요청함'}
          </SubTitle>
          {props.recomm.counterOfferDetail.times.length
            ? props.recomm.counterOfferDetail.times.map((time, index) => (
                <Text key={index}>
                  {Format.dateFormatWithDayLong.format(time.date)}{' '}
                  {Format.timeSpanFormat.format(time)}
                </Text>
              ))
            : '없음'}
        </>
      )}
    </>
  );
}
