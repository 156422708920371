import {
  Wrapper,
  Footer,
  Header,
  Content,
  HeaderTitleBigText,
  HeaderTitleSmallText,
  buttonStyles,
  FooterSpacer,
  FooterActionButtonSpacer,
  FooterAgreementWrapper,
  FooterAgreementText,
  FooterActionButtonWrapper,
} from './styles';
import { Item } from './item';

import { Button } from '@material-ui/core';

import React, { ReactElement, useState } from 'react';

import { generateApiUrl } from '../../helpers/Api';
import { VirtualRecommendation } from '../../schemas/VirtualRecommendation';

import IconAgreeOn from 'img/icon-toggle-checkbox-24-black.svg';
import IconAgrreOff from 'img/icon-toggle-checkbox-24.svg';
import { useHistory } from 'react-router-dom';

export function VirtualRecommConfirm(props: {
  id: string;
  company: string;
  token: string;
  isPeriodic: boolean;
  recomms: VirtualRecommendation[];
  onDone(): void;
  onCancel(): void;
}): ReactElement {
  const history = useHistory();

  const buttonClasses = buttonStyles();

  const [agree, setAgree] = useState(false);

  async function generateRecomms() {
    const response = await fetch(
      generateApiUrl(`/admin/ccs/${props.id}/recommendations`),
      {
        method: 'POST',
        headers: {
          'Api-Token': props.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ids: props.recomms.map((recomm) => recomm.id),
          'is-periodic': props.isPeriodic,
        }),
      },
    );

    if (!response.ok) throw response;
  }

  function onClickRecommButton() {
    generateRecomms()
      .then(() => {
        props.onDone();
      })
      .catch((response) => {
        if (response.status === 401) history.push('/login');
        else {
          console.error(response);
          alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
        }
      });
  }

  function onClickCancelButton() {
    props.onCancel();
  }

  return (
    <Wrapper>
      <Header>
        <HeaderTitleSmallText>{props.company}</HeaderTitleSmallText>
        <HeaderTitleBigText>
          {props.isPeriodic ? '정기' : '타겟'}추천 선택 확인 - 총{' '}
          {props.recomms.length}명
        </HeaderTitleBigText>
      </Header>
      <Content>
        {props.recomms.map((recomm) => (
          <Item key={recomm.id} item={recomm} />
        ))}
      </Content>
      <Footer>
        <FooterAgreementWrapper onClick={() => setAgree((agree) => !agree)}>
          <img
            src={agree ? IconAgreeOn : IconAgrreOff}
            width="24"
            height="24"
          />
          <FooterAgreementText>
            선택목록을 최종 확인하였으며 {props.isPeriodic ? '정기' : '타겟'}
            추천에 동의함
          </FooterAgreementText>
        </FooterAgreementWrapper>
        <FooterSpacer />
        <FooterActionButtonWrapper>
          <Button
            className={buttonClasses.cancel}
            variant="contained"
            color="primary"
            onClick={onClickCancelButton}
          >
            이전
          </Button>
          <FooterActionButtonSpacer />
          <Button
            className={buttonClasses.ok}
            variant="contained"
            color="primary"
            onClick={onClickRecommButton}
            disabled={!agree}
          >
            추천하기
          </Button>
        </FooterActionButtonWrapper>
      </Footer>
    </Wrapper>
  );
}
