import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { Recommendation } from '../schemas/Recommendation';

import Format from '../helpers/Format';

const Title = styled.h2`
  margin: 0;
  margin-top: 32px;
  font-size: 16px;
  font-weight: bold;
  color: #222222;
  &:first-of-type {
    margin-top: 0;
  }
`;
const SubTitle = styled.p`
  display: block;
  margin-top: 4px;
  font-size: 14px;
  font-weight: normal;
  color: #999999;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
`;
const Header = styled.h3`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  margin-top: 16px;
  width: 83px;
  font-size: 14px;
  font-weight: bold;
  color: #222222;
`;
const Text = styled.p`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  margin-top: 8px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: #222222;
`;

export default function IntentDetail(props: {
  companyName: string;
  talentName: string;
  recomm: Recommendation;
}): ReactElement {
  return (
    <>
      <Title>제안 기업</Title>
      <Text>{props.companyName}</Text>
      <Title>인재 정보</Title>
      <Row>
        <Header>이름</Header>
        <Text>{props.talentName}</Text>
      </Row>
      {props.recomm.intentDetail && (
        <>
          <Title>제안 정보</Title>
          <SubTitle>
            {Format.dateFormatWithDay.format(props.recomm.intentDetail.date)}{' '}
            {Format.timeFormat.format(props.recomm.intentDetail.date)}
            {' 제안함'}
          </SubTitle>
          <Row>
            <Header>회차</Header>
            <Text>{props.recomm.intentDetail.isSecond ? 2 : 1}회차</Text>
          </Row>
          <Row>
            <Header>상태</Header>
            <Text>
              {props.recomm.intentDetail.state === 'PENDING'
                ? '응답 대기중'
                : props.recomm.intentDetail.response === 'NO_RESPONSE'
                ? '응답하지 않음'
                : '응답함'}
            </Text>
          </Row>
          {props.recomm.intentDetail.state !== 'PENDING' && (
            <Row>
              <Header>응답</Header>
              <Text>
                {props.recomm.intentDetail.response === 'YES'
                  ? '의향 있음'
                  : props.recomm.intentDetail.response === 'LATER'
                  ? '지금은 의향 없음'
                  : props.recomm.intentDetail.response === 'NO'
                  ? '의향 없음'
                  : props.recomm.intentDetail.response === 'NO_RESPONSE'
                  ? '응답하지 않음'
                  : '아직 응답하지 않음'}
                ({props.recomm.intentDetail.response ?? 'PENDING'})
              </Text>
            </Row>
          )}
        </>
      )}
    </>
  );
}
