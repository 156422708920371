export default new (class PageCache {
  private caches = new Map<string, Map<string, unknown>>();

  purge(): void {
    this.caches = new Map<string, Map<string, unknown>>();
  }

  set(page: string, id: string, data: unknown): void {
    let ids = this.caches.get(page);

    if (!ids) {
      ids = new Map<string, unknown>();
      this.caches.set(page, ids);
    }

    ids.set(id, data);
  }

  get(page: string, id: string): unknown | undefined {
    const ids = this.caches.get(page);
    return ids ? ids.get(id) : undefined;
  }
})();
