import {
  ItemLowerText,
  ItemLowerWrapper,
  ItemProfileBigText,
  ItemProfileBigTextSpacer,
  ItemProfileSmallText,
  ItemProfileWrapper,
  ItemThumbnail,
  ItemThumbnailPlaceholder,
  ItemThumbnailSpacer,
  ItemUpperWrapper,
  ItemWrapper,
} from './styles';

import React, { ReactElement } from 'react';

import { VirtualRecommendation } from '../../schemas/VirtualRecommendation';

export function Item(props: { item: VirtualRecommendation }): ReactElement {
  return (
    <ItemWrapper>
      <ItemUpperWrapper>
        <a
          href={`/talents/${props.item.profile.user.id}`}
          target="_blank"
          rel="noreferrer"
        >
          {props.item.profile.thumbnail ? (
            <ItemThumbnail
              src={props.item.profile.thumbnail?.url}
              width="52"
              height="52"
            />
          ) : (
            <ItemThumbnailPlaceholder />
          )}
        </a>
        <ItemThumbnailSpacer />
        <ItemProfileWrapper>
          <div>
            <a
              href={`/talents/${props.item.profile.user.id}`}
              target="_blank"
              rel="noreferrer"
            >
              <ItemProfileBigText>{props.item.profile.name}</ItemProfileBigText>
            </a>
            <ItemProfileBigTextSpacer />
            <ItemProfileBigText>
              {props.item.talentHopePosition} 희망
            </ItemProfileBigText>
          </div>
          <div>
            <ItemProfileSmallText>
              {props.item.profile.mainPosition} {props.item.profile.mainCareer}{' '}
              (총 경력 {props.item.profile.totalCareer})
            </ItemProfileSmallText>
          </div>
        </ItemProfileWrapper>
      </ItemUpperWrapper>
      <ItemLowerWrapper>
        <ItemLowerText>
          {props.item.ccHopePosition} 추천(
          {Math.round(props.item.score * 10) / 10} / 19)
        </ItemLowerText>
      </ItemLowerWrapper>
    </ItemWrapper>
  );
}
