import { styled as mstyled } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

import '../css/filter-button.css';

const ButtonLabel = styled.span`
  line-height: 16px;
`;
const ButtonLowerLabel = styled(ButtonLabel)`
  margin-top: auto;
`;

const StyledToggleButton = mstyled(ToggleButton)({
  padding: '16px',
  borderRadius: 0,
  width: '120px',
  height: '88px',
  fontSize: '14px',
  fontWeight: 'bold',
});

export default function FilterButton(props: {
  key?: string | number | null;
  value: string;
  label: string;
  num: number;
  black?: boolean;
  red?: boolean;
}): ReactElement {
  return (
    <StyledToggleButton
      key={props.key}
      className={classNames({ black: props.black, red: props.red })}
      value={props.value}
    >
      <ButtonLabel>{props.label}</ButtonLabel>
      <ButtonLowerLabel>{props.num}</ButtonLowerLabel>
    </StyledToggleButton>
  );
}
