import {
  makeStyles,
  styled as mstyled,
  Button,
  Paper,
  Tabs,
  Tab,
} from '@material-ui/core';
import { Location } from 'history';
import React, { ReactChild, ReactElement, useEffect, useState } from 'react';
import {
  Link,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  generatePath,
  withRouter,
} from 'react-router-dom';
import styled from 'styled-components';

import ApplicationDashboard from '../fragments/ApplicationDashboard';
import CCDashboard from '../fragments/CCDashboard';
import CCDetail from '../fragments/CCDetail';
import ExtendedSideView from '../fragments/ExtendedSideView';
import FloatView from '../fragments/FloatView';
import SettingDashboard from '../fragments/SettingDashboard';
import SideView from '../fragments/SideView';
import TalentDashboard from '../fragments/TalentDashboard';
import TalentDetail from '../fragments/TalentDetail';

import { joinRoute } from '../helpers/Route';

import '@fortawesome/fontawesome-free/css/all.css';
import '../css/datagrid.css';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  height: 100%;
`;
const TabsOverlayWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const TitleLogoText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #222222;
`;
const TitleText = styled.span`
  margin: 0 24px;
  font-size: 14px;
  font-weight: normal;
  color: #222222;
`;
const Spacer = styled.span`
  margin: 0 auto;
`;
const ContentWrapper = styled.section`
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
`;
const FloatViewWrapper = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 600px;
  height: 560px;
  z-index: 5;
`;
const SideViewWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 6;
`;

const FixedPaper = mstyled(Paper)({
  position: 'relative',
  flexBasis: 'auto',
  flexGrow: 0,
  flexShrink: 0,
  zIndex: 2,
});
const AnchorableTabs = mstyled(Tabs)({
  position: 'relative',
  padding: '0 36px',
});
const AnchorableTab = mstyled(Tab)({
  position: 'relative',
  zIndex: 4,
  width: '110px',
  minWidth: '110px',
});
const TitleButton = mstyled(Button)({
  position: 'relative',
  zIndex: 'auto',
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#222222',
});
const titleLogoButton = makeStyles({
  root: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#222222',
  },
});

export default withRouter(function Dashboard(
  props: RouteComponentProps<{ type: string; id: string }> & {
    email: string;
    token: string;
    clearToken(): void;
  },
): ReactElement {
  const [tabType, setTabType] = useState(
    ['applications', 'ccs', 'talents'].includes(props.match.params.type)
      ? props.match.params.type
      : 'applications',
  );
  const [lastSettingLocation, setLastSettingLocation] = useState<string | null>(
    null,
  );
  const [lastApplicationLocation, setLastApplicationLocation] = useState<
    string | null
  >(null);
  const [lastCCLocation, setLastCCLocation] = useState<string | null>(null);
  const [lastTalentLocation, setLastTalentLocation] = useState<string | null>(
    null,
  );
  const [floatContent, setFloatContent] = useState<{
    title: string;
    content: ReactChild;
  } | null>(null);
  const [sideContent, setSideContent] = useState<
    {
      title: string;
      content: ReactChild;
    }[]
  >([]);
  const [extendedSideContent, setExtendedSideContent] = useState<{
    content: ReactChild;
  } | null>(null);

  const handleClickLogout = (): void => {
    props.clearToken();
    props.history.push('/login');
  };
  const handleChangeTabType = (
    _: React.ChangeEvent<Record<string, unknown>>,
    value: string,
  ): void => {
    if (tabType === value) return;

    switch (value) {
      case 'settings':
        props.history.push(lastSettingLocation ?? '/settings');
        break;
      case 'applications':
        props.history.push(lastApplicationLocation ?? '/applications');
        break;
      case 'ccs':
        props.history.push(lastCCLocation ?? '/ccs');
        break;
      case 'talents':
        props.history.push(lastTalentLocation ?? '/talents');
        break;
    }

    setTabType(value);
  };
  const handleDisplayFloatView = (title: string, content: ReactChild): void => {
    setFloatContent({ title, content });
  };
  const handleCloseFloatView = (): void => {
    setFloatContent(null);
  };
  const handlePushSideView = (title: string, content: ReactChild): void => {
    setSideContent((sideContent) => [...sideContent, { title, content }]);
    setExtendedSideContent(null);
  };
  const handlePopSideView = (): void => {
    setSideContent((sideContent) => {
      sideContent.pop();
      return [...sideContent];
    });
    setExtendedSideContent(null);
  };
  const handleDisplayExtendedSideView = (content: ReactChild): void => {
    setExtendedSideContent({ content });
  };
  const handleCloseExtendedSideView = (): void => {
    setExtendedSideContent(null);
  };
  const handleChangeLocation = (location: Location<unknown>): void => {
    if (location.pathname.startsWith('/settings')) {
      setLastSettingLocation(location.pathname);
      setTabType('settings');
    } else if (location.pathname.startsWith('/applications')) {
      setLastApplicationLocation(location.pathname);
      setTabType('applications');
    } else if (location.pathname.startsWith('/ccs')) {
      setLastCCLocation(location.pathname);
      setTabType('ccs');
    } else if (location.pathname.startsWith('/talents')) {
      setLastTalentLocation(location.pathname);
      setTabType('talents');
    }
  };

  useEffect(() => {
    handleChangeLocation(props.location);
    const unlisten = props.history.listen(handleChangeLocation);
    return () => unlisten();
  }, []);

  return (
    <Wrapper>
      <FixedPaper>
        <AnchorableTabs
          value={tabType}
          onChange={handleChangeTabType}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <AnchorableTab label="설정" value="settings" />
          <AnchorableTab label="가입 신청" value="applications" />
          <AnchorableTab label="기업" value="ccs" />
          <AnchorableTab label="인재" value="talents" />
          <TabsOverlayWrapper>
            <Switch>
              <Route
                path={generatePath(props.match.path, { type: 'settings' })}
              >
                <TitleLogoText>어드민</TitleLogoText>
              </Route>
              <Route
                path={generatePath(props.match.path, { type: 'applications' })}
              >
                <TitleLogoText>어드민</TitleLogoText>
              </Route>
              <Route path={generatePath(props.match.path, { type: 'ccs' })}>
                <Switch>
                  <Route
                    path={joinRoute(
                      generatePath(props.match.path, {
                        type: 'ccs',
                      }),
                      ':id',
                    )}
                  >
                    <Button
                      className={titleLogoButton().root}
                      component={Link}
                      to={generatePath(props.match.path, { type: 'ccs' })}
                    >
                      <i className="fas fa-chevron-left" />
                      <span className="fa-space">기업 목록</span>
                    </Button>
                  </Route>
                  <Route>
                    <TitleLogoText>어드민</TitleLogoText>
                  </Route>
                </Switch>
              </Route>
              <Route path={generatePath(props.match.path, { type: 'talents' })}>
                <Switch>
                  <Route
                    path={joinRoute(
                      generatePath(props.match.path, {
                        type: 'talents',
                      }),
                      ':id',
                    )}
                  >
                    <Button
                      className={titleLogoButton().root}
                      component={Link}
                      to={generatePath(props.match.path, { type: 'talents' })}
                    >
                      <i className="fas fa-chevron-left" />
                      <span className="fa-space">인재 목록</span>
                    </Button>
                  </Route>
                  <Route>
                    <TitleLogoText>어드민</TitleLogoText>
                  </Route>
                </Switch>
              </Route>
              <Redirect
                to={generatePath(props.match.path, { type: 'applications' })}
              />
            </Switch>
            <Spacer />
            <TitleText>{props.email}</TitleText>
            <TitleButton onClick={handleClickLogout}>로그아웃</TitleButton>
          </TabsOverlayWrapper>
        </AnchorableTabs>
      </FixedPaper>
      <ContentWrapper>
        <Switch>
          <Route
            render={(innerProps) => (
              <SettingDashboard {...innerProps} token={props.token} />
            )}
            path={joinRoute(
              generatePath(props.match.path, {
                type: 'settings',
              }),
              ':settingType(coding-tests|notifications)?',
            )}
          />
          <Route
            render={(innerProps) => (
              <ApplicationDashboard {...innerProps} token={props.token} />
            )}
            path={joinRoute(
              generatePath(props.match.path, {
                type: 'applications',
              }),
              ':applicationType(ccs|talents)?',
            )}
          />
          <Route path={generatePath(props.match.path, { type: 'ccs' })}>
            <Switch>
              <Route
                render={(innerProps) => (
                  <CCDetail
                    {...innerProps}
                    token={props.token}
                    onDisplayFloatView={handleDisplayFloatView}
                    onDisplaySideView={handlePushSideView}
                    onDisplayExtendedSideView={handleDisplayExtendedSideView}
                    onCloseExtendedSideView={handleCloseExtendedSideView}
                  />
                )}
                path={joinRoute(
                  generatePath(props.match.path, {
                    type: 'ccs',
                  }),
                  ':id',
                )}
              />
              <Route
                render={(innerProps) => (
                  <CCDashboard {...innerProps} token={props.token} />
                )}
              />
            </Switch>
          </Route>
          <Route path={generatePath(props.match.path, { type: 'talents' })}>
            <Switch>
              <Route
                render={(innerProps) => (
                  <TalentDetail
                    {...innerProps}
                    token={props.token}
                    onDisplayFloatView={handleDisplayFloatView}
                    onDisplaySideView={handlePushSideView}
                    onDisplayExtendedSideView={handleDisplayExtendedSideView}
                    onCloseExtendedSideView={handleCloseExtendedSideView}
                  />
                )}
                path={joinRoute(
                  generatePath(props.match.path, {
                    type: 'talents',
                  }),
                  ':id',
                )}
              />
              <Route
                render={(innerProps) => (
                  <TalentDashboard {...innerProps} token={props.token} />
                )}
              />
            </Switch>
          </Route>
        </Switch>
        {floatContent && (
          <FloatViewWrapper>
            <FloatView
              title={floatContent.title}
              onClose={handleCloseFloatView}
            >
              {floatContent.content}
            </FloatView>
          </FloatViewWrapper>
        )}
        {sideContent.length ? (
          <SideViewWrapper>
            <SideView
              title={sideContent[sideContent.length - 1].title}
              hasPrevious={2 <= sideContent.length}
              onClose={handlePopSideView}
            >
              {sideContent[sideContent.length - 1].content}
            </SideView>
            {extendedSideContent && (
              <ExtendedSideView>{extendedSideContent.content}</ExtendedSideView>
            )}
          </SideViewWrapper>
        ) : (
          <></>
        )}
      </ContentWrapper>
    </Wrapper>
  );
});
