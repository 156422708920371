import {
  makeStyles,
  styled as mstyled,
  Backdrop,
  Button,
  CircularProgress,
  MuiThemeProvider,
  createMuiTheme,
  useMediaQuery,
} from '@material-ui/core';
import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import DataGrid, { Column } from 'react-data-grid';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import throttle from 'lodash/throttle';
import styled from 'styled-components';

import { useSort } from '../features/Sort';

import TalentColumns, {
  TalentCCRowModel,
  TalentRowModel,
} from '../schemas/Talent';

import { searchClient } from '../search';

import {
  fetchTalentProfile,
  fetchTalentRecommendations,
  fetchTalents,
  queryTalents,
  TARGET_SERVER_SEARCH_INDEX_PREFIX,
} from '../helpers/Api';

import '@fortawesome/fontawesome-free/css/all.css';
import '../css/datagrid.css';
import Format from '../helpers/Format';

const Wrapper = styled.div`
  padding: 64px 36px 36px;
`;
const SearchBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const SearchBarInput = styled.input`
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  border: 0;
  border-radius: 20px;
  padding: 0 16px;
  height: 40px;
  background-color: #eeeeee;
  width: 100%;
  min-width: 150px;
  max-width: 330px;
`;
const Spacer = styled.span`
  margin: 0 auto;
`;
const TitleText = styled.h1`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #222222;
`;
const DataGridWrapper = styled.div`
  margin-top: 24px;
  height: 1286px;
`;

const BackgroundCover = mstyled(Backdrop)({
  color: 'black',
  zIndex: 1,
});
const MenuButton = mstyled(Button)({
  marginLeft: '16px',
  padding: '5px 8px',
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#222222',
});
const dataGridLinkCellStyle = makeStyles({
  root: {
    fontSize: '14px',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
});

export default withRouter(function TalentDashboard(
  props: RouteComponentProps & {
    token: string;
  },
): ReactElement {
  const [loading, setLoading] = useState(false);
  const [oldRows, setOldRows] = useState<TalentRowModel[]>([]);
  const [rows, setRows] = useState<TalentRowModel[]>([]);
  const [sort, setSort, sortedRows] = useSort(
    rows,
    JSON.parse(localStorage.getItem(`${props.match.url}@sort`) || '[]'),
  );
  const [onExport, setOnExport] = useState<(() => void) | null>(null);

  const updateData = async (purgeCache?: boolean) => {
    try {
      const talents = await fetchTalents(
        props,
        setLoading,
        purgeCache ?? false,
      );
      setOldRows(talents);
      setRows(talents);
    } catch (response) {
      if (response.status === 401) props.history.push('/login');
      else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
    }
  };

  useEffect(() => {
    updateData();
  }, []);
  useEffect(() => {
    setOnExport(() => async () => {
      function startToDate(
        time: { date: Date; startTime: number } | undefined,
      ): Date | undefined {
        if (!time) return undefined;
        return new Date(time.date.getTime() + time.startTime * 60 * 1000);
      }
      function endToDate(
        time: { date: Date; endTime: number } | undefined,
      ): Date | undefined {
        if (!time) return undefined;
        return new Date(time.date.getTime() + time.endTime * 60 * 1000);
      }

      const profiles = await Promise.all(
        sortedRows.map((row) =>
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          fetchTalentProfile(props, String(row.id), () => {}, true),
        ),
      );
      const recomms = await Promise.all<[string, TalentCCRowModel[]]>(
        sortedRows.map(async (row) => [
          row.name,
          await fetchTalentRecommendations(
            props,
            String(row.id),
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {},
            true,
          ),
        ]),
      );

      const xlsx = await import('xlsx');
      const wb = xlsx.utils.book_new();
      const ws = xlsx.utils.aoa_to_sheet([
        [
          '번호',
          '가입일',
          '이름',
          '멤버십',
          '이메일',
          '최종 프로필 변경일',
          '프로필 생성 여부',
          '추천 활성화 여부',
          '추천 승인 여부',
          '마케팅 수신 동의 여부',
          '희망 취업 직무',
          '기업 추천',
          '면접 제안',
          '일정 변경 요청',
          '면접 예정',
          '면접 완료',
          '최종 합격',
          '불합격',
          '종료됨',
          '생년월일',
          '휴대폰 번호',
          '거주지 위치',
          '주요직무',
          '주요직무 연차',
          '총 경력',
          '포트폴리오 링크',
          '희망 연봉',
          '희망 근무 지속일',
          '보유기술 1 이름',
          '보유기술 1 숙련도',
          '보유기술 2 이름',
          '보유기술 2 숙련도',
          '보유기술 3 이름',
          '보유기술 3 숙련도',
          '보유기술 4 이름',
          '보유기술 4 숙련도',
          '보유기술 5 이름',
          '보유기술 5 숙련도',
          '보유기술 6 이름',
          '보유기술 6 숙련도',
          '보유기술 7 이름',
          '보유기술 7 숙련도',
          '보유기술 8 이름',
          '보유기술 8 숙련도',
          '보유기술 9 이름',
          '보유기술 9 숙련도',
          '보유기술 10 이름',
          '보유기술 10 숙련도',
          '학력 1 기관명',
          '학력 1 전공',
          '학력 1 학위',
          '학력 1 시작일',
          '학력 1 종료일',
          '학력 2 기관명',
          '학력 2 전공',
          '학력 2 학위',
          '학력 2 시작일',
          '학력 2 종료일',
          '학력 3 기관명',
          '학력 3 전공',
          '학력 3 학위',
          '학력 3 시작일',
          '학력 3 종료일',
          '경력 1 회사명',
          '경력 1 직무',
          '경력 1 시작일',
          '경력 1 종료일',
          '경력 1 설명',
          '경력 2 회사명',
          '경력 2 직무',
          '경력 2 시작일',
          '경력 2 종료일',
          '경력 2 설명',
          '경력 3 회사명',
          '경력 3 직무',
          '경력 3 시작일',
          '경력 3 종료일',
          '경력 3 설명',
          '프로젝트 1 이름',
          '프로젝트 1 기관명',
          '프로젝트 1 팀명',
          '프로젝트 1 직무',
          '프로젝트 1 시작일',
          '프로젝트 1 종료일',
          '프로젝트 1 설명',
          '프로젝트 1 링크',
          '프로젝트 2 이름',
          '프로젝트 2 기관명',
          '프로젝트 2 팀명',
          '프로젝트 2 직무',
          '프로젝트 2 시작일',
          '프로젝트 2 종료일',
          '프로젝트 2 설명',
          '프로젝트 2 링크',
          '프로젝트 3 이름',
          '프로젝트 3 기관명',
          '프로젝트 3 팀명',
          '프로젝트 3 직무',
          '프로젝트 3 시작일',
          '프로젝트 3 종료일',
          '프로젝트 3 설명',
          '프로젝트 3 링크',
        ],
        ...sortedRows.map((row, index) => [
          row.id,
          row.joinedAt,
          row.name,
          row.membership,
          row.email,
          row.lastProfileUpdatedAt,
          0 < row.numOfHardSkills &&
          0 < row.numOfCareers + row.numOfProjects &&
          0 < row.numOfEducations
            ? 'YES'
            : 'NO',
          row.recommendationEnabled ? 'YES' : 'NO',
          row.allowedForRecommendation ? 'YES' : 'NO',
          row.notifyMarketingRelated ? 'YES' : 'NO',
          row.positions[0] ?? '',
          row.numOfRecomm,
          row.numOfOffer,
          row.numOfCounterOffer,
          row.numOfInterview,
          row.numOfDoneInterview,
          row.numOfPass,
          row.numOfFail,
          row.numOfTerm,
          profiles[index].birth ?? '',
          profiles[index].phone ?? '',
          profiles[index].address ?? '',
          profiles[index].mainPosition ?? '',
          profiles[index].mainCareer ?? '',
          profiles[index].totalCareer ?? '',
          profiles[index].portfolioUrl ?? '',
          profiles[index].hopeSalary ?? '',
          profiles[index].hopeServiceDuration ?? '',
          profiles[index].skills[0]?.skill ?? '',
          profiles[index].skills[0]?.score ?? '',
          profiles[index].skills[1]?.skill ?? '',
          profiles[index].skills[1]?.score ?? '',
          profiles[index].skills[2]?.skill ?? '',
          profiles[index].skills[2]?.score ?? '',
          profiles[index].skills[3]?.skill ?? '',
          profiles[index].skills[3]?.score ?? '',
          profiles[index].skills[4]?.skill ?? '',
          profiles[index].skills[4]?.score ?? '',
          profiles[index].skills[5]?.skill ?? '',
          profiles[index].skills[5]?.score ?? '',
          profiles[index].skills[6]?.skill ?? '',
          profiles[index].skills[6]?.score ?? '',
          profiles[index].skills[7]?.skill ?? '',
          profiles[index].skills[7]?.score ?? '',
          profiles[index].skills[8]?.skill ?? '',
          profiles[index].skills[8]?.score ?? '',
          profiles[index].skills[9]?.skill ?? '',
          profiles[index].skills[9]?.score ?? '',
          profiles[index].educations[0]?.name ?? '',
          profiles[index].educations[0]?.major ?? '',
          profiles[index].educations[0]?.degree ?? '',
          profiles[index].educations[0]?.beginAt ?? '',
          profiles[index].educations[0]?.endAt ?? '',
          profiles[index].educations[1]?.name ?? '',
          profiles[index].educations[1]?.major ?? '',
          profiles[index].educations[1]?.degree ?? '',
          profiles[index].educations[1]?.beginAt ?? '',
          profiles[index].educations[1]?.endAt ?? '',
          profiles[index].educations[2]?.name ?? '',
          profiles[index].educations[2]?.major ?? '',
          profiles[index].educations[2]?.degree ?? '',
          profiles[index].educations[2]?.beginAt ?? '',
          profiles[index].educations[2]?.endAt ?? '',
          profiles[index].careers[0]?.name ?? '',
          profiles[index].careers[0]?.position ?? '',
          profiles[index].careers[0]?.beginAt ?? '',
          profiles[index].careers[0]?.endAt ?? '',
          profiles[index].careers[0]?.description ?? '',
          profiles[index].careers[1]?.name ?? '',
          profiles[index].careers[1]?.position ?? '',
          profiles[index].careers[1]?.beginAt ?? '',
          profiles[index].careers[1]?.endAt ?? '',
          profiles[index].careers[1]?.description ?? '',
          profiles[index].careers[2]?.name ?? '',
          profiles[index].careers[2]?.position ?? '',
          profiles[index].careers[2]?.beginAt ?? '',
          profiles[index].careers[2]?.endAt ?? '',
          profiles[index].careers[2]?.description ?? '',
          profiles[index].projects[0]?.name ?? '',
          profiles[index].projects[0]?.organization ?? '',
          profiles[index].projects[0]?.team ?? '',
          profiles[index].projects[0]?.position ?? '',
          profiles[index].projects[0]?.beginAt ?? '',
          profiles[index].projects[0]?.endAt ?? '',
          profiles[index].projects[0]?.description ?? '',
          profiles[index].projects[0]?.url ?? '',
          profiles[index].projects[1]?.name ?? '',
          profiles[index].projects[1]?.organization ?? '',
          profiles[index].projects[1]?.team ?? '',
          profiles[index].projects[1]?.position ?? '',
          profiles[index].projects[1]?.beginAt ?? '',
          profiles[index].projects[1]?.endAt ?? '',
          profiles[index].projects[1]?.description ?? '',
          profiles[index].projects[1]?.url ?? '',
          profiles[index].projects[2]?.name ?? '',
          profiles[index].projects[2]?.organization ?? '',
          profiles[index].projects[2]?.team ?? '',
          profiles[index].projects[2]?.position ?? '',
          profiles[index].projects[2]?.beginAt ?? '',
          profiles[index].projects[2]?.endAt ?? '',
          profiles[index].projects[2]?.description ?? '',
          profiles[index].projects[2]?.url ?? '',
        ]),
      ]);
      const ws2 = xlsx.utils.aoa_to_sheet([
        [
          '번호',
          '이름',
          '기업명',
          '추천 직무',
          '매칭 점수',
          '추천 날짜',
          '프로핑 평가: 첫인상 호감도',
          '프로핑 평가: 기술과 경력 매력도',
          '프로핑 평가: 인재 추천 만족도',
          '면접 제안: 제안일',
          '면접 제안: 면접 형태',
          '면접 제안: 제안 직무',
          '면접 제안: 상세 업무',
          '면접 제안: 면접 장소(대면 면접인 경우)',
          '면접 제안: 화상 면접 링크(비대면 면접인 경우)',
          '면접 제안: 준비 사항',
          '면접 제안: 일정 0 시작',
          '면접 제안: 일정 0 끝',
          '면접 제안: 일정 1 시작',
          '면접 제안: 일정 1 끝',
          '면접 제안: 일정 2 시작',
          '면접 제안: 일정 2 끝',
          '면접 제안: 일정 3 시작',
          '면접 제안: 일정 3 끝',
          '면접 제안: 일정 4 시작',
          '면접 제안: 일정 4 끝',
          '면접 제안: 일정 5 시작',
          '면접 제안: 일정 5 끝',
          '면접 제안: 일정 6 시작',
          '면접 제안: 일정 6 끝',
          '면접 제안: 일정 7 시작',
          '면접 제안: 일정 7 끝',
          '면접 제안: 일정 8 시작',
          '면접 제안: 일정 8 끝',
          '면접 제안: 일정 9 시작',
          '면접 제안: 일정 9 끝',
          '일정 변경 요청: 요청일',
          '일정 변경 요청: 일정 0 시작',
          '일정 변경 요청: 일정 0 끝',
          '일정 변경 요청: 일정 1 시작',
          '일정 변경 요청: 일정 1 끝',
          '일정 변경 요청: 일정 2 시작',
          '일정 변경 요청: 일정 2 끝',
          '일정 변경 요청: 일정 3 시작',
          '일정 변경 요청: 일정 3 끝',
          '일정 변경 요청: 일정 4 시작',
          '일정 변경 요청: 일정 4 끝',
          '일정 변경 요청: 일정 5 시작',
          '일정 변경 요청: 일정 5 끝',
          '일정 변경 요청: 일정 6 시작',
          '일정 변경 요청: 일정 6 끝',
          '일정 변경 요청: 일정 7 시작',
          '일정 변경 요청: 일정 7 끝',
          '일정 변경 요청: 일정 8 시작',
          '일정 변경 요청: 일정 8 끝',
          '일정 변경 요청: 일정 9 시작',
          '일정 변경 요청: 일정 9 끝',
          '면접 예정 시작일',
          '면접 예정 종료일',
          '면접 후기: 작성일',
          '면접 후기: 별점',
          '면접 후기: 면접이 예정된 시간에 시작됐나요?',
          '면접 후기: 면접관이 면접 분위기를 잘 조성해주었나요?',
          '면접 후기: 면접관이 직무 및 수행업무에 대해서 알기쉽게 설명해주었나요?',
          '면접 후기: 그 외',
          '면접 결과',
          '면접 결과 통보 일자',
          '상태',
        ],
        ...recomms
          .map(([name, recomms]) =>
            recomms.map<[string, TalentCCRowModel]>((recomm) => [name, recomm]),
          )
          .reduce((prev, recomms) => [...prev, ...recomms], [])
          .map((recomm, index) => [
            index + 1,
            recomm[0],
            recomm[1].name,
            recomm[1].recommDetail.positionDetail.position,
            recomm[1].recommDetail.positionDetail.score,
            recomm[1].recommDetail.date,
            recomm[1].recommDetail.profileDetail?.firstImpression ?? '',
            recomm[1].recommDetail.profileDetail?.skillSetSuitability ?? '',
            recomm[1].recommDetail.profileDetail?.talentSimilarity ?? '',
            recomm[1].recommDetail.offerDetail?.date ?? '',
            recomm[1].recommDetail.offerDetail?.onlineDetail
              ? '비대면 면접'
              : recomm[1].recommDetail.offerDetail?.onlineDetail
              ? '대면 면접'
              : '',
            recomm[1].recommDetail.offerDetail?.position ?? '',
            recomm[1].recommDetail.offerDetail?.duty ?? '',
            recomm[1].recommDetail.offerDetail?.offlineDetail?.address ?? '',
            recomm[1].recommDetail.offerDetail?.onlineDetail?.url ?? '',
            recomm[1].recommDetail.offerDetail?.preparation,
            startToDate(recomm[1].recommDetail.offerDetail?.times[0]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[0]) ?? '',
            startToDate(recomm[1].recommDetail.offerDetail?.times[1]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[1]) ?? '',
            startToDate(recomm[1].recommDetail.offerDetail?.times[2]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[2]) ?? '',
            startToDate(recomm[1].recommDetail.offerDetail?.times[3]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[3]) ?? '',
            startToDate(recomm[1].recommDetail.offerDetail?.times[4]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[4]) ?? '',
            startToDate(recomm[1].recommDetail.offerDetail?.times[5]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[5]) ?? '',
            startToDate(recomm[1].recommDetail.offerDetail?.times[6]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[6]) ?? '',
            startToDate(recomm[1].recommDetail.offerDetail?.times[7]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[7]) ?? '',
            startToDate(recomm[1].recommDetail.offerDetail?.times[8]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[8]) ?? '',
            startToDate(recomm[1].recommDetail.offerDetail?.times[9]) ?? '',
            endToDate(recomm[1].recommDetail.offerDetail?.times[9]) ?? '',
            recomm[1].recommDetail.counterOfferDetail?.date ?? '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[0]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[0]) ??
              '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[1]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[1]) ??
              '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[2]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[2]) ??
              '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[3]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[3]) ??
              '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[4]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[4]) ??
              '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[5]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[5]) ??
              '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[6]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[6]) ??
              '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[7]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[7]) ??
              '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[8]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[8]) ??
              '',
            startToDate(recomm[1].recommDetail.counterOfferDetail?.times[9]) ??
              '',
            endToDate(recomm[1].recommDetail.counterOfferDetail?.times[9]) ??
              '',
            startToDate(recomm[1].recommDetail.interviewDetail) ?? '',
            endToDate(recomm[1].recommDetail.interviewDetail) ?? '',
            recomm[1].recommDetail.interviewReviewDetail?.date ?? '',
            recomm[1].recommDetail.interviewReviewDetail?.score ?? '',
            recomm[1].recommDetail.interviewReviewDetail?.review.ccTime
              ? 'YES'
              : recomm[1].recommDetail.interviewReviewDetail?.review.ccTime ===
                false
              ? 'NO'
              : '',
            recomm[1].recommDetail.interviewReviewDetail?.review.ccAmbience
              ? 'YES'
              : recomm[1].recommDetail.interviewReviewDetail?.review
                  .ccAmbience === false
              ? 'NO'
              : '',
            recomm[1].recommDetail.interviewReviewDetail?.review.ccClear
              ? 'YES'
              : recomm[1].recommDetail.interviewReviewDetail?.review.ccClear ===
                false
              ? 'NO'
              : '',
            recomm[1].recommDetail.interviewReviewDetail?.comment ?? '',
            recomm[1].recommDetail.resultDetail?.passed
              ? '합격'
              : recomm[1].recommDetail.resultDetail?.passed === false
              ? '불합격'
              : '',
            recomm[1].recommDetail.resultDetail?.date ?? '',
            recomm[1].recommStage,
          ]),
      ]);
      xlsx.utils.book_append_sheet(wb, ws, 'talent');
      xlsx.utils.book_append_sheet(wb, ws2, 'talent recomm stats');
      xlsx.writeFile(wb, 'talent.xlsx');
    });
  }, [sortedRows]);

  const applySearchQuery = useCallback(
    throttle(
      (event: ChangeEvent<HTMLInputElement>) => {
        const query = (event.target.value ?? '').trim();

        if (!query) {
          setRows(oldRows);
          return;
        }

        (async () => {
          const result = await searchClient.search([
            {
              facet: '',
              indexName: `${TARGET_SERVER_SEARCH_INDEX_PREFIX}_talent_user`,
              query,
            },
          ]);
          const maxPage = result.results[0]?.nbPages ?? 1;
          const pages: number[] = [];

          for (let page = 1; page < maxPage; ++page) pages.push(page);

          const extraResults = await Promise.all(
            pages.map((page) =>
              searchClient.search([
                {
                  facet: '',
                  indexName: `${TARGET_SERVER_SEARCH_INDEX_PREFIX}_talent_user`,
                  query,
                  params: {
                    page,
                  },
                },
              ]),
            ),
          );

          setRows(
            await queryTalents(
              props,
              [
                ...result.results,
                ...extraResults.flatMap((result) => result.results),
              ]
                .flatMap((result) => result.hits ?? [])
                .map((hit) => Number(hit.objectID)),
            ),
          );
        })().catch(console.error);
      },
      1000,
      {
        leading: false,
        trailing: true,
      },
    ),
    [oldRows],
  );

  const dataGridLinkCellTheme = createMuiTheme({
    palette: {
      primary: {
        main: useMediaQuery('(prefers-color-scheme: dark)')
          ? '#ffffff'
          : '#000000',
      },
    },
  });

  const columns: Column<TalentRowModel>[] = TalentColumns.columns.filter(
    (column) => column.display,
  );

  columns[1].formatter = function JoinedAtCell(props) {
    return <>{Format.dateFormat.format(props.row.joinedAt)}</>;
  };
  columns[2].formatter = function TalentNameCell(props): ReactElement {
    return (
      <MuiThemeProvider theme={dataGridLinkCellTheme}>
        <Button
          variant="text"
          color="primary"
          className={dataGridLinkCellStyle().root}
          component={Link}
          to={`/talents/${rows[(props.row as TalentRowModel).index].id}`}
        >
          {props.row.name}
        </Button>
      </MuiThemeProvider>
    );
  };
  columns[6].formatter = function ProfileUpdatedAtCell(props): ReactElement {
    return (
      <>{Format.dateFormatWithDay.format(props.row.lastProfileUpdatedAt)}</>
    );
  };
  columns[7].formatter = function HasProfileCell(props): ReactElement {
    return (
      <>
        {0 < props.row.numOfHardSkills &&
        0 < props.row.numOfCareers + props.row.numOfProjects &&
        0 < props.row.numOfEducations
          ? 'O'
          : 'X'}
      </>
    );
  };
  columns[8].formatter = function RecommendationEnabledCell(
    props,
  ): ReactElement {
    return <>{props.row.recommendationEnabled ? 'O' : 'X'}</>;
  };
  columns[9].formatter = function AllowedForRecommendationCell(
    props,
  ): ReactElement {
    return <>{props.row.allowedForRecommendation ? 'O' : 'X'}</>;
  };
  columns[11].formatter = function NumOfRecommCell(props): ReactElement {
    return <>{props.row.numOfRecomm + props.row.numOfProfileEval}</>;
  };

  return (
    <>
      <Wrapper>
        <SearchBarWrapper>
          <TitleText>인재 목록</TitleText>
          <Spacer />
          <SearchBarInput
            type="text"
            placeholder="인재 검색"
            onChange={applySearchQuery}
          />
          <MenuButton
            variant="text"
            onClick={() => {
              if (onExport) onExport();
            }}
          >
            <i className="fas fa-download" />
            <span className="fa-space">내보내기</span>
          </MenuButton>
          <MenuButton variant="text" onClick={() => updateData(true)}>
            <i className="fas fa-sync-alt" />
            <span className="fa-space">새로고침</span>
          </MenuButton>
        </SearchBarWrapper>
        <DataGridWrapper>
          <DataGrid
            style={{ height: '100%' }}
            rows={sortedRows}
            columns={columns}
            sorts={sort}
            onSort={(sorts) => {
              setSort(sorts);
              localStorage.setItem(
                `${props.match.url}@sort`,
                JSON.stringify(sorts),
              );
            }}
          />
        </DataGridWrapper>
      </Wrapper>
      <BackgroundCover open={loading}>
        <CircularProgress />
      </BackgroundCover>
    </>
  );
});
