import {
  styled as mstyled,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  MenuItem,
  Select,
  ThemeProvider,
  Tooltip,
  Link,
} from '@material-ui/core';
import { ToggleButtonGroup } from '@material-ui/lab';
import React, {
  ReactElement,
  useEffect,
  useState,
  useCallback,
  ChangeEvent,
} from 'react';
import DataGrid, { Column } from 'react-data-grid';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import throttle from 'lodash/throttle';
import styled from 'styled-components';

import { useSort } from '../features/Sort';

import FilterButton from '../components/FilterButton';

import approveButtonTheme from '../themes/ApproveButton';
import rejectButtonTheme from '../themes/RejectButton';
import testButtonTheme from '../themes/TestButton';
import testRepeatButtonTheme from '../themes/TestRepeatButton';

import Format from '../helpers/Format';

import { searchClient } from '../search';

import {
  fetchTalentApplications,
  // fetchTests,
  testTalentApplication,
  approveTalentApplication,
  rejectTalentApplication,
  deleteTalentApplication,
  queryTalentApplications,
  TARGET_SERVER_SEARCH_INDEX_PREFIX,
} from '../helpers/Api';

import ApplicationScheme, {
  TalentApplicationRejectReason,
  TalentApplicationRowModel,
  TalentApplicationState,
} from '../schemas/Application';

import '@fortawesome/fontawesome-free/css/all.css';
import '../css/datagrid.css';

const FilterBarWrapper = styled.div`
  overflow-x: scroll;
  white-space: nowrap;
`;
const FilterBarInnerWrapper = styled.div`
  display: inline-block;
  & + & {
    margin-left: 24px;
  }
`;
const SearchBarInput = styled.input`
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  margin-top: 35px;
  border: 0;
  border-radius: 20px;
  padding: 0 16px;
  height: 40px;
  background-color: #eeeeee;
  width: 100%;
  min-width: 150px;
  max-width: 330px;
`;
const DataGridWrapper = styled.div`
  margin-top: 35px;
  height: 1286px;
`;
const ScorePass = styled.span`
  color: #20d23d;
  font-size: 14px;
  font-weight: bold;
`;
const ScoreNonPass = styled.span`
  color: #ff5c5c;
  font-size: 14px;
  font-weight: bold;
`;
const ScoreReview = styled.span`
  color: #ffcf17;
  font-size: 14px;
  font-weight: bold;
`;
const StateText = styled.span`
  font-size: 14px;
  font-weight: normal;
`;
const StateRejectedText = styled.span`
  font-size: 14px;
  font-weight: normal;
  color: #ff5c5c;
`;
const PendingIcon = styled.span`
  display: inline-block;
  margin-right: 4px;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  color: white;
  background-color: #999999;
  text-align: center;
  user-select: none;
`;
const ApproveIcon = styled.span`
  display: inline-block;
  margin-right: 4px;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  color: white;
  background-color: #0c46f2;
  text-align: center;
  user-select: none;
`;
const TestingIcon = styled.span`
  display: inline-block;
  margin-right: 4px;
  border: solid 1px #dddddd;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  color: #999999;
  background-color: white;
  text-align: center;
  user-select: none;
`;
const TestedIcon = styled.span`
  display: inline-block;
  margin-right: 4px;
  border: solid 1px #dddddd;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  color: #39e956;
  background-color: white;
  text-align: center;
  user-select: none;
`;
const RejectIcon = styled.span`
  display: inline-block;
  margin-right: 4px;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  color: white;
  background-color: #ff5c5c;
  text-align: center;
  user-select: none;
`;
const ReviewIcon = styled.span`
  display: inline-block;
  margin-right: 4px;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  color: white;
  background-color: #ffcf17;
  text-align: center;
  user-select: none;
`;
const DialogWrapper = styled.div`
  padding: 32px 20px 20px;
`;
const DialogTitleText = styled.p`
  font-size: 18px;
  font-weight: normal;
  color: #222222;
  text-align: center;
`;
const DialogButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;
const DialogItemRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 32px;
  & > * + * {
    margin-top: 12px;
  }
`;
const DialogItemText = styled.p`
  align-self: flex-start;
  font-size: 16px;
  font-weight: bold;
  color: #222222;
`;

const BackgroundCover = mstyled(Backdrop)({
  color: 'black',
  zIndex: 1,
});
const TestButton = mstyled(Button)({
  fontSize: '12px',
  fontWeight: 'normal',
});
const TestRepeatButton = mstyled(Button)({
  fontSize: '12px',
  fontWeight: 'normal',
});
const ApproveButton = mstyled(Button)({
  fontSize: '12px',
  fontWeight: 'normal',
  marginLeft: '4px',
});
const RejectButton = mstyled(Button)({
  fontSize: '12px',
  fontWeight: 'normal',
  marginLeft: '4px',
});
const DeleteButton = mstyled(Button)({
  fontSize: '20px',
  fontWeight: 'normal',
  color: '#666666',
  marginLeft: '4px',
});
const DialogRowButton = mstyled(Button)({
  width: '142px',
  height: '56px',
  fontSize: '16px',
  fontWeight: 'normal',
  boxShadow: 'none',
  '&+&': {
    marginLeft: '12px',
  },
  '&:hover, &:active': {
    boxShadow: 'none',
  },
});
const DialogVerticalButton = mstyled(Button)({
  width: '296px',
  height: '56px',
  fontSize: '16px',
  fontWeight: 'normal',
  boxShadow: 'none',
  '&:hover, &:active': {
    boxShadow: 'none',
  },
});

const filterCount =
  ApplicationScheme.blueTalentFilters.length +
  ApplicationScheme.redTalentFilters.length;

export default withRouter(function TalentApplication(
  props: RouteComponentProps & {
    token: string;
    registerOnExport(listener: () => void): void;
    registerOnRefresh(listener: () => void): void;
  },
): ReactElement {
  const [loading, setLoading] = useState(0);
  const [tests, setTests] = useState<null | {
    saved: {
      id: number;
      testId: number;
      name: string;
      cutoffScore: number;
      maxScore: number;
      questions: number;
      activated: boolean;
    }[];
  }>(null);
  const [oldRows, setOldRows] = useState<TalentApplicationRowModel[]>([]);
  const [rows, setRows] = useState<TalentApplicationRowModel[]>([]);
  const [filter, setFilter] = useState<string[]>(() => ['all']);
  const [filteredRows, setFilteredRows] = useState<TalentApplicationRowModel[]>(
    [],
  );
  const [sort, setSort, sortedRows] = useSort(
    filteredRows,
    JSON.parse(localStorage.getItem(`${props.match.url}@sort`) || '[]'),
  );
  const [testDialog, setTestDialog] = useState<null | {
    id: number;
    talent: string;
    callback(id: number, testId: number, cutoffScore: number): void;
  }>(null);
  const [testIndex, setTestIndex] = useState<'' | number>('');
  const [approveDialog, setApproveDialog] = useState<null | {
    id: number;
    talent: string;
    callback(id: number): void;
  }>(null);
  const [rejectDialog, setRejectDialog] = useState<null | {
    id: number;
    talent: string;
    state: TalentApplicationState;
    callback(
      id: number,
      rejectReason: '' | TalentApplicationRejectReason,
    ): void;
  }>(null);
  const [rejectReason, setRejectReason] = useState<
    '' | TalentApplicationRejectReason
  >('');
  const [deleteDialog, setDeleteDialog] = useState<null | {
    id: number;
    talent: string;
    callback(id: number): void;
  }>(null);

  const testButtonCallback = (
    id: number,
    testId: number,
    cutoffScore: number,
  ) => {
    (async () => {
      await testTalentApplication(props, id, testId, cutoffScore, (loading) =>
        setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
      );
      await updateData(true);
    })().catch((response) => {
      if (response.status === 401) props.history.push('/login');
      else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
    });
  };
  const approveButtonCallback = (id: number) => {
    (async () => {
      await approveTalentApplication(props, id, (loading) =>
        setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
      );
      await updateData(true);
    })().catch((response) => {
      if (response.status === 401) props.history.push('/login');
      else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
    });
  };
  const rejectButtonCallback = (
    id: number,
    rejectReason: '' | TalentApplicationRejectReason,
  ) => {
    (async () => {
      await rejectTalentApplication(
        props,
        id,
        rejectReason || null,
        (loading) =>
          setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
      );
      await updateData(true);
    })().catch((response) => {
      if (response.status === 401) props.history.push('/login');
      else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
    });
  };
  const deleteButtonCallback = (id: number) => {
    (async () => {
      await deleteTalentApplication(props, id, (loading) =>
        setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
      );
      await updateData(true);
    })().catch((response) => {
      if (response.status === 401) props.history.push('/login');
      else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
    });
  };

  const handleChangeFilter = (
    event: React.MouseEvent<HTMLElement>,
    filter: string[],
  ) => {
    const allIndex = filter.indexOf('all');

    if (allIndex < 0) {
      if (!filter.length || filter.length == filterCount) filter = ['all'];
    } else {
      if (allIndex === 0) filter.splice(allIndex, 1);
      else filter = ['all'];
    }

    setFilter(filter);
  };
  const handleClickTestButton = (id: number, name: string) => {
    const index = tests?.saved.findIndex((test) => test.activated);

    if (typeof index === 'number' && 0 <= index) setTestIndex(index);
    else setTestIndex('');

    setTestDialog({
      id,
      talent: name,
      callback: testButtonCallback,
    });
  };
  const handleClickApproveButton = (id: number, name: string) => {
    setApproveDialog({
      id,
      talent: name,
      callback: approveButtonCallback,
    });
  };
  const handleClickRejectButton = (
    id: number,
    name: string,
    state: TalentApplicationState,
  ) => {
    setRejectReason('');
    setRejectDialog({
      id,
      talent: name,
      state,
      callback: rejectButtonCallback,
    });
  };
  const handleClickDeleteButton = (id: number, name: string) => {
    setDeleteDialog({
      id,
      talent: name,
      callback: deleteButtonCallback,
    });
  };
  const handleChangeTestIndexSelect = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setTestIndex(event.target.value as number);
  };
  const handleChangeRejectReasonSelect = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setRejectReason(event.target.value as TalentApplicationRejectReason);
  };

  const updateData = async (purgeCache?: boolean) => {
    try {
      const applications = await fetchTalentApplications(
        props,
        (loading) =>
          setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
        purgeCache ?? false,
      );
      setOldRows(applications);
      setRows(applications);
    } catch (response) {
      if (response.status === 401) props.history.push('/login');
      else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
    }
  };

  useEffect(() => {
    props.registerOnExport(async () => {
      const xlsx = await import('xlsx');
      const wb = xlsx.utils.book_new();
      const ws = xlsx.utils.aoa_to_sheet([
        [
          '번호',
          '신청일자',
          '이름',
          '생년월일',
          '이메일',
          '연락처',
          '희망 직무',
          '최종 학력',
          '최종 학력 상태',
          '전공',
          '개발 교육 수료',
          '현업 유무',
          '총 개발 경력',
          '가입 경로',
          '포트폴리오 문서',
          '포트폴리오 링크',
          '코딩테스트 유형',
          '코딩테스트 응시희망일',
          '코딩테스트 발송일',
          '코딩테스트 점수',
          '상태',
          '미승인 사유',
        ],
        ...sortedRows.map((row, index) => [
          index + 1,
          row.appliedAt,
          row.name,
          row.birth,
          row.email,
          row.phone,
          row.position?.name || '',
          row.school,
          row.schoolState,
          row.major,
          row.bootcamp ? 'YES' : 'NO',
          row.working ? 'YES' : 'NO',
          row.yearsOfExp,
          row.channel || '',
          row.documentURL,
          row.websiteURL,
          row.codingTestType,
          row.codingTestDate,
          row.codingTestSentDate,
          row.codingTestScore || '',
          row.state,
          row.rejectReason,
        ]),
      ]);
      xlsx.utils.book_append_sheet(wb, ws, 'talent applications');
      xlsx.writeFile(wb, 'talent_applications.xlsx');
    });
  }, [sortedRows]);
  useEffect(() => {
    props.registerOnRefresh(() => {
      updateData(true);
    });
  }, []);
  useEffect(() => {
    updateData();
  }, []);
  useEffect(() => {
    if (filter.length === 1 && filter[0] === 'all') {
      setFilteredRows(rows);
      return;
    }

    const filterSet = new Set(filter);
    setFilteredRows(rows.filter((row) => filterSet.has(row.state)));
  }, [rows, filter]);

  const applySearchQuery = useCallback(
    throttle(
      (event: ChangeEvent<HTMLInputElement>) => {
        const query = (event.target.value ?? '').trim();

        if (!query) {
          setRows(oldRows);
          return;
        }

        (async () => {
          const result = await searchClient.search([
            {
              facet: '',
              indexName: `${TARGET_SERVER_SEARCH_INDEX_PREFIX}_talent_application`,
              query,
            },
          ]);
          const maxPage = result.results[0]?.nbPages ?? 1;
          const pages: number[] = [];

          for (let page = 1; page < maxPage; ++page) pages.push(page);

          const extraResults = await Promise.all(
            pages.map((page) =>
              searchClient.search([
                {
                  facet: '',
                  indexName: `${TARGET_SERVER_SEARCH_INDEX_PREFIX}_talent_application`,
                  query,
                  params: {
                    page,
                  },
                },
              ]),
            ),
          );

          setRows(
            await queryTalentApplications(
              props,
              [
                ...result.results,
                ...extraResults.flatMap((result) => result.results),
              ]
                .flatMap((result) => result.hits ?? [])
                .map((hit) => Number(hit.objectID)),
            ),
          );
        })().catch(console.error);
      },
      1000,
      {
        leading: false,
        trailing: true,
      },
    ),
    [oldRows],
  );

  const columns: Column<TalentApplicationRowModel>[] =
    ApplicationScheme.talentColumns;
  columns[0].formatter = function AppliedAtCell(props) {
    return (
      <>
        {Format.dateFormatWithDay.format(props.row.appliedAt)}{' '}
        {Format.timeFormat.format(props.row.appliedAt)}
      </>
    );
  };
  columns[2].formatter = function BirthCell(props) {
    return <>{Format.dateFormat.format(props.row.birth)}</>;
  };
  columns[5].formatter = function PositionCell(props) {
    return <>{props.row.position?.name ?? ''}</>;
  };
  columns[8].formatter = function BootcampCell(props) {
    return <>{props.row.bootcamp ? 'O' : 'X'}</>;
  };
  columns[9].formatter = function WorkingCell(props) {
    return <>{props.row.working ? 'O' : 'X'}</>;
  };
  columns[10].formatter = function YearsOfExp(props) {
    return (
      <>
        경력{' '}
        {props.row.yearsOfExp === '0' ? '없음' : `${props.row.yearsOfExp}년`}
      </>
    );
  };
  columns[11].formatter = function ChannelCell(props) {
    return <>{props.row.channel ?? '-'}</>;
  };
  columns[12].formatter = function CodingTestTypeCell(props) {
    return <>{props.row.codingTestType ?? '-'}</>;
  };
  columns[13].formatter = function CodingTestDateCell(props) {
    return (
      <>
        {props.row.codingTestDate
          ? Format.dateFormatWithDayLong.format(props.row.codingTestDate)
          : '-'}
      </>
    );
  };
  columns[14].formatter = function CodingTestSentDateCell(props) {
    return (
      <>
        {props.row.codingTestSentDate
          ? Format.dateFormatWithDayLong.format(props.row.codingTestSentDate)
          : '-'}
      </>
    );
  };
  columns[15].formatter = function CodingTestScore(props) {
    if (props.row.codingTestPlagiarismStatus)
      return (
        <>
          <ReviewIcon>
            <i className="fas fa-exclamation" />
          </ReviewIcon>
          <ScoreReview>{props.row.codingTestScore ?? '-'}</ScoreReview>
        </>
      );
    else if (
      props.row.codingTestScore !== null &&
      props.row.codingTestCutoffScore !== null
    ) {
      return props.row.codingTestCutoffScore <= props.row.codingTestScore ? (
        <ScorePass>{props.row.codingTestScore ?? '-'}</ScorePass>
      ) : (
        <ScoreNonPass>{props.row.codingTestScore ?? '-'}</ScoreNonPass>
      );
    } else return <>{props.row.codingTestScore ?? '-'}</>;
  };
  columns[16].formatter = function DocumentURLCell(props): ReactElement {
    return props.row.documentURL ? (
      <Link href={props.row.documentURL} target="_blank" rel="noopener">
        {props.row.documentURL}
      </Link>
    ) : (
      <>(미첨부)</>
    );
  };
  columns[17].formatter = function WebsiteURLCell(props): ReactElement {
    return props.row.websiteURL ? (
      <Link href={props.row.websiteURL} target="_blank" rel="noopener">
        {props.row.websiteURL}
      </Link>
    ) : (
      <>(미등록)</>
    );
  };
  columns[18].formatter = function StateCell(props) {
    const row = props.row as TalentApplicationRowModel;

    switch (row.state) {
      case TalentApplicationState.PENDING:
        return (
          <>
            <PendingIcon>
              <i className="fas fa-check" />
            </PendingIcon>
            <StateText>가입 신청함</StateText>
          </>
        );
      case TalentApplicationState.APPROVED:
        return (
          <>
            <ApproveIcon>
              <i className="fas fa-check" />
            </ApproveIcon>
            <StateText>승인됨</StateText>
          </>
        );
      case TalentApplicationState.REJECTED: {
        let rejectReason;

        switch (row.rejectReason) {
          case TalentApplicationRejectReason.NOT_QUALIFIED:
            rejectReason = '가입 조건 미달';
            break;
          case TalentApplicationRejectReason.TEST_IGNORED:
            rejectReason = '코딩테스트 미응시';
            break;
          case TalentApplicationRejectReason.TEST_BELOW:
            rejectReason = '코딩테스트 점수 미달';
            break;
          case TalentApplicationRejectReason.TEST_CHEAT:
            rejectReason = '코딩테스트 부정행위';
            break;
          case TalentApplicationRejectReason.DUPLICATED:
            rejectReason = '이미 승인되어 있는 사용자';
            break;
          default:
            rejectReason = null;
            break;
        }

        return rejectReason ? (
          <Tooltip title={rejectReason}>
            <span>
              <RejectIcon>
                <i className="fas fa-times" />
              </RejectIcon>
              <StateRejectedText>미승인</StateRejectedText>
            </span>
          </Tooltip>
        ) : (
          <>
            <RejectIcon>
              <i className="fas fa-times" />
            </RejectIcon>
            <StateRejectedText>미승인</StateRejectedText>
          </>
        );
      }
      case TalentApplicationState.TESTING:
        return (
          <>
            <TestingIcon>H</TestingIcon>
            <StateText>테스트 진행중</StateText>
          </>
        );
      case TalentApplicationState.TESTED:
        return (
          <>
            <TestedIcon>H</TestedIcon>
            <StateText>테스트 완료</StateText>
          </>
        );
    }
  };
  columns[19].formatter = function ActionCell(props) {
    const row = props.row as TalentApplicationRowModel;
    let buttons: [[boolean, boolean], boolean, boolean];

    switch (row.state) {
      case TalentApplicationState.PENDING:
        buttons = [[true, false], true, true];
        break;
      case TalentApplicationState.APPROVED:
        buttons = [[false, false], false, true];
        break;
      case TalentApplicationState.REJECTED:
        buttons = [[true, true], true, false];
        break;
      case TalentApplicationState.TESTING:
        buttons = [[false, false], true, true];
        break;
      case TalentApplicationState.TESTED:
        buttons = [[true, true], true, true];
        break;
    }

    return (
      <>
        <ThemeProvider theme={approveButtonTheme}>
          {buttons[0][1] ? (
            <ThemeProvider theme={testRepeatButtonTheme}>
              <TestRepeatButton
                color="primary"
                variant="contained"
                onClick={() =>
                  handleClickTestButton(row.id as number, row.name)
                }
                disabled={!buttons[0][0]}
              >
                테스트 재발송
              </TestRepeatButton>
            </ThemeProvider>
          ) : (
            <ThemeProvider theme={testButtonTheme}>
              <TestButton
                color="primary"
                variant="contained"
                onClick={() =>
                  handleClickTestButton(row.id as number, row.name)
                }
                disabled={!buttons[0][0]}
              >
                테스트 발송
              </TestButton>
            </ThemeProvider>
          )}
          <ApproveButton
            color="primary"
            variant="contained"
            onClick={() => handleClickApproveButton(row.id as number, row.name)}
            disabled={!buttons[1]}
          >
            승인
          </ApproveButton>
          <ThemeProvider theme={rejectButtonTheme}>
            <RejectButton
              color="primary"
              variant="contained"
              onClick={() =>
                handleClickRejectButton(row.id as number, row.name, row.state)
              }
              disabled={!buttons[2]}
            >
              미승인
            </RejectButton>
          </ThemeProvider>
        </ThemeProvider>
        <DeleteButton
          style={{ color: '#ff5c5c' }}
          variant="text"
          onClick={() => handleClickDeleteButton(row.id as number, row.name)}
        >
          <i className="far fa-trash-alt" />
        </DeleteButton>
      </>
    );
  };

  return (
    <>
      <FilterBarWrapper>
        <FilterBarInnerWrapper>
          <ToggleButtonGroup value={filter} onChange={handleChangeFilter}>
            {FilterButton({
              value: 'all',
              label: '전체',
              num: rows.length,
            })}
          </ToggleButtonGroup>
        </FilterBarInnerWrapper>
        <FilterBarInnerWrapper>
          <ToggleButtonGroup value={filter} onChange={handleChangeFilter}>
            {ApplicationScheme.blueTalentFilters.map((filter) =>
              FilterButton({
                key: filter.state,
                value: filter.state,
                label: filter.displayName,
                num: rows.filter((row) => row.state === filter.state).length,
              }),
            )}
          </ToggleButtonGroup>
        </FilterBarInnerWrapper>
        <FilterBarInnerWrapper>
          <ToggleButtonGroup value={filter} onChange={handleChangeFilter}>
            {ApplicationScheme.redTalentFilters.map((filter) =>
              FilterButton({
                key: filter.state,
                value: filter.state,
                label: filter.displayName,
                num: rows.filter((row) => row.state === filter.state).length,
                red: true,
              }),
            )}
          </ToggleButtonGroup>
        </FilterBarInnerWrapper>
      </FilterBarWrapper>
      <SearchBarInput
        placeholder="인재 신청 검색"
        onChange={applySearchQuery}
      />
      <DataGridWrapper>
        <DataGrid
          className="datagrid"
          style={{ height: '100%' }}
          rowHeight={45}
          rows={sortedRows}
          columns={columns}
          sorts={sort}
          onSort={(sorts) => {
            setSort(sorts);
            localStorage.setItem(
              `${props.match.url}@sort`,
              JSON.stringify(sorts),
            );
          }}
        />
      </DataGridWrapper>
      <BackgroundCover open={loading !== 0}>
        <CircularProgress />
      </BackgroundCover>
      <Dialog open={!!testDialog}>
        <DialogWrapper>
          <DialogTitleText>
            {testDialog?.talent}님의 코딩테스트를
          </DialogTitleText>
          <DialogTitleText>진행하시겠습니까?</DialogTitleText>
          <DialogItemRow>
            <DialogItemText>테스트</DialogItemText>
            <Select
              variant="outlined"
              value={testIndex}
              onChange={handleChangeTestIndexSelect}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" disabled>
                테스트를 선택해주세요
              </MenuItem>
              {tests?.saved.map((test, index) => (
                <MenuItem key={test.id} value={index} selected={test.activated}>
                  {test.name} ({test.cutoffScore}점 / {test.maxScore}점, 총{' '}
                  {test.questions} 문항)
                </MenuItem>
              ))}
            </Select>
            <DialogVerticalButton
              variant="contained"
              color="primary"
              onClick={() => {
                if (typeof testIndex !== 'number') return;
                setTestDialog(null);
                testDialog?.callback(
                  testDialog.id,
                  tests?.saved[testIndex].testId || 0,
                  tests?.saved[testIndex].cutoffScore || 0,
                );
              }}
              disabled={typeof testIndex !== 'number'}
            >
              확인
            </DialogVerticalButton>
            <DialogVerticalButton
              variant="text"
              onClick={() => setTestDialog(null)}
            >
              취소
            </DialogVerticalButton>
          </DialogItemRow>
        </DialogWrapper>
      </Dialog>
      <Dialog open={!!approveDialog}>
        <DialogWrapper>
          <DialogTitleText>{approveDialog?.talent}님의</DialogTitleText>
          <DialogTitleText>가입 신청을 승인하시겠습니까?</DialogTitleText>
          <DialogButtonRow>
            <DialogRowButton
              variant="contained"
              onClick={() => setApproveDialog(null)}
            >
              아니오
            </DialogRowButton>
            <DialogRowButton
              variant="contained"
              color="primary"
              onClick={() => {
                setApproveDialog(null);
                approveDialog?.callback(approveDialog.id);
              }}
            >
              네
            </DialogRowButton>
          </DialogButtonRow>
        </DialogWrapper>
      </Dialog>
      <Dialog open={!!rejectDialog}>
        <DialogWrapper>
          <DialogTitleText>{rejectDialog?.talent}님의</DialogTitleText>
          <DialogTitleText>가입 신청을 미승인합니다.</DialogTitleText>
          <DialogItemRow>
            <DialogItemText>미승인 사유</DialogItemText>
            <Select
              variant="outlined"
              value={rejectReason}
              onChange={handleChangeRejectReasonSelect}
              displayEmpty
              fullWidth
            >
              <MenuItem value="">없음</MenuItem>
              {(() => {
                switch (rejectDialog?.state) {
                  case TalentApplicationState.PENDING:
                    return [
                      <MenuItem
                        key={TalentApplicationRejectReason.NOT_QUALIFIED}
                        value={TalentApplicationRejectReason.NOT_QUALIFIED}
                      >
                        가입 조건 미달
                      </MenuItem>,
                      <MenuItem
                        key={TalentApplicationRejectReason.DUPLICATED}
                        value={TalentApplicationRejectReason.DUPLICATED}
                      >
                        이미 승인되어 있는 사용자
                      </MenuItem>,
                    ];
                  case TalentApplicationState.TESTING:
                    return [
                      <MenuItem
                        key={TalentApplicationRejectReason.NOT_QUALIFIED}
                        value={TalentApplicationRejectReason.NOT_QUALIFIED}
                      >
                        가입 조건 미달
                      </MenuItem>,
                      <MenuItem
                        key={TalentApplicationRejectReason.TEST_IGNORED}
                        value={TalentApplicationRejectReason.TEST_IGNORED}
                      >
                        코딩테스트 미응시
                      </MenuItem>,
                      <MenuItem
                        key={TalentApplicationRejectReason.DUPLICATED}
                        value={TalentApplicationRejectReason.DUPLICATED}
                      >
                        이미 승인되어 있는 사용자
                      </MenuItem>,
                    ];
                  case TalentApplicationState.TESTED:
                    return [
                      <MenuItem
                        key={TalentApplicationRejectReason.NOT_QUALIFIED}
                        value={TalentApplicationRejectReason.NOT_QUALIFIED}
                      >
                        가입 조건 미달
                      </MenuItem>,
                      <MenuItem
                        key={TalentApplicationRejectReason.TEST_BELOW}
                        value={TalentApplicationRejectReason.TEST_BELOW}
                      >
                        코딩테스트 점수 미달
                      </MenuItem>,
                      <MenuItem
                        key={TalentApplicationRejectReason.TEST_CHEAT}
                        value={TalentApplicationRejectReason.TEST_CHEAT}
                      >
                        코딩테스트 부정행위
                      </MenuItem>,

                      <MenuItem
                        key={TalentApplicationRejectReason.DUPLICATED}
                        value={TalentApplicationRejectReason.DUPLICATED}
                      >
                        이미 승인되어 있는 사용자
                      </MenuItem>,
                    ];
                  default:
                    return [];
                }
              })()}
            </Select>
            <DialogVerticalButton
              variant="contained"
              color="secondary"
              onClick={() => {
                setRejectDialog(null);
                rejectDialog?.callback(rejectDialog.id, rejectReason);
              }}
            >
              확인
            </DialogVerticalButton>
            <DialogVerticalButton
              variant="text"
              onClick={() => setRejectDialog(null)}
            >
              취소
            </DialogVerticalButton>
          </DialogItemRow>
        </DialogWrapper>
      </Dialog>
      <Dialog open={!!deleteDialog}>
        <DialogWrapper>
          <DialogTitleText>{deleteDialog?.talent}님의</DialogTitleText>
          <DialogTitleText>신청 내역을 삭제하시겠습니까?</DialogTitleText>
          <DialogButtonRow>
            <DialogRowButton
              variant="contained"
              onClick={() => setDeleteDialog(null)}
            >
              아니오
            </DialogRowButton>
            <DialogRowButton
              variant="contained"
              color="secondary"
              onClick={() => {
                setDeleteDialog(null);
                deleteDialog?.callback(deleteDialog.id);
              }}
            >
              네
            </DialogRowButton>
          </DialogButtonRow>
        </DialogWrapper>
      </Dialog>
    </>
  );
});
