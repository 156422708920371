import { styled as mstyled, Button } from '@material-ui/core';
import classNames from 'classnames';
import React, { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';

import '@fortawesome/fontawesome-free/css/all.css';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;
const InnerWrapper = styled.section`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  width: 600px;
  background-color: white;
`;
const TitleWrapper = styled.header`
  position: relative;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 56px;
`;
const TitleText = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  color: #222222;
  text-align: center;
`;
const ContentWrapper = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 20px;
  overflow: auto;
  overflow-wrap: break-word;
`;

const CloseButton = mstyled(Button)({
  position: 'absolute',
  left: 0,
  width: '56px',
  height: '100%',
  fontSize: '21px',
});

export default function SideView(
  props: PropsWithChildren<{
    title: string;
    hasPrevious?: boolean;
    onClose(): void;
  }>,
): ReactElement {
  return (
    <Wrapper onClick={props.onClose}>
      <InnerWrapper onClick={(event) => event.stopPropagation()}>
        <TitleWrapper>
          <TitleText>{props.title}</TitleText>
          <CloseButton onClick={props.onClose}>
            <i
              className={classNames(
                'fas',
                props.hasPrevious ? 'fa-chevron-left' : 'fa-times',
              )}
            />
          </CloseButton>
        </TitleWrapper>
        <ContentWrapper>{props.children}</ContentWrapper>
      </InnerWrapper>
    </Wrapper>
  );
}
