import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { Recommendation } from '../schemas/Recommendation';

import Format from '../helpers/Format';

const Title = styled.h2`
  margin: 0;
  margin-top: 32px;
  font-size: 16px;
  font-weight: bold;
  color: #222222;
  &:first-of-type {
    margin-top: 0;
  }
`;
const SubTitle = styled.p`
  display: block;
  margin-top: 4px;
  font-size: 14px;
  font-weight: normal;
  color: #999999;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
`;
const Header = styled.h3`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  margin-top: 16px;
  width: 83px;
  font-size: 14px;
  font-weight: bold;
  color: #222222;
`;
const Text = styled.p`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  margin-top: 8px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: #222222;
`;

export default function OfferDetail(props: {
  companyName: string;
  talentName: string;
  recomm: Recommendation;
}): ReactElement {
  return (
    <>
      <Title>제안 기업</Title>
      <Text>{props.companyName}</Text>
      <Title>인재 정보</Title>
      <Row>
        <Header>이름</Header>
        <Text>{props.talentName}</Text>
      </Row>
      <Row>
        <Header>희망직무</Header>
        <Text>{props.recomm.positionDetail.position}</Text>
      </Row>
      {props.recomm.offerDetail && (
        <>
          <Title>제안 정보</Title>
          <SubTitle>
            {Format.dateFormatWithDay.format(props.recomm.offerDetail.date)}{' '}
            {Format.timeFormat.format(props.recomm.offerDetail.date)}
            {' 제안함'}
          </SubTitle>
          <Row>
            <Header>면접 형태</Header>
            <Text>
              {props.recomm.offerDetail.offlineDetail
                ? '대면 전형'
                : props.recomm.offerDetail.onlineDetail
                ? '비대면 전형'
                : '-'}
            </Text>
          </Row>
          <Row>
            <Header>제안 직무</Header>
            <Text>{props.recomm.offerDetail.position}</Text>
          </Row>
          <Title>상세 업무</Title>
          <Text>{props.recomm.offerDetail.duty}</Text>
          {props.recomm.offerDetail.onlineDetail && (
            <>
              <Title>면접 링크</Title>
              <Text>{props.recomm.offerDetail.onlineDetail.url}</Text>
            </>
          )}
          {props.recomm.offerDetail.offlineDetail && (
            <>
              <Title>면접 장소</Title>
              <Text>{props.recomm.offerDetail.offlineDetail.address}</Text>
            </>
          )}
          <Title>준비사항</Title>
          <Text>{props.recomm.offerDetail.preparation ?? '-'}</Text>
          <Title>제안한 면접 일정</Title>
          {props.recomm.offerDetail.times.length
            ? props.recomm.offerDetail.times.map((time, index) => (
                <Text key={index}>
                  {Format.dateFormatWithDayLong.format(time.date)}{' '}
                  {Format.timeSpanFormat.format(time)}
                </Text>
              ))
            : '없음'}
        </>
      )}
    </>
  );
}
