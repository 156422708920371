import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { Recommendation } from '../schemas/Recommendation';

import Format from '../helpers/Format';

const Title = styled.h2`
  margin: 0;
  margin-top: 32px;
  font-size: 16px;
  font-weight: bold;
  color: #222222;
  &:first-child {
    margin-top: 0;
  }
`;
const SubTitle = styled.span`
  display: block;
  margin-top: 4px;
  font-size: 14px;
  font-weight: normal;
  color: #999999;
  &:first-child {
    margin-top: 0;
  }
`;
const Text = styled.h3`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  margin-top: 8px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: #222222;
`;

export default function InterviewResultDetail(props: {
  recomm: Recommendation<unknown>;
}): ReactElement {
  return (
    <>
      {props.recomm.resultDetail && (
        <>
          <SubTitle>
            {Format.dateFormatWithDay.format(props.recomm.resultDetail.date)}{' '}
            {Format.timeFormat.format(props.recomm.resultDetail.date)}
            {' 생성함'}
          </SubTitle>
          <Title>합격 여부</Title>
          <Text>{props.recomm.resultDetail.passed ? '합격' : '불합격'}</Text>
          {props.recomm.resultDetail.passed && (
            <>
              <Title>제시 연봉</Title>
              <Text>
                {props.recomm.resultDetail.passedSalary
                  ? Format.floatFormat.format(
                      props.recomm.resultDetail.passedSalary,
                    )
                  : '-'}
              </Text>
            </>
          )}
          {!props.recomm.resultDetail.passed && (
            <>
              <Title>불합격 사유</Title>
              <Text>{props.recomm.resultDetail.nonPassReason ?? '-'}</Text>
              <Title>불합격 상세 사유</Title>
              <Text>
                {props.recomm.resultDetail.nonPassReasonDetail ?? '-'}
              </Text>
            </>
          )}
        </>
      )}
    </>
  );
}
