import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { Recommendation } from '../schemas/Recommendation';

import Format from '../helpers/Format';

const Title = styled.h2`
  margin: 0;
  margin-top: 32px;
  font-size: 16px;
  font-weight: bold;
  color: #222222;
  &:first-child {
    margin-top: 0;
  }
`;
const SubTitle = styled.span`
  display: block;
  margin-top: 4px;
  font-size: 14px;
  font-weight: normal;
  color: #999999;
  &:first-child {
    margin-top: 0;
  }
`;
const Text = styled.h3`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  margin-top: 8px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: #222222;
`;

export default function CCInterviewReview(props: {
  recomm: Recommendation<{
    ccTime: boolean;
    ccAmbience: boolean;
    ccClear: boolean;
  }>;
}): ReactElement {
  return (
    <>
      {props.recomm.interviewReviewDetail && (
        <>
          <SubTitle>
            {Format.dateFormatWithDay.format(
              props.recomm.interviewReviewDetail.date,
            )}{' '}
            {Format.timeFormat.format(props.recomm.interviewReviewDetail.date)}
            {' 작성함'}
          </SubTitle>
          <Title>별점 평가</Title>
          <Text>
            {Format.floatFormat.format(
              props.recomm.interviewReviewDetail.score / 2,
            )}
          </Text>
          <Title>면접이 예정된 시간에 시작됐나요?</Title>
          <Text>
            {props.recomm.interviewReviewDetail.review.ccTime ? '예' : '아니오'}
          </Text>
          <Title>면접관이 면접 분위기를 잘 조성해주었나요?</Title>
          <Text>
            {props.recomm.interviewReviewDetail.review.ccAmbience
              ? '예'
              : '아니오'}
          </Text>
          <Title>
            면접관이 직무 및 수행업무에 대해서 알기쉽게 설명해주었나요?
          </Title>
          <Text>
            {props.recomm.interviewReviewDetail.review.ccClear
              ? '예'
              : '아니오'}
          </Text>
          <Title>그 외 다른 점은 없었나요?(선택)</Title>
          <Text>{props.recomm.interviewReviewDetail.comment ?? '-'}</Text>
        </>
      )}
    </>
  );
}
