import { styled as mstyled, Button, Tabs, Tab } from '@material-ui/core';
import { Location } from 'history';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  generatePath,
  withRouter,
} from 'react-router-dom';
import styled from 'styled-components';

import CodingTestSetting from '../fragments/CodingTestSetting';
import EmailSetting from './EmailSetting';

import '@fortawesome/fontawesome-free/css/all.css';

const Wrapper = styled.div`
  padding: 40px 36px 36px;
`;
const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  & + & {
    margin-top: 32px;
  }
`;
const RowInnerWrapper = styled.div`
  width: 100%;
`;
const Spacer = styled.span`
  margin: 0 auto;
`;
const TitleText = styled.h1`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  font-size: 34px;
  font-weight: bold;
  color: #222222;
`;

const RefreshButton = mstyled(Button)({
  marginLeft: '16px',
  padding: '5px 8px',
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#222222',
});

export default withRouter(function SettingDashboard(
  props: RouteComponentProps<{ type: string; settingType: string }> & {
    token: string;
  },
): ReactElement {
  const [tabType, setTabType] = useState(
    ['notifications', 'coding-tests'].includes(props.match.params.settingType)
      ? props.match.params.settingType
      : 'notifications',
  );
  const [lastCodingTestSettingLocation, setLastCodingTestSettingLocation] =
    useState<string | null>(null);
  const [lastNotificationSettingLocation, setLastNotificationSettingLocation] =
    useState<string | null>(null);
  const [onRefresh, setOnRefresh] = useState<(() => void) | null>(null);

  const handleChangeTabType = (
    _: React.ChangeEvent<Record<string, unknown>>,
    value: string,
  ): void => {
    if (tabType === value) return;

    switch (value) {
      case 'coding-tests':
        props.history.push(
          lastCodingTestSettingLocation ??
            generatePath(props.match.path, {
              settingType: 'coding-tests',
            }),
        );
        break;
      case 'notifications':
        props.history.push(
          lastNotificationSettingLocation ??
            generatePath(props.match.path, {
              settingType: 'notifications',
            }),
        );
        break;
    }

    setTabType(value);
  };
  const handleChangeLocation = (location: Location<unknown>): void => {
    if (location.pathname.startsWith('/settings/coding-tests')) {
      setLastCodingTestSettingLocation(location.pathname);
      setTabType('coding-tests');
    } else if (location.pathname.startsWith('/settings/notifications')) {
      setLastNotificationSettingLocation(location.pathname);
      setTabType('notifications');
    }
  };

  useEffect(() => {
    handleChangeLocation(props.location);
    const unlisten = props.history.listen(handleChangeLocation);
    return () => unlisten();
  }, []);

  return (
    <Wrapper>
      <RowWrapper>
        <TitleText>어드민 설정</TitleText>
        <Spacer />
        <RefreshButton
          variant="text"
          onClick={() => {
            if (onRefresh) onRefresh();
          }}
        >
          <i className="fas fa-sync-alt" />
          <span className="fa-space">새로고침</span>
        </RefreshButton>
      </RowWrapper>
      <RowWrapper>
        <Tabs
          value={tabType}
          onChange={handleChangeTabType}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="이메일/알림톡 설정" value="notifications" />
          <Tab label="코딩테스트 설정" value="coding-tests" />
        </Tabs>
      </RowWrapper>
      <RowWrapper>
        <RowInnerWrapper>
          <Switch>
            <Route
              render={(innerProps) => (
                <EmailSetting
                  {...innerProps}
                  token={props.token}
                  registerOnRefresh={(listener) => {
                    setOnRefresh(() => listener);
                  }}
                />
              )}
              path={generatePath(props.match.path, {
                settingType: 'notifications',
              })}
            />
            <Route
              render={(innerProps) => (
                <CodingTestSetting
                  {...innerProps}
                  token={props.token}
                  registerOnRefresh={(listener) => {
                    setOnRefresh(() => listener);
                  }}
                />
              )}
              path={generatePath(props.match.path, {
                settingType: 'coding-tests',
              })}
            />
            <Redirect
              to={generatePath(props.match.path, {
                settingType: 'notifications',
              })}
            />
          </Switch>
        </RowInnerWrapper>
      </RowWrapper>
    </Wrapper>
  );
});
