import { styled as mstyled, Button, Tabs, Tab } from '@material-ui/core';
import { Location } from 'history';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  generatePath,
  withRouter,
} from 'react-router-dom';
import styled from 'styled-components';

import CCApplication from '../fragments/CCApplication';
import TalentApplication from '../fragments/TalentApplication';

import '@fortawesome/fontawesome-free/css/all.css';

const Wrapper = styled.div`
  padding: 40px 36px 36px;
`;
const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  & + & {
    margin-top: 32px;
  }
`;
const RowInnerWrapper = styled.div`
  width: 100%;
`;
const Spacer = styled.span`
  margin: 0 auto;
`;
const TitleText = styled.h1`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  font-size: 34px;
  font-weight: bold;
  color: #222222;
`;

const MenuButton = mstyled(Button)({
  marginLeft: '16px',
  padding: '5px 8px',
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#222222',
  '$ + $': {
    marginLeft: '8px',
  },
});

export default withRouter(function Dashboard(
  props: RouteComponentProps<{ type: string; applicationType: string }> & {
    token: string;
  },
): ReactElement {
  const [tabType, setTabType] = useState(
    ['ccs', 'talents'].includes(props.match.params.applicationType)
      ? props.match.params.applicationType
      : 'ccs',
  );
  const [lastCCLocation, setLastCCLocation] = useState<string | null>(null);
  const [lastTalentLocation, setLastTalentLocation] = useState<string | null>(
    null,
  );
  const [onExport, setOnExport] = useState<(() => void) | null>(null);
  const [onRefresh, setOnRefresh] = useState<(() => void) | null>(null);

  const handleChangeTabType = (
    _: React.ChangeEvent<Record<string, unknown>>,
    value: string,
  ): void => {
    if (tabType === value) return;

    switch (value) {
      case 'ccs':
        props.history.push(
          lastCCLocation ??
            generatePath(props.match.path, {
              applicationType: 'ccs',
            }),
        );
        break;
      case 'talents':
        props.history.push(
          lastTalentLocation ??
            generatePath(props.match.path, {
              applicationType: 'talents',
            }),
        );
        break;
    }

    setTabType(value);
  };
  const handleChangeLocation = (location: Location<unknown>): void => {
    if (location.pathname.startsWith('/applications/ccs')) {
      setLastCCLocation(location.pathname);
      setTabType('ccs');
    } else if (location.pathname.startsWith('/applications/talents')) {
      setLastTalentLocation(location.pathname);
      setTabType('talents');
    }
  };

  useEffect(() => {
    handleChangeLocation(props.location);
    const unlisten = props.history.listen(handleChangeLocation);
    return () => unlisten();
  }, []);

  return (
    <Wrapper>
      <RowWrapper>
        <TitleText>가입 신청</TitleText>
        <Spacer />
        <MenuButton
          variant="text"
          onClick={() => {
            if (onExport) onExport();
          }}
        >
          <i className="fas fa-download" />
          <span className="fa-space">내보내기</span>
        </MenuButton>
        <MenuButton
          variant="text"
          onClick={() => {
            if (onRefresh) onRefresh();
          }}
        >
          <i className="fas fa-sync-alt" />
          <span className="fa-space">새로고침</span>
        </MenuButton>
      </RowWrapper>
      <RowWrapper>
        <Tabs
          value={tabType}
          onChange={handleChangeTabType}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="기업 신청" value="ccs" />
          <Tab label="인재 신청" value="talents" />
        </Tabs>
      </RowWrapper>
      <RowWrapper>
        <RowInnerWrapper>
          <Switch>
            <Route
              render={(innerProps) => (
                <CCApplication
                  {...innerProps}
                  token={props.token}
                  registerOnExport={(listener) => {
                    setOnExport(() => listener);
                  }}
                  registerOnRefresh={(listener) => {
                    setOnRefresh(() => listener);
                  }}
                />
              )}
              path={generatePath(props.match.path, {
                applicationType: 'ccs',
              })}
            />
            <Route
              render={(innerProps) => (
                <TalentApplication
                  {...innerProps}
                  token={props.token}
                  registerOnExport={(listener) => {
                    setOnExport(() => listener);
                  }}
                  registerOnRefresh={(listener) => {
                    setOnRefresh(() => listener);
                  }}
                />
              )}
              path={generatePath(props.match.path, {
                applicationType: 'talents',
              })}
            />
            <Redirect
              to={generatePath(props.match.path, {
                applicationType: 'ccs',
              })}
            />
          </Switch>
        </RowInnerWrapper>
      </RowWrapper>
    </Wrapper>
  );
});
