/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Wrapper,
  Footer,
  Header,
  Content,
  HeaderTitleBigText,
  HeaderTitleSmallText,
  HeaderWrapper,
  HeaderSpacer,
  buttonStyles,
  FooterWrapper,
  FooterSpacer,
  FooterSummaryText,
  FooterSummaryTextSpacer,
  FooterActionButtonSpacer,
} from './styles';
import { Item } from './item';

import { Button } from '@material-ui/core';

import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { generateApiUrl } from '../../helpers/Api';
import { VirtualRecommendation } from '../../schemas/VirtualRecommendation';

export function VirtualRecomm(props: {
  id: string;
  company: string;
  positions: string[];
  token: string;
  onRecomm(isPeriodic: boolean, recomms: VirtualRecommendation[]): void;
  onClose(): void;
}): ReactElement {
  const history = useHistory();

  const buttonClasses = buttonStyles();

  const [positionTab, setPositionTab] = useState<string>('');
  const [recomms, setRecomms] = useState<VirtualRecommendation[]>([]);
  const [recommsPerPositions, setRecommsPerPositions] = useState<
    Map<string, VirtualRecommendation[]>
  >(new Map());
  const [recommSelections, setRecommSelections] = useState<Set<number>>(
    new Set(),
  );

  async function getRecomms(): Promise<void> {
    const response = await fetch(
      generateApiUrl(`/admin/ccs/${props.id}/virtual-recomms`),
      {
        method: 'GET',
        headers: {
          'Api-Token': props.token,
          Accept: 'application/json',
        },
      },
    );

    const recomms: VirtualRecommendation[] = (await response.json()).map(
      (recomm: VirtualRecommendation) => {
        recomm.ccHopePosition = (recomm.ccHopePosition as any).name;
        recomm.talentHopePosition = (recomm.talentHopePosition as any).name;
        recomm.expiry = new Date(recomm.expiry);
        recomm.createdAt = new Date(recomm.createdAt);
        return recomm;
      },
    );

    setRecomms(recomms);
    setRecommSelections(new Set());

    const perPositions = new Map();

    for (const position of props.positions)
      perPositions.set(
        position,
        recomms.filter((recomm) => recomm.ccHopePosition === position),
      );

    setRecommsPerPositions(perPositions);
  }

  async function generateRecomms(): Promise<void> {
    await fetch(generateApiUrl(`/admin/ccs/${props.id}/virtual-recomms`), {
      method: 'POST',
      headers: {
        'Api-Token': props.token,
      },
    });
  }

  useEffect(() => {
    getRecomms().catch((response) => {
      if (response.status === 401) history.push('/login');
      else {
        console.error(response);
        alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
      }
    });
  }, []);

  function onToggleItem(index: number) {
    const id = (recommsPerPositions.get(positionTab) ?? recomms)[index].id;

    if (recommSelections.has(id)) recommSelections.delete(id);
    else recommSelections.add(id);

    setRecommSelections(new Set(recommSelections));
  }

  function onClickGenerateRecommButton() {
    generateRecomms()
      .then(() => getRecomms())
      .catch((response) => {
        if (response.status === 401) history.push('/login');
        else {
          console.error(response);
          alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
        }
      });
  }

  function onClickRecommButton() {
    if (!recommSelections.size) return;

    props.onRecomm(
      recomms.findIndex((recomm) => recomm.recommendedByAdmin) === -1,
      Array.from(recommSelections)
        .map((id) => recomms.find((recomm) => recomm.id === id))
        .filter((recomm) => recomm) as VirtualRecommendation[],
    );
  }

  function onClickCloseButton() {
    props.onClose();
  }

  return (
    <Wrapper>
      <Header>
        <HeaderWrapper>
          <HeaderTitleBigText>가추천 결과</HeaderTitleBigText>
          <HeaderTitleSmallText>{props.company}</HeaderTitleSmallText>
        </HeaderWrapper>
        <HeaderSpacer />
        <HeaderWrapper>
          <button onClick={() => setPositionTab('')}>
            전체 ({999 < recomms.length ? '999+' : recomms.length})
          </button>
          {props.positions.map((position) => (
            <button key={position} onClick={() => setPositionTab(position)}>
              {position} (
              {999 < recommsPerPositions.get(position)?.length ?? 0
                ? '999+'
                : recommsPerPositions.get(position)?.length ?? 0}
              )
            </button>
          ))}
        </HeaderWrapper>
      </Header>
      <Content>
        {(recommsPerPositions.get(positionTab) ?? recomms).map(
          (recomm, index) => (
            <Item
              key={recomm.id}
              index={index}
              selected={recommSelections.has(recomm.id)}
              item={recomm}
              onToggle={onToggleItem}
            />
          ),
        )}
      </Content>
      <Footer>
        <FooterWrapper>
          <Button
            className={buttonClasses.generateRecomm}
            variant="contained"
            color="primary"
            onClick={onClickGenerateRecommButton}
          >
            재추천 실행
          </Button>
        </FooterWrapper>
        <FooterSpacer />
        {!!recommSelections.size && (
          <>
            <FooterSummaryText>
              <i className="fas fa-check"></i> {recommSelections.size}명 선택됨
            </FooterSummaryText>
            <FooterSummaryTextSpacer />
          </>
        )}
        <FooterWrapper>
          <Button
            className={buttonClasses.makeRecomm}
            variant="contained"
            color="primary"
            onClick={onClickRecommButton}
          >
            {recomms.findIndex((recomm) => recomm.recommendedByAdmin) === -1
              ? '정기'
              : '타겟'}{' '}
            추천하기
          </Button>
          <FooterActionButtonSpacer />
          <Button
            className={buttonClasses.closeRecomm}
            variant="contained"
            color="primary"
            onClick={onClickCloseButton}
          >
            닫기
          </Button>
        </FooterWrapper>
      </Footer>
    </Wrapper>
  );
}
