import { Column } from 'react-data-grid';

import { Recommendation, RecommendationStage } from './Recommendation';

export interface CCRowModel {
  id: number;
  index: number;
  companyName: string;
  plan: string;
  hasProfile: boolean;
  industry: string;
  users: {
    email: string;
    name: string;
    phone: string;
    notifyMarketingRelated: boolean;
  }[];
  numOfRecomm: number;
  numOfProfileEval: number;
  numOfPendingIntents: number;
  numOfDoneIntentsYes: number;
  numOfDoneIntentsLater: number;
  numOfDoneIntentsNo: number;
  numOfDoneIntentsNoResponse: number;
  numOfOffer: number;
  numOfCounterOffer: number;
  numOfInterview: number;
  numOfDoneInterview: number;
  numOfPass: number;
  numOfDoneHire: number;
  numOfFail: number;
  numOfTerm: number;
  targets: {
    position: string;
    skills: string[];
    lowYears: number;
    highYears: number;
    lowSalary: number;
    highSalary: number;
  }[];
  joinedAt: Date;
}

export interface CCProfileData {
  id: number;
  name: string;
  companyLogoUrl?: string;
  companyPhotoUrls: string[];
  industry?: string;
  ceo?: string;
  users: { email: string; name: string; phone: string }[];
  url?: string;
  scale?: string;
  slogan?: string;
  description?: string;
  since?: Date;
  numOfEmployees?: string;
  address?: string;
  services: {
    serviceLogoUrl?: string;
    name: string;
    description?: string;
  }[];
  joinedAt: Date;
}

export interface CCTalentRowModel {
  id: number;
  talentId: number;
  index: number;
  name: string;
  recommDetail: Recommendation<{
    talentTime: boolean;
    talentManner: boolean;
    talentSuitable: boolean;
  }>;
  recommStage: RecommendationStage;
}

interface CCColumn {
  name: string;
  key: string;
  display?: boolean;
  filter?: {
    black?: boolean;
    stage: RecommendationStage;
  };
}

interface CCTalentColumn {
  name: string;
  key: string;
}

const columns: (CCColumn & Column<CCRowModel>)[] = [
  {
    name: '가입일',
    key: 'joinedAt',
    sortable: true,
    resizable: true,
    width: 100,
    cellClass: 'enable-dragging align-center',
    display: true,
  },
  {
    name: '기업명',
    key: 'companyName',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging',
    display: true,
  },
  {
    name: '이용플랜',
    key: 'plan',
    sortable: true,
    resizable: true,
    width: 100,
    cellClass: 'enable-dragging',
    display: true,
  },
  {
    name: '프로필 생성 여부',
    key: 'hasProfile',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging align-center',
    display: true,
  },
  {
    name: '산업',
    key: 'industry',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
  },
  {
    name: '채용 담당자',
    key: 'userName',
    resizable: true,
    width: 300,
    cellClass: 'enable-dragging',
    display: true,
  },
  {
    name: '인재 추천',
    key: 'numOfRecomm',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
    filter: {
      stage: RecommendationStage.RECOMM,
    },
  },
  {
    name: '프로필 평가',
    key: 'numOfProfileEval',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
    filter: {
      stage: RecommendationStage.PROFILE_EVAL,
    },
  },
  {
    name: '진행중인 의향 파악',
    key: 'numOfPendingIntents',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
  },
  {
    name: '의향 있음',
    key: 'numOfDoneIntentsYes',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
  },
  {
    name: '지금은 의향 없음',
    key: 'numOfDoneIntentsLater',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
  },
  {
    name: '의향 없음',
    key: 'numOfDoneIntentsNo',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
  },
  {
    name: '의향 응답 없음',
    key: 'numOfDoneIntentsNoResponse',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
  },
  {
    name: '면접 제안',
    key: 'numOfOffer',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
    filter: {
      stage: RecommendationStage.OFFERRED,
    },
  },
  {
    name: '일정 변경 요청',
    key: 'numOfCounterOffer',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
    filter: {
      stage: RecommendationStage.COUNTER_OFFERRED,
    },
  },
  {
    name: '면접 예정',
    key: 'numOfInterview',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
    filter: {
      stage: RecommendationStage.PENDING_INTERVIEW,
    },
  },
  {
    name: '면접 완료',
    key: 'numOfDoneInterview',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
    filter: {
      stage: RecommendationStage.DONE_INTERVIEW,
    },
  },
  {
    name: '최종 합격',
    key: 'numOfPass',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
    filter: {
      stage: RecommendationStage.PASSED,
    },
  },
  {
    name: '채용 완료',
    key: 'numOfDoneHire',
  },
  {
    name: '불합격',
    key: 'numOfFail',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
    filter: {
      black: true,
      stage: RecommendationStage.FAILED,
    },
  },
  {
    name: '종료됨',
    key: 'numOfTerm',
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging',
    display: true,
    filter: {
      black: true,
      stage: RecommendationStage.CLOSED,
    },
  },
];
const talentColumns: (CCTalentColumn & Column<CCTalentRowModel>)[] = [
  {
    name: '이름',
    key: 'name',
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging',
  },
  {
    name: '추천 직무',
    key: 'position',
    sortKeyGetter: (row) => row.recommDetail.positionDetail.position,
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging',
  },
  {
    name: '매칭 점수',
    key: 'score',
    sortKeyGetter: (row) => row.recommDetail.positionDetail.score,
    sortable: true,
    resizable: true,
    width: 100,
    cellClass: 'enable-dragging align-right',
  },
  {
    name: '추천 날짜',
    key: 'recommDate',
    sortKeyGetter: (row) => row.recommDetail.date,
    sortable: true,
    resizable: true,
    width: 100,
    cellClass: 'enable-dragging align-center',
  },
  {
    name: '프로필 평가',
    key: 'profileEval',
    sortKeyGetter: (row) =>
      (row.recommDetail.profileDetail?.firstImpression ?? 0) +
      (row.recommDetail.profileDetail?.skillSetSuitability ?? 0) +
      (row.recommDetail.profileDetail?.talentSimilarity ?? 0),
    sortable: true,
    resizable: true,
    width: 120,
    cellClass: 'enable-dragging align-center',
  },
  {
    name: '의향 파악',
    key: 'intent',
    sortKeyGetter: (row) => !!row.recommDetail.intentDetail,
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging align-center',
  },
  {
    name: '면접 제안',
    key: 'offer',
    sortKeyGetter: (row) => !!row.recommDetail.offerDetail,
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging align-center',
  },
  {
    name: '일정 변경 요청',
    key: 'counterOffer',
    sortKeyGetter: (row) => !!row.recommDetail.counterOfferDetail,
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging align-center',
  },
  {
    name: '면접 예정',
    key: 'interviewDate',
    sortKeyGetter: (row) =>
      (row.recommDetail.interviewDetail?.date.getTime() ?? 0) +
      (row.recommDetail.interviewDetail?.endTime ?? 0) * 1000 * 60,
    sortable: true,
    resizable: true,
    width: 200,
    cellClass: 'enable-dragging align-center',
  },
  {
    name: '면접 결과(통보 일자)',
    key: 'result',
    sortKeyGetter: (row) => row.recommDetail.interviewReviewDetail?.date,
    sortable: true,
    resizable: true,
    width: 150,
    cellClass: 'enable-dragging align-center',
  },
  {
    name: '채용 현황',
    key: 'status',
    sortKeyGetter: (row) => row.recommStage,
    sortable: true,
    resizable: true,
    width: 120,
    cellClass: 'enable-dragging align-center',
  },
  {
    key: 'action',
    name: '처리',
    resizable: true,
    width: 120,
    cellClass: 'align-center',
  },
];

export default {
  columns,
  talentColumns,
};
