import { styled as mstyled, Button } from '@material-ui/core';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { ViewProps } from '../props/ViewProps';
import TalentTargetRecomm from './TalentTargetRecomm';

import { TalentRowModel, TalentProfileData } from '../schemas/Talent';

import Format from '../helpers/Format';

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const PhotoSplitter = styled.div`
  margin-top: 40px;
`;
const PhotoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 32px;
  & + & {
    margin-top: 13px;
  }
  & > * + * {
    margin-left: 10px;
  }
`;
const PhotoImageLink = styled.a`
  display: flex;
`;
const PhotoImage = styled.img`
  display: inline-block;
  border-radius: 8px;
  width: 180px;
  height: 200px;
`;
const PhotoPlaceholder = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: solid 1px #dddddd;
  border-radius: 8px;
  width: 180px;
  height: 200px;
  background-color: #eeeeee;
  user-select: none;
  & + & {
    margin-left: 10px;
  }
`;
const PhotoPlaceholderText = styled.span`
  color: #999999;
  font-size: 14px;
  font-weight: normal;
`;
const ItemWrapper = styled.section`
  padding: 16px 0;
`;
const ItemTitle = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #222222;
`;
const ItemInfoIcon = styled.img`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  border-radius: 24px;
`;
const ItemInfoTitle = styled.h3`
  margin: 0;
  margin-left: 16px;
  font-size: 24px;
  font-weight: normal;
  color: #222222;
`;
const ItemContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 24px;
`;
const Splitter = styled.hr`
  margin: 32px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  & + & {
    margin-top: 16px;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  & + & {
    margin-top: 16px;
  }
`;
const Header = styled.h4`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  width: 120px;
  font-size: 14px;
  font-weight: normal;
  color: #999999;
`;
const Text = styled.p`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: #222222;
`;
const BoldText = styled(Text)`
  font-weight: bold;
`;
const LinkText = styled.a(Text);

const TargetRecommendationButton = mstyled(Button)({
  marginLeft: 'auto',
  width: '133px',
  height: '44px',
  borderRadius: '22px',
  padding: '5px 8px',
  fontSize: '14px',
  fontWeight: 'normal',
  color: 'white',
  backgroundColor: '#0c46f2',
});

function PhotoOrPlaceholder(props: { url: string | undefined }): ReactElement {
  return props.url ? (
    <PhotoImageLink href={props.url} target="_blank" rel="noreferrer">
      <PhotoImage src={props.url} />
    </PhotoImageLink>
  ) : (
    <PhotoPlaceholder>
      <PhotoPlaceholderText>등록된 사진이 없습니다</PhotoPlaceholderText>
    </PhotoPlaceholder>
  );
}

export default function TalentProfile(
  props: ViewProps & {
    token: string;
    talent: TalentRowModel;
    profile: TalentProfileData;
  },
): ReactElement {
  return (
    <>
      <ItemWrapper>
        <TitleRow>
          <ItemInfoIcon
            src={props.profile.thumbnailUrl ?? '#'}
            width="48"
            height="48"
          />
          <ItemInfoTitle>{props.profile.name}</ItemInfoTitle>
          <TargetRecommendationButton
            onClick={() =>
              props.onDisplaySideView(
                '타겟 추천',
                <TalentTargetRecomm {...props} />,
              )
            }
          >
            타겟추천
          </TargetRecommendationButton>
        </TitleRow>
        <PhotoRow>
          <PhotoOrPlaceholder url={props.profile.photoUrls[0]} />
          <PhotoOrPlaceholder url={props.profile.photoUrls[1]} />
          <PhotoOrPlaceholder url={props.profile.photoUrls[2]} />
        </PhotoRow>
        <PhotoRow>
          <PhotoOrPlaceholder url={props.profile.photoUrls[3]} />
          <PhotoOrPlaceholder url={props.profile.photoUrls[4]} />
          <PhotoOrPlaceholder url={props.profile.photoUrls[5]} />
        </PhotoRow>
        <PhotoSplitter />
        <ItemTitle>기본 정보</ItemTitle>
        <ItemContentWrapper>
          <Row>
            <Header>가입일</Header>
            <Text>
              {Format.dateFormatWithDayLong.format(props.profile.joinedAt)}
            </Text>
          </Row>
          <Row>
            <Header>생년월일</Header>
            <Text>
              {props.profile.birth
                ? Format.dateFormat.format(props.profile.birth)
                : '-'}
            </Text>
          </Row>
          <Row>
            <Header>휴대폰 번호</Header>
            <Text>{props.profile.phone ?? '-'}</Text>
          </Row>
          <Row>
            <Header>거주지 위치</Header>
            <Text>{props.profile.address}</Text>
          </Row>
          <Row>
            <Header>학력</Header>
            <Column>
              {props.profile.educations.map((education, index) => (
                <Column key={index}>
                  <BoldText>{education.name}</BoldText>
                  <Text>
                    {education.major
                      ? `${education.major} ${education.degree}`
                      : education.degree}
                  </Text>
                  <Text>
                    {Format.monthFormat.format(education.beginAt)}
                    {' - '}
                    {education.endAt
                      ? Format.monthFormat.format(education.endAt)
                      : '재학중'}
                  </Text>
                </Column>
              ))}
            </Column>
          </Row>
        </ItemContentWrapper>
        <Splitter />
        <ItemTitle>기술 및 경력 정보</ItemTitle>
        <ItemContentWrapper>
          <Row>
            <Header>주요직무</Header>
            <Text>{props.profile.mainPosition ?? '-'}</Text>
          </Row>
          <Row>
            <Header>주요직무 연차</Header>
            <Text>
              {props.profile.mainCareer
                ? `${props.profile.mainCareer}년차`
                : '-'}
            </Text>
          </Row>
          <Row>
            <Header>총 경력</Header>
            <Text>
              {props.profile.totalCareer
                ? `${props.profile.totalCareer}년`
                : '-'}
            </Text>
          </Row>
          <Row>
            <Header>보유기술</Header>
            <Column>
              {props.profile.skills.map((skill, index) => (
                <Text key={index}>
                  {skill.skill}({skill.score})
                </Text>
              ))}
            </Column>
          </Row>
          <Row>
            <Header>포트폴리오 링크</Header>
            {props.profile.portfolioUrl ? (
              <LinkText href={props.profile.portfolioUrl} target="_blank">
                {props.profile.portfolioUrl}
              </LinkText>
            ) : (
              <Text>-</Text>
            )}
          </Row>
          <Row>
            <Header>경력 및 프로젝트</Header>
            <Column>
              {props.profile.careers.map((career, index) => (
                <Column key={index}>
                  <BoldText>경력 - {career.name}</BoldText>
                  <Text>{career.position}</Text>
                  <Text>
                    {Format.monthFormat.format(career.beginAt)}
                    {' - '}
                    {career.endAt
                      ? Format.monthFormat.format(career.endAt)
                      : '현재'}
                  </Text>
                  {career.description && <Text>{career.description}</Text>}
                </Column>
              ))}
              {props.profile.projects.map((project, index) => (
                <Column key={index}>
                  <BoldText>프로젝트 - {project.name}</BoldText>
                  {(project.organization || project.team) && (
                    <Text>
                      {project.organization && project.team
                        ? `${project.organization} | ${project.team}`
                        : project.organization || project.team}
                    </Text>
                  )}
                  <Text>{project.position}</Text>
                  <Text>
                    {Format.monthFormat.format(project.beginAt)}
                    {' - '}
                    {project.endAt
                      ? Format.monthFormat.format(project.endAt)
                      : '현재'}
                  </Text>
                  {project.description && <Text>{project.description}</Text>}
                  {project.url && (
                    <LinkText href={project.url} target="_blank">
                      {project.url}
                    </LinkText>
                  )}
                </Column>
              ))}
            </Column>
          </Row>
        </ItemContentWrapper>
        <Splitter />
        <ItemTitle>희망 정보</ItemTitle>
        <ItemContentWrapper>
          <Row>
            <Header>취업 희망 직무</Header>
            <Text>{props.talent.hopePosition ?? '-'}</Text>
          </Row>
          <Row>
            <Header>희망 연봉</Header>
            <Text>
              {props.profile.hopeSalary
                ? `${props.profile.hopeSalary / 10000}만원`
                : '-'}
            </Text>
          </Row>
          <Row>
            <Header>희망 근무 지속일</Header>
            <Text>{props.profile.hopeServiceDuration ?? '-'}</Text>
          </Row>
        </ItemContentWrapper>
      </ItemWrapper>
    </>
  );
}
