import { useMemo, useState } from 'react';
import { SortDirection } from 'react-data-grid';

export function useSort<R>(
  rows: R[],
  initialSort?: [string, SortDirection, ((row: R) => unknown) | undefined][],
): [
  [string, SortDirection, ((row: R) => unknown) | undefined][],
  (sorts: [string, SortDirection, ((row: R) => unknown) | undefined][]) => void,
  R[],
] {
  const [sort, setSort] = useState<
    [string, SortDirection, ((row: R) => unknown) | undefined][]
  >(initialSort ?? []);
  const sortedRows: R[] = useMemo(() => {
    if (!sort.length) return rows;

    let sortedRows: [number, unknown[]][] = rows.map((row, index) => {
      const sortKeys = [];

      for (const [column, , sortKeyGetter] of sort)
        sortKeys.push(
          sortKeyGetter
            ? sortKeyGetter(row) ?? ''
            : (row as Record<string, unknown>)[column],
        );

      return [index, sortKeys];
    });

    for (let index = sort.length - 1; 0 <= index; --index)
      sortedRows = sortedRows.sort(
        sort[index][1] === 'ASC' ? compareAsc(index) : compareDesc(index),
      );

    return sortedRows.map(([index]) => rows[index]);
  }, [rows, sort]);

  return [sort, setSort, sortedRows];
}

function compareAsc<R extends [number, unknown[]]>(
  index: number,
): (lhs: R, rhs: R) => number {
  return (lhs, rhs) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((lhs[1][index] as any) < (rhs[1][index] as any)) return -1;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((rhs[1][index] as any) < (lhs[1][index] as any)) return 1;
    return 0;
  };
}

function compareDesc<R extends [number, unknown[]]>(
  index: number,
): (lhs: R, rhs: R) => number {
  return (lhs, rhs) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((lhs[1][index] as any) < (rhs[1][index] as any)) return 1;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((rhs[1][index] as any) < (lhs[1][index] as any)) return -1;
    return 0;
  };
}
