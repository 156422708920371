import {
  styled as mstyled,
  Button,
  TextField,
  CircularProgress,
  Backdrop,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import { ViewProps } from '../props/ViewProps';

import { TalentRowModel, TalentProfileData } from '../schemas/Talent';

import {
  generateRecommendations,
  listCandidatePositions,
  listRecommCandidates,
} from '../helpers/Api';

import IconStarOn from 'img/icon-toggle-star-on-20.svg';
import IconAgreeOn from 'img/icon-toggle-checkbox-24-black.svg';
import IconAgrreOff from 'img/icon-toggle-checkbox-24.svg';

const Wrapper = styled.section`
  padding: 16px 0;
`;
const TitleText = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #222222;
`;
const SectionTitle = styled.h3`
  margin-top: 32px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: normal;
  color: #222222;
`;
const TalentInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  border: #222222 solid 1px;
  border-radius: 8px;
`;
const TalentInfoUpperRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
`;
const TalentInfoUpperRow = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  padding: 8px 16px;
  & + & {
    border-left: #222222 solid 1px;
  }
`;
const TalentInfoRowText = styled.p`
  font-size: 14px;
  font-weight: normal;
  color: #222222;
`;
const TalentInfoRowBoldText = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: #222222;
`;
const TalentInfoRowSpacer = styled.span`
  margin: auto;
`;
const TalentInfoLowerRow = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  border-top: #222222 solid 1px;
  padding: 8px 16px;
`;
const PositionWrapper = styled.div`
  padding: 16px;
  cursor: pointer;
  & + & {
    border-top: 1px solid #dddddd;
  }
`;
const PositionText = styled.h3`
  font-size: 20px;
  font-weight: normal;
  color: #222222;
`;
const PositionScoreText = styled.p`
  margin-top: 4px;
  font-size: 16px;
  font-weight: normal;
  color: #0c46f2;
`;
const PositionBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;
`;
const PositionYearsOfExpBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  padding: 0 12px;
  height: 28px;
  font-size: 11px;
  font-weight: normal;
  color: white;
  background-color: #0c46f2;
`;
const PositionYearsOfExpBoxDisabled = styled(PositionYearsOfExpBox)`
  background-color: #dddddd;
`;
const PositionSalaryBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 8px;
  border-radius: 4px;
  padding: 0 8px;
  height: 28px;
  font-size: 11px;
  font-weight: normal;
  color: white;
  background-color: #3dda18;
`;
const PositionSalaryBoxDisabled = styled(PositionSalaryBox)`
  background-color: #dddddd;
`;
const PositionHiringIcon = styled.img`
  margin-left: auto;
`;
const PositionHiringIndicator = styled.p`
  margin-left: 5px;
  font-size: 12px;
  font-weight: normal;
  color: #222222;
`;
const ScoreWrapper = styled.div`
  margin-top: 20px;
  padding: 20px 12px 12px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  text-align: center;
`;
const ScoreTitleText = styled.p`
  font-size: 20px;
  font-weight: normal;
  color: #444444;
`;
const ScoreTitleScoreTextEnabled = styled.span`
  margin-left: 21px;
  font-size: 20px;
  font-weight: bold;
  color: #444444;
`;
const ScoreTitleScoreTextDisabled = styled.span`
  margin-left: 21px;
  font-size: 20px;
  font-weight: normal;
  color: #bbbbbb;
`;
function ScoreTitleScoreText(
  props: PropsWithChildren<{ disabled: boolean }>,
): ReactElement {
  return props.disabled ? (
    <ScoreTitleScoreTextDisabled>{props.children}</ScoreTitleScoreTextDisabled>
  ) : (
    <ScoreTitleScoreTextEnabled>{props.children}</ScoreTitleScoreTextEnabled>
  );
}
const ScoreFormula = styled.p`
  margin-top: 4px;
  font-size: 11px;
  font-weight: normal;
  color: #999999;
`;
const ScoreInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  padding: 20px 0;
  border-radius: 6px;
  background-color: #f8f8f8;
`;
const ScoreSection = styled.div`
  flex: 1 1 0;
  padding: 6px;
  & + & {
    border-left: 1px solid #dddddd;
  }
`;
const ScoreSectionUpperText = styled.p`
  font-size: 14px;
  font-weight: normal;
  color: #222222;
`;
const ScoreSectionLowerTextEnabled = styled.p`
  margin-top: 13px;
  font-size: 20px;
  font-weight: bold;
  color: #222222;
`;
const ScoreSectionLowerTextDisabled = styled.p`
  margin-top: 13px;
  font-size: 20px;
  font-weight: normal;
  color: #bbbbbb;
`;
function ScoreSectionLowerText(
  props: PropsWithChildren<{ disabled: boolean }>,
): ReactElement {
  return props.disabled ? (
    <ScoreSectionLowerTextDisabled>
      {props.children}
    </ScoreSectionLowerTextDisabled>
  ) : (
    <ScoreSectionLowerTextEnabled>
      {props.children}
    </ScoreSectionLowerTextEnabled>
  );
}
const AgreeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
  cursor: pointer;
`;
const AgreeText = styled.p`
  margin-left: 4px;
  font-size: 14px;
  font-weight: normal;
  color: #444444;
  user-select: none;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
`;
const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const ResultText = styled.p`
  font-size: 24px;
  font-weight: normal;
  color: #222222;
  text-align: center;
`;

const RecommendButton = mstyled(Button)({
  width: '133px',
  height: '44px',
  borderRadius: '22px',
  padding: '5px 8px',
  fontSize: '14px',
  fontWeight: 'normal',
  color: 'white',
  backgroundColor: '#0c46f2',
  '&:disabled': {
    color: 'white',
    backgroundColor: '#dddddd',
  },
});
const BackgroundCover = mstyled(Backdrop)({
  color: 'black',
  zIndex: 1,
});

export default function TalentTargetRecomm(
  props: ViewProps & {
    token: string;
    talent: TalentRowModel;
    profile: TalentProfileData;
  },
): ReactElement {
  const [searching, setSearching] = useState(false);
  const [searchingWaitingId, setSearchingWaitingId] = useState<number | null>(
    null,
  );
  const [options, setOptions] = useState<
    {
      id: number;
      name: string;
      logoUrl: string | undefined;
      availability: boolean;
    }[]
  >([]);
  const [ccName, setCCName] = useState('test');
  const [selectedCC, setSelectedCC] = useState<{
    id: number;
    name: string;
    logoUrl: string | undefined;
    availability: boolean;
  } | null>(null);
  const [positions, setPositions] = useState<
    {
      position: string;
      hiring: boolean;
      lowYears: number;
      highYears: number;
      lowSalary: number;
      highSalary: number;
      score: number;
      positionScore: number;
      skillScore: number;
      yearsScore: number;
      salaryScore: number;
    }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState<{
    position: string;
    hiring: boolean;
    lowYears: number;
    highYears: number;
    lowSalary: number;
    highSalary: number;
    score: number;
    positionScore: number;
    skillScore: number;
    yearsScore: number;
    salaryScore: number;
  } | null>(null);
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    setAgree(false);

    if (!positions.length) {
      props.onCloseExtendedSideView();
      return;
    }

    props.onDisplayExtendedSideView(
      <>
        {positions.map((position) => (
          <PositionWrapper
            key={position.position}
            onClick={() => setSelectedPosition(position)}
          >
            <PositionText>{position.position}</PositionText>
            <PositionScoreText>
              매치점수 {position.score} / 15
            </PositionScoreText>
            <PositionBoxRow>
              {position.yearsScore === 0 ? (
                <PositionYearsOfExpBoxDisabled>
                  {position.lowYears} ~ {position.highYears}년
                </PositionYearsOfExpBoxDisabled>
              ) : (
                <PositionYearsOfExpBox>
                  {position.lowYears} ~ {position.highYears}년
                </PositionYearsOfExpBox>
              )}
              {position.salaryScore === 0 ? (
                <PositionSalaryBoxDisabled>
                  {position.lowSalary} ~ {position.highSalary}만원
                </PositionSalaryBoxDisabled>
              ) : (
                <PositionSalaryBox>
                  {position.lowSalary} ~ {position.highSalary}만원
                </PositionSalaryBox>
              )}
              {position.hiring && (
                <>
                  <PositionHiringIcon src={IconStarOn} width="15" height="15" />
                  <PositionHiringIndicator>채용중</PositionHiringIndicator>
                </>
              )}
            </PositionBoxRow>
          </PositionWrapper>
        ))}
      </>,
    );
  }, [positions]);

  function handleOnCCNameChanged(value: string, reason: string) {
    setCCName(value);
    setSelectedCC(null);
    setPositions([]);
    setSelectedPosition(null);

    if (reason === 'reset') return;

    setSearching(true);

    if (searchingWaitingId !== null) clearTimeout(searchingWaitingId);

    setSearchingWaitingId(
      window.setTimeout(async () => {
        try {
          setSearchingWaitingId(null);
          setOptions(await listRecommCandidates(props, props.talent.id, value));
        } finally {
          setSearching(false);
        }
      }, 500),
    );
  }
  function handleOnCandidateSelected(
    value: {
      id: number;
      name: string;
      logoUrl: string | undefined;
      availability: boolean;
    } | null,
  ) {
    setSelectedCC(value);

    if (!value) return;
    if (!value.availability) return;

    (async () => {
      try {
        setLoading(true);
        setPositions(
          await listCandidatePositions(props, props.talent.id, value.id),
        );
      } finally {
        setLoading(false);
      }
    })();
  }
  function handleOnRecommend() {
    (async () => {
      try {
        await generateRecommendations(
          props,
          props.talent.id,
          selectedCC!.id,
          selectedPosition!.position,
        );
        props.onDisplaySideView(
          '타겟 추천',
          <>
            <ResultWrapper>
              <ResultText>
                {props.talent.name}님을 {selectedCC!.name}에<br />
                {selectedPosition!.position}로 추천했습니다.
              </ResultText>
            </ResultWrapper>
          </>,
        );
      } catch {
        props.onDisplaySideView(
          '타겟 추천 실패',
          <>
            <ResultWrapper>
              <ResultText>
                {props.talent.name}님을 {selectedCC!.name}에<br />
                {selectedPosition!.position}로 추천하지 못했습니다.
                <br />
                관리자에게 문의해주세요.
              </ResultText>
            </ResultWrapper>
          </>,
        );
      }
    })();
  }

  return (
    <Wrapper>
      <TitleText>
        {props.talent.name}님을 어느 기업에
        <br />
        추천하실건가요?
      </TitleText>
      <SectionTitle>인재 정보</SectionTitle>
      <TalentInfoWrapper>
        <TalentInfoUpperRowWrapper>
          <TalentInfoUpperRow>
            <TalentInfoRowText>총 경력</TalentInfoRowText>
            <TalentInfoRowSpacer />
            <TalentInfoRowBoldText>
              {props.profile.totalCareer
                ? `${props.profile.totalCareer}년`
                : '없음'}
            </TalentInfoRowBoldText>
          </TalentInfoUpperRow>
          <TalentInfoUpperRow>
            <TalentInfoRowText>희망 연봉</TalentInfoRowText>
            <TalentInfoRowSpacer />
            <TalentInfoRowBoldText>
              {props.profile.hopeSalary
                ? `${props.profile.hopeSalary / 10000}만 원`
                : '없음'}
            </TalentInfoRowBoldText>
          </TalentInfoUpperRow>
        </TalentInfoUpperRowWrapper>
        <TalentInfoLowerRow>
          <TalentInfoRowText>희망 포지션</TalentInfoRowText>
          <TalentInfoRowSpacer />
          <TalentInfoRowBoldText>
            {props.talent.hopePosition ?? '없음'}
          </TalentInfoRowBoldText>
        </TalentInfoLowerRow>
      </TalentInfoWrapper>
      <SectionTitle>추천 기업</SectionTitle>
      <Autocomplete
        options={options}
        loading={searching}
        inputValue={ccName}
        onInputChange={(_, value, reason) =>
          handleOnCCNameChanged(value, reason)
        }
        onChange={(_, value) => handleOnCandidateSelected(value)}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) =>
          `${option.name} (${
            option.availability ? '추천 가능' : '이미 추천됨'
          })`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="기업 검색"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {searching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <SectionTitle>추천 포지션</SectionTitle>
      <TextField
        variant="outlined"
        placeholder="포지션 선택"
        value={selectedPosition?.position ?? ''}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
      />
      <ScoreWrapper>
        <ScoreTitleText>
          매치점수
          <ScoreTitleScoreText disabled={!selectedPosition}>
            {selectedPosition?.score ?? 0} / 15
          </ScoreTitleScoreText>
        </ScoreTitleText>
        <ScoreFormula>(매치점수 = 포지션 * 스킬 + 경력 + 연봉)</ScoreFormula>
        <ScoreInnerWrapper>
          <ScoreSection>
            <ScoreSectionUpperText>포지션</ScoreSectionUpperText>
            <ScoreSectionLowerText disabled={!selectedPosition}>
              {selectedPosition?.positionScore ?? 0} / 1
            </ScoreSectionLowerText>
          </ScoreSection>
          <ScoreSection>
            <ScoreSectionUpperText>스킬</ScoreSectionUpperText>
            <ScoreSectionLowerText disabled={!selectedPosition}>
              {selectedPosition?.skillScore ?? 0} / 10
            </ScoreSectionLowerText>
          </ScoreSection>
          <ScoreSection>
            <ScoreSectionUpperText>경력</ScoreSectionUpperText>
            <ScoreSectionLowerText disabled={!selectedPosition}>
              {selectedPosition?.yearsScore ?? 0} / 3
            </ScoreSectionLowerText>
          </ScoreSection>
          <ScoreSection>
            <ScoreSectionUpperText>연봉</ScoreSectionUpperText>
            <ScoreSectionLowerText disabled={!selectedPosition}>
              {selectedPosition?.salaryScore ?? 0} / 1
            </ScoreSectionLowerText>
          </ScoreSection>
        </ScoreInnerWrapper>
      </ScoreWrapper>
      <AgreeWrapper
        onClick={() => setAgree((agree) => !!selectedPosition && !agree)}
      >
        <img src={agree ? IconAgreeOn : IconAgrreOff} width="24" height="24" />
        <AgreeText>모든 내용을 확인했으며, 추천하는 것에 동의합니다</AgreeText>
      </AgreeWrapper>
      <ButtonWrapper>
        <RecommendButton
          onClick={() => handleOnRecommend()}
          disabled={!selectedPosition || !agree}
        >
          추천하기
        </RecommendButton>
      </ButtonWrapper>
      <BackgroundCover open={loading}>
        <CircularProgress />
      </BackgroundCover>
    </Wrapper>
  );
}
