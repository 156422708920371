export function time2Date(time: {
  date: Date;
  startTime: number;
  endTime: number;
}): { startTime: Date; endTime: Date } {
  const today = new Date(
    time.date.getFullYear(),
    time.date.getMonth(),
    time.date.getDate(),
  );

  return {
    startTime: new Date(today.getTime() + time.startTime * 60 * 1000),
    endTime: new Date(today.getTime() + time.endTime * 60 * 1000),
  };
}
