import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  palette: {
    action: {
      disabled: 'transparent',
      disabledBackground: 'transparent',
    },
    primary: {
      main: '#0c46f2',
    },
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
});
