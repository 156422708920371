import React, { ReactElement, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Dashboard from './pages/Dashboard';
import Login from './pages/Login';

function App(): ReactElement {
  const [email, setEmail] = useState<string>(
    localStorage.getItem('admin.email') ?? '',
  );
  const [token, setToken] = useState<string>(
    localStorage.getItem('admin.token') ?? '',
  );

  const clearToken = (): void => {
    setEmail('');
    setToken('');
  };

  useEffect(() => {
    localStorage.setItem('admin.email', email);
    localStorage.setItem('admin.token', token);
  }, [email, token]);

  return (
    <Router>
      <Switch>
        <Route
          render={(props) => (
            <Login {...props} setEmail={setEmail} setToken={setToken} />
          )}
          path="/login"
          exact
        />
        <Route
          render={(props) => (
            <Dashboard
              {...props}
              email={email}
              token={token}
              clearToken={clearToken}
            />
          )}
          path="/:type(settings|applications|ccs|talents)?"
        />
      </Switch>
    </Router>
  );
}

export default App;
