import { styled as mstyled, Button } from '@material-ui/core';
import React, { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  border: 1px solid #444444;
  height: 100%;
  background-color: white;
`;
const TitleWrapper = styled.div`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 8px 0 16px;
  height: 52px;
  background-color: #444444;
`;
const TitleText = styled.h2`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  color: white;
`;
const Spacer = styled.span`
  margin: 0 auto;
`;
const ContentWrapper = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 20px 16px;
  overflow: auto;
`;

const CloseButton = mstyled(Button)({
  padding: '4px 14px',
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#666666',
});

export default function FloatView(
  props: PropsWithChildren<{
    title: string;
    onClose(): void;
  }>,
): ReactElement {
  return (
    <Wrapper>
      <TitleWrapper>
        <TitleText>{props.title}</TitleText>
        <Spacer />
        <CloseButton onClick={props.onClose}>CLOSE</CloseButton>
      </TitleWrapper>
      <ContentWrapper>{props.children}</ContentWrapper>
    </Wrapper>
  );
}
