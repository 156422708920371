import {
  styled as mstyled,
  Backdrop,
  Button,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import classNames from 'classnames';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { generateApiUrl } from '../helpers/Api';

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  height: 100%;
`;
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0 16px;
  width: 100%;
`;
const TitleText = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #222222;
`;
const SubTitleText = styled.p`
  margin: 0;
  margin-top: 8px;
  font-size: 16px;
  font-weight: normal;
  color: #999999;
`;
const ErrorText = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: normal;
  color: #999999;
  visibility: hidden;

  &.error {
    visibility: initial;
  }
`;

const BackgroundCover = mstyled(Backdrop)({
  color: 'black',
  zIndex: 1,
});
const FormInput = mstyled(FormControl)({
  width: '100%',
  maxWidth: '336px',
  '& + &': {
    marginTop: '16px',
  },
});
const LoginButton = mstyled(Button)({
  marginTop: '16px',
  width: '100%',
  maxWidth: '336px',
  height: '56px',
  fontSize: '14px',
  fontWeight: 'normal',
  color: 'white',
});

export default withRouter(function Login(
  props: RouteComponentProps & {
    setEmail(token: string): void;
    setToken(token: string): void;
  },
): ReactElement {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handleClickShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };
  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const handleClickLoginButton = async () => {
    setLoading(true);
    setHasError(false);

    const response = await fetch(generateApiUrl('/admin/sessions'), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        pw: password,
      }),
    });

    if (response.status !== 201) {
      setLoading(false);
      setHasError(true);
      return;
    }

    props.setEmail(email);
    props.setToken(await response.text());
    props.history.push('/');
  };
  // const handleClickLoginButton = () => {
  //   (async () => {
  //     setLoading(true);
  //     setHasError(false);

  //     const response = await fetch(generateApiUrl('/admin/sessions'), {
  //       method: 'POST',
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         email,
  //         pw: password,
  //       }),
  //     });

  //     if (response.status !== 201) {
  //       setLoading(false);
  //       setHasError(true);
  //       return;
  //     }

  //     props.setEmail(email);
  //     props.setToken(await response.text());
  //     props.history.push('/');
  //   })();
  // };

  return (
    <>
      <Wrapper>
        <TitleText>탤런트엑스 어드민</TitleText>
        <SubTitleText>이메일과 비밀번호를 입력하세요.</SubTitleText>
        <InnerWrapper>
          <FormInput variant="outlined">
            <InputLabel htmlFor="email">이메일</InputLabel>
            <OutlinedInput
              id="email"
              label="이메일"
              type="text"
              onChange={handleChangeEmail}
            />
          </FormInput>
          <FormInput variant="outlined">
            <InputLabel htmlFor="password">비밀번호</InputLabel>
            <OutlinedInput
              id="password"
              label="비밀번호"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              onChange={handleChangePassword}
            />
          </FormInput>
          <LoginButton
            variant="contained"
            color="primary"
            onClick={handleClickLoginButton}
          >
            로그인
          </LoginButton>
        </InnerWrapper>
        <ErrorText
          className={classNames({
            error: hasError,
          })}
        >
          올바르지 않은 계정 정보입니다.
        </ErrorText>
      </Wrapper>
      <BackgroundCover open={loading}>
        <CircularProgress />
      </BackgroundCover>
    </>
  );
});
