import { styled as mstyled, Button } from '@material-ui/core';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Format from '../helpers/Format';

import { ViewProps } from '../props/ViewProps';

import { CCProfileData } from '../schemas/CC';

const PhotoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  & + & {
    margin-top: 8px;
  }
`;
const PhotoImage = styled.img`
  display: inline-block;
  & + & {
    margin-left: 8px;
  }
`;
const ItemWrapper = styled.section`
  padding: 32px 0;
`;
const ItemTitle = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #222222;
`;
const ItemInfoBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 24px;
`;
const ItemInfoIcon = styled.img`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
`;
const ItemInfoTitle = styled.h3`
  margin: 0;
  margin-left: 16px;
  font-size: 24px;
  font-weight: bold;
  color: #222222;
`;
const ItemContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 24px;
`;
const Splitter = styled.hr`
  margin: 32px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  & + & {
    margin-top: 16px;
  }
`;
const Header = styled.h4`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  width: 120px;
  font-size: 14px;
  font-weight: normal;
  color: #999999;
`;
const Text = styled.p`
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: #222222;
`;

const ItemInfoImageButton = mstyled(Button)({
  marginLeft: 'auto',
  padding: '5px 8px',
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#444444',
});

export default function CCProfile(
  props: ViewProps & { profile: CCProfileData },
): ReactElement {
  const handleClickPhotoOpen = (): void => {
    props.onDisplaySideView(
      '기업 소개 이미지',
      <>
        <PhotoRow>
          <PhotoImage
            src={props.profile.companyPhotoUrls[0] ?? ''}
            width="276"
            height="172"
          />
          <PhotoImage
            src={props.profile.companyPhotoUrls[1] ?? ''}
            width="276"
            height="172"
          />
        </PhotoRow>
        <PhotoRow>
          <PhotoImage
            src={props.profile.companyPhotoUrls[2] ?? ''}
            width="276"
            height="172"
          />
          <PhotoImage
            src={props.profile.companyPhotoUrls[3] ?? ''}
            width="276"
            height="172"
          />
        </PhotoRow>
        <PhotoRow>
          <PhotoImage
            src={props.profile.companyPhotoUrls[4] ?? ''}
            width="276"
            height="172"
          />
          <PhotoImage
            src={props.profile.companyPhotoUrls[5] ?? ''}
            width="276"
            height="172"
          />
        </PhotoRow>
      </>,
    );
  };

  return (
    <>
      <ItemWrapper>
        <ItemTitle>기업 정보</ItemTitle>
        <ItemInfoBarWrapper>
          <ItemInfoIcon
            src={props.profile.companyLogoUrl ?? '#'}
            width="48"
            height="48"
          />
          <ItemInfoTitle>{props.profile.name}</ItemInfoTitle>
          {props.profile.companyPhotoUrls.length && (
            <ItemInfoImageButton
              variant="outlined"
              onClick={handleClickPhotoOpen}
            >
              기업 소개 이미지
            </ItemInfoImageButton>
          )}
        </ItemInfoBarWrapper>
        <ItemContentWrapper>
          <Row>
            <Header>가입일</Header>
            <Text>
              {Format.dateFormatWithDayLong.format(props.profile.joinedAt)}
            </Text>
          </Row>
          <Row>
            <Header>산업</Header>
            <Text>{props.profile.industry ?? '-'}</Text>
          </Row>
          <Row>
            <Header>대표</Header>
            <Text>{props.profile.ceo ?? '-'}</Text>
          </Row>
          <Row>
            <Header>채용 담당자</Header>
            <Text>
              {props.profile.users.length
                ? props.profile.users
                    .map((user) => `${user.name}(${user.phone})`)
                    .join(', ')
                : '-'}
            </Text>
          </Row>
          <Row>
            <Header>홈페이지</Header>
            <Text>{props.profile.url ?? '-'}</Text>
          </Row>
          <Row>
            <Header>기업규모</Header>
            <Text>{props.profile.scale ?? '-'}</Text>
          </Row>
          <Row>
            <Header>기업 슬로건</Header>
            <Text>{props.profile.slogan ?? '-'}</Text>
          </Row>
          <Row>
            <Header>기업 소개</Header>
            <Text>{props.profile.description ?? '-'}</Text>
          </Row>
          <Row>
            <Header>기업 설립연도</Header>
            <Text>
              {props.profile.since
                ? String(props.profile.since.getFullYear()).padStart(4, '0')
                : '-'}
            </Text>
          </Row>
          <Row>
            <Header>전체 사원 수</Header>
            <Text>{props.profile.numOfEmployees ?? '-'}</Text>
          </Row>
          <Row>
            <Header>회사 주소</Header>
            <Text>{props.profile.address ?? '-'}</Text>
          </Row>
        </ItemContentWrapper>
        {props.profile.services.length && (
          <>
            <Splitter />
            <ItemTitle>서비스 정보</ItemTitle>
            {props.profile.services.map((service, index) => (
              <React.Fragment key={index}>
                <ItemInfoBarWrapper>
                  <ItemInfoIcon
                    src={service.serviceLogoUrl ?? '#'}
                    width="48"
                    height="48"
                  />
                </ItemInfoBarWrapper>
                <ItemContentWrapper>
                  <Row>
                    <Header>서비스명</Header>
                    <Text>{service.name}</Text>
                  </Row>
                  <Row>
                    <Header>서비스 설명</Header>
                    <Text>{service.description ?? '-'}</Text>
                  </Row>
                </ItemContentWrapper>
              </React.Fragment>
            ))}
          </>
        )}
      </ItemWrapper>
    </>
  );
}
