import {
  buttonStyles,
  ItemLowerWrapper,
  ItemPositionListSpacer,
  ItemPositionListText,
  ItemPositionText,
  ItemPositionWrapper,
  ItemProfileBigText,
  ItemProfileBigTextSpacer,
  ItemProfileSmallText,
  ItemProfileSpacer,
  ItemProfileWrapper,
  ItemScoreBigBlueText,
  ItemScoreBigText,
  ItemScoreMainWrapper,
  ItemScoreSectionBigText,
  ItemScoreSectionSmallText,
  ItemScoreSectionWrapper,
  ItemScoreSpacer,
  ItemScoreWrapper,
  ItemSelectSpacer,
  ItemSelectText,
  ItemSelectTextSpacer,
  ItemSelectWrapper,
  ItemThumbnail,
  ItemThumbnailPlaceholder,
  ItemThumbnailSpacer,
  ItemUpperWrapper,
  ItemWrapper,
  SelectedItemWrapper,
} from './styles';

import { Button } from '@material-ui/core';

import React, { ReactElement } from 'react';

import { VirtualRecommendation } from '../../schemas/VirtualRecommendation';

export function Item(props: {
  index: number;
  selected: boolean;
  item: VirtualRecommendation;
  onToggle(index: number): void;
}): ReactElement {
  const buttonClasses = buttonStyles();

  const Wrapper = props.selected ? SelectedItemWrapper : ItemWrapper;

  return (
    <Wrapper>
      <ItemUpperWrapper>
        <a
          href={`/talents/${props.item.profile.user.id}`}
          target="_blank"
          rel="noreferrer"
        >
          {props.item.profile.thumbnail ? (
            <ItemThumbnail
              src={props.item.profile.thumbnail?.url}
              width="52"
              height="52"
            />
          ) : (
            <ItemThumbnailPlaceholder />
          )}
        </a>
        <ItemThumbnailSpacer />
        <ItemProfileWrapper>
          <div>
            <a
              href={`/talents/${props.item.profile.user.id}`}
              target="_blank"
              rel="noreferrer"
            >
              <ItemProfileBigText>{props.item.profile.name}</ItemProfileBigText>
            </a>
            <ItemProfileBigTextSpacer />
            <ItemProfileBigText>
              {props.item.talentHopePosition} 희망
            </ItemProfileBigText>
          </div>
          <div>
            <ItemProfileSmallText>
              {props.item.profile.mainPosition} {props.item.profile.mainCareer}{' '}
              (총 경력 {props.item.profile.totalCareer})
            </ItemProfileSmallText>
          </div>
        </ItemProfileWrapper>
        <ItemProfileSpacer />
        <ItemScoreWrapper>
          <ItemScoreMainWrapper>
            <ItemScoreBigText>매치점수</ItemScoreBigText>
            <ItemScoreBigBlueText>
              {Math.round(props.item.score * 10) / 10} / 19
            </ItemScoreBigBlueText>
          </ItemScoreMainWrapper>
          <ItemScoreSpacer />
          <ItemScoreSectionWrapper>
            <ItemScoreSectionBigText>포지션</ItemScoreSectionBigText>
            <ItemScoreSectionSmallText>
              {Math.round((props.item.jobPositionSuitability / 100) * 10) / 10}{' '}
              / 1
            </ItemScoreSectionSmallText>
          </ItemScoreSectionWrapper>
          <ItemScoreSectionWrapper>
            <ItemScoreSectionBigText>스킬</ItemScoreSectionBigText>
            <ItemScoreSectionSmallText>
              {Math.round(
                ((props.item.score -
                  props.item.yearsFitTerm -
                  props.item.salaryFitTerm) /
                  (props.item.jobPositionSuitability / 100)) *
                  10,
              ) / 10}{' '}
              / 10
            </ItemScoreSectionSmallText>
          </ItemScoreSectionWrapper>
          <ItemScoreSectionWrapper>
            <ItemScoreSectionBigText>경력</ItemScoreSectionBigText>
            <ItemScoreSectionSmallText>
              {Math.round(props.item.yearsFitTerm * 10) / 10} / 6
            </ItemScoreSectionSmallText>
          </ItemScoreSectionWrapper>
          <ItemScoreSectionWrapper>
            <ItemScoreSectionBigText>연봉</ItemScoreSectionBigText>
            <ItemScoreSectionSmallText>
              {Math.round(props.item.salaryFitTerm * 10) / 10} / 3
            </ItemScoreSectionSmallText>
          </ItemScoreSectionWrapper>
        </ItemScoreWrapper>
        <ItemSelectSpacer />
        <ItemSelectWrapper>
          <ItemSelectText>{props.item.ccHopePosition} 추천</ItemSelectText>
          <ItemSelectTextSpacer />
          <Button
            className={buttonClasses.selectItem}
            variant="contained"
            color="primary"
            onClick={() => props.onToggle(props.index)}
          >
            선택
          </Button>
        </ItemSelectWrapper>
      </ItemUpperWrapper>
      <ItemLowerWrapper>
        <ItemPositionListText>보유기술</ItemPositionListText>
        <ItemPositionListSpacer />
        <ItemPositionWrapper>
          {props.item.profile.hardSkills.map((skill, index) => (
            <ItemPositionText
              key={`${props.index}-${index}-${skill.skill.name}`}
            >
              {skill.skill.name}({skill.rate})
            </ItemPositionText>
          ))}
        </ItemPositionWrapper>
      </ItemLowerWrapper>
    </Wrapper>
  );
}
