/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouteComponentProps } from 'react-router-dom';

import {
  CCApplicationRejectReason,
  CCApplicationRowModel,
  TalentApplicationRejectReason,
  TalentApplicationRowModel,
} from '../schemas/Application';
import { CCRowModel, CCProfileData, CCTalentRowModel } from '../schemas/CC';
import { classifyRecommendationStage } from '../schemas/Recommendation';
import {
  TalentRowModel,
  TalentProfileData,
  TalentCCRowModel,
} from '../schemas/Talent';

import pageCache from './PageCache';
import { joinRoute } from './Route';

const HOST = 'https://api.talentx.devision.co.kr';
//const HOST = 'https://api.talentx.talentinum.com';
//const HOST = 'https://api-dev.talentx.talentinum.com';
//const HOST = 'http://localhost:8000';

export const TARGET_SERVER_SEARCH_INDEX_PREFIX =
  HOST === 'https://api.talentx.devision.co.kr' ? 'prod' : 'dev';

export function generateApiUrl(path: string): string {
  return `${HOST}${path}`;
}

export async function fetchCCs(
  props: RouteComponentProps & { token: string },
  setLoading: (loading: boolean) => void,
  purgeCache: boolean,
): Promise<CCRowModel[]> {
  const page = props.match.url;

  if (!purgeCache) {
    const cache = pageCache.get(page, 'ccs');
    if (cache) return cache as CCRowModel[];
  }

  setLoading(true);

  try {
    const response = await fetch(generateApiUrl('/admin/ccs'), {
      method: 'GET',
      headers: {
        'Api-Token': props.token,
      },
    });

    if (response.status !== 200) throw response;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ccs = ((await response.json()) as any[]).map((row, index) => ({
      id: row.id,
      index,
      companyName: row.name,
      plan: row.plan,
      hasProfile: row.hasProfile,
      industry: row.industry,
      users: row.users,
      numOfRecomm: row.counts.recommendations,
      numOfProfileEval: row.counts.profileEvals,
      numOfPendingIntents: row.counts.pendingIntents,
      numOfDoneIntentsYes: row.counts.doneIntentsYes,
      numOfDoneIntentsLater: row.counts.doneIntentsLater,
      numOfDoneIntentsNo: row.counts.doneIntentsNo,
      numOfDoneIntentsNoResponse: row.counts.doneIntentsNoResponse,
      numOfOffer: row.counts.offers,
      numOfCounterOffer: row.counts.counterOffers,
      numOfInterview: row.counts.pendingSchedules,
      numOfDoneInterview: row.counts.doneSchedules,
      numOfPass: row.counts.passedResults,
      numOfDoneHire: 0,
      numOfFail: row.counts.failedResults,
      numOfTerm: row.counts.closed,
      targets: row.targets,
      joinedAt: new Date(row.joinedAt),
    }));

    pageCache.set(page, 'ccs', ccs);

    for (const cc of ccs)
      pageCache.set(joinRoute(props.match.url, `${cc.id}`), 'basic', cc);

    return ccs;
  } finally {
    setLoading(false);
  }
}

export async function queryCCs(
  props: { token: string },
  ids: number[],
): Promise<CCRowModel[]> {
  const response = await fetch(generateApiUrl('/admin/ccs/query'), {
    method: 'POST',
    headers: {
      'Api-Token': props.token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ids,
    }),
  });

  if (response.status !== 201) throw response;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ccs = ((await response.json()) as any[]).map((row, index) => ({
    id: row.id,
    index,
    companyName: row.name,
    plan: row.plan,
    hasProfile: row.hasProfile,
    industry: row.industry,
    users: row.users,
    numOfRecomm: row.counts.recommendations,
    numOfProfileEval: row.counts.profileEvals,
    numOfPendingIntents: row.counts.pendingIntents,
    numOfDoneIntentsYes: row.counts.doneIntentsYes,
    numOfDoneIntentsLater: row.counts.doneIntentsLater,
    numOfDoneIntentsNo: row.counts.doneIntentsNo,
    numOfDoneIntentsNoResponse: row.counts.doneIntentsNoResponse,
    numOfOffer: row.counts.offers,
    numOfCounterOffer: row.counts.counterOffers,
    numOfInterview: row.counts.pendingSchedules,
    numOfDoneInterview: row.counts.doneSchedules,
    numOfPass: row.counts.passedResults,
    numOfDoneHire: 0,
    numOfFail: row.counts.failedResults,
    numOfTerm: row.counts.closed,
    targets: row.targets,
    joinedAt: new Date(row.joinedAt),
  }));

  return ccs;
}

export async function fetchTalents(
  props: RouteComponentProps & { token: string },
  setLoading: (loading: boolean) => void,
  purgeCache: boolean,
): Promise<TalentRowModel[]> {
  const page = props.match.url;

  if (!purgeCache) {
    const cache = pageCache.get(page, 'talents');
    if (cache) return cache as TalentRowModel[];
  }

  setLoading(true);

  try {
    const response = await fetch(generateApiUrl('/admin/talents'), {
      method: 'GET',
      headers: {
        'Api-Token': props.token,
      },
    });

    if (response.status !== 200) throw response;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const talents = ((await response.json()) as any[]).map((row, index) => ({
      id: row.id,
      index,
      name: row.name,
      membership: row.membership,
      email: row.email,
      phone: row.phone,
      recommendationEnabled: row.recommendationEnabled,
      allowedForRecommendation: row.allowedForRecommendation,
      notifyMarketingRelated: row.notifyMarketingRelated,
      hopePosition: row.positions.length ? row.positions[0] : '-',
      numOfRecomm: row.counts.recommendations,
      numOfProfileEval: row.counts.profileEvals,
      numOfPendingIntents: row.counts.pendingIntents,
      numOfDoneIntentsYes: row.counts.doneIntentsYes,
      numOfDoneIntentsLater: row.counts.doneIntentsLater,
      numOfDoneIntentsNo: row.counts.doneIntentsNo,
      numOfDoneIntentsNoResponse: row.counts.doneIntentsNoResponse,
      numOfOffer: row.counts.offers,
      numOfCounterOffer: row.counts.counterOffers,
      numOfInterview: row.counts.pendingSchedules,
      numOfDoneInterview: row.counts.doneSchedules,
      numOfPass: row.counts.passedResults,
      numOfDoneHire: 0,
      numOfFail: row.counts.failedResults,
      numOfTerm: row.counts.closed,
      numOfHardSkills: row.extraCounts.hardSkills,
      numOfCareers: row.extraCounts.careers,
      numOfProjects: row.extraCounts.projects,
      numOfEducations: row.extraCounts.educations,
      lastProfileUpdatedAt: new Date(row.lastProfileUpdatedAt),
      positions: row.positions,
      joinedAt: new Date(row.joinedAt),
    }));

    pageCache.set(page, 'talents', talents);

    for (const talent of talents)
      pageCache.set(
        joinRoute(props.match.url, `${talent.id}`),
        'basic',
        talent,
      );

    return talents;
  } finally {
    setLoading(false);
  }
}

export async function queryTalents(
  props: { token: string },
  ids: number[],
): Promise<TalentRowModel[]> {
  const response = await fetch(generateApiUrl('/admin/talents/query'), {
    method: 'POST',
    headers: {
      'Api-Token': props.token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ids,
    }),
  });

  if (response.status !== 201) throw response;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const talents = ((await response.json()) as any[]).map((row, index) => ({
    id: row.id,
    index,
    name: row.name,
    membership: row.membership,
    email: row.email,
    phone: row.phone,
    recommendationEnabled: row.recommendationEnabled,
    allowedForRecommendation: row.allowedForRecommendation,
    notifyMarketingRelated: row.notifyMarketingRelated,
    hopePosition: row.positions.length ? row.positions[0] : '-',
    numOfRecomm: row.counts.recommendations,
    numOfProfileEval: row.counts.profileEvals,
    numOfPendingIntents: row.counts.pendingIntents,
    numOfDoneIntentsYes: row.counts.doneIntentsYes,
    numOfDoneIntentsLater: row.counts.doneIntentsLater,
    numOfDoneIntentsNo: row.counts.doneIntentsNo,
    numOfDoneIntentsNoResponse: row.counts.doneIntentsNoResponse,
    numOfOffer: row.counts.offers,
    numOfCounterOffer: row.counts.counterOffers,
    numOfInterview: row.counts.pendingSchedules,
    numOfDoneInterview: row.counts.doneSchedules,
    numOfPass: row.counts.passedResults,
    numOfDoneHire: 0,
    numOfFail: row.counts.failedResults,
    numOfTerm: row.counts.closed,
    numOfHardSkills: row.extraCounts.hardSkills,
    numOfCareers: row.extraCounts.careers,
    numOfProjects: row.extraCounts.projects,
    numOfEducations: row.extraCounts.educations,
    lastProfileUpdatedAt: new Date(row.lastProfileUpdatedAt),
    positions: row.positions,
    joinedAt: new Date(row.joinedAt),
  }));

  return talents;
}

export async function fetchCC(
  props: RouteComponentProps & { token: string },
  id: string,
  setLoading: (loading: boolean) => void,
  purgeCache: boolean,
): Promise<CCRowModel> {
  const page = props.match.url;

  if (!purgeCache) {
    const cache = pageCache.get(page, 'basic');
    if (cache) return cache as CCRowModel;
  }

  setLoading(true);

  try {
    const response = await fetch(generateApiUrl(`/admin/ccs/${id}`), {
      method: 'GET',
      headers: {
        'Api-Token': props.token,
      },
    });

    if (response.status !== 200) throw response;

    const result = await response.json();
    const cc = {
      id: result.id,
      index: 0,
      companyName: result.name,
      hasProfile: result.hasProfile,
      industry: result.industry,
      users: result.users,
      numOfRecomm: result.counts.recommendations,
      numOfProfileEval: result.counts.profileEvals,
      numOfPendingIntents: result.counts.pendingIntents,
      numOfDoneIntentsYes: result.counts.doneIntentsYes,
      numOfDoneIntentsLater: result.counts.doneIntentsLater,
      numOfDoneIntentsNo: result.counts.doneIntentsNo,
      numOfDoneIntentsNoResponse: result.counts.doneIntentsNoResponse,
      numOfOffer: result.counts.offers,
      numOfCounterOffer: result.counts.counterOffers,
      numOfInterview: result.counts.pendingSchedules,
      numOfDoneInterview: result.counts.doneSchedules,
      numOfPass: result.counts.passedResults,
      numOfDoneHire: 0,
      numOfFail: result.counts.failedResults,
      numOfTerm: result.counts.closed,
      targets: result.targets,
      joinedAt: new Date(result.joinedAt),
    };

    pageCache.set(page, 'basic', cc);

    return cc;
  } finally {
    setLoading(false);
  }
}

export async function fetchTalent(
  props: RouteComponentProps & { token: string },
  id: string,
  setLoading: (loading: boolean) => void,
  purgeCache: boolean,
): Promise<TalentRowModel> {
  const page = props.match.url;

  if (!purgeCache) {
    const cache = pageCache.get(page, 'basic');
    if (cache) return cache as TalentRowModel;
  }

  setLoading(true);

  try {
    const response = await fetch(generateApiUrl(`/admin/talents/${id}`), {
      method: 'GET',
      headers: {
        'Api-Token': props.token,
      },
    });

    if (response.status !== 200) throw response;

    const result = await response.json();
    const talent = {
      id: result.id,
      index: 0,
      name: result.name,
      membership: result.membership,
      email: result.email,
      phone: result.phone,
      recommendationEnabled: result.recommendationEnabled,
      allowedForRecommendation: result.allowedForRecommendation,
      notifyMarketingRelated: result.notifyMarketingRelated,
      hopePosition: result.positions.length ? result.positions[0] : '-',
      numOfRecomm: result.counts.recommendations,
      numOfProfileEval: result.counts.profileEvals,
      numOfPendingIntents: result.counts.pendingIntents,
      numOfDoneIntentsYes: result.counts.doneIntentsYes,
      numOfDoneIntentsLater: result.counts.doneIntentsLater,
      numOfDoneIntentsNo: result.counts.doneIntentsNo,
      numOfDoneIntentsNoResponse: result.counts.doneIntentsNoResponse,
      numOfOffer: result.counts.offers,
      numOfCounterOffer: result.counts.counterOffers,
      numOfInterview: result.counts.pendingSchedules,
      numOfDoneInterview: result.counts.doneSchedules,
      numOfPass: result.counts.passedResults,
      numOfDoneHire: 0,
      numOfFail: result.counts.failedResults,
      numOfTerm: result.counts.closed,
      numOfHardSkills: result.extraCounts.hardSkills,
      numOfCareers: result.extraCounts.careers,
      numOfProjects: result.extraCounts.projects,
      numOfEducations: result.extraCounts.educations,
      lastProfileUpdatedAt: new Date(result.lastProfileUpdatedAt),
      positions: result.positions,
      joinedAt: new Date(result.joinedAt),
    };

    pageCache.set(page, 'basic', talent);

    return talent;
  } finally {
    setLoading(false);
  }
}

export async function deleteTalent(
  props: RouteComponentProps & { token: string },
  id: string,
  setLoading: (loading: boolean) => void,
): Promise<void> {
  pageCache.purge();

  setLoading(true);

  try {
    const response = await fetch(generateApiUrl(`/admin/talents/${id}`), {
      method: 'DELETE',
      headers: {
        'Api-Token': props.token,
      },
    });

    if (response.status !== 200) throw response;
  } finally {
    setLoading(false);
  }

  window.location.href = '/';
}

export async function emailWebTalentProfileLink(
  props: RouteComponentProps & { token: string },
  id: string,
  setLoading: (loading: boolean) => void,
): Promise<void> {
  pageCache.purge();

  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl(`/admin/mail/talent-profile-builder/talent/${id}`),
      {
        method: 'POST',
        headers: {
          'Api-Token': props.token,
        },
      },
    );

    if (response.status !== 201) throw response;
  } finally {
    setLoading(false);
  }
}

export async function getTalentUserToken(
  props: RouteComponentProps & { token: string },
  id: string,
  setLoading: (loading: boolean) => void,
): Promise<any> {
  pageCache.purge();

  setLoading(true);

  try {
    const response = await fetch(generateApiUrl(`/admin/talent/${id}/token`), {
      method: 'GET',
      headers: {
        'Api-Token': props.token,
      },
    });

    if (response.status !== 200) throw response;

    return response.json();
  } finally {
    setLoading(false);
  }
}

export async function fetchCCProfile(
  props: RouteComponentProps & { token: string },
  id: string,
  setLoading: (loading: boolean) => void,
  purgeCache: boolean,
): Promise<CCProfileData> {
  const page = props.match.url;

  if (!purgeCache) {
    const cache = pageCache.get(page, 'profile');
    if (cache) return cache as CCProfileData;
  }

  setLoading(true);

  try {
    const response = await fetch(generateApiUrl(`/admin/ccs/${id}/profile`), {
      method: 'GET',
      headers: {
        'Api-Token': props.token,
      },
    });

    if (response.status !== 200) throw response;

    const result = await response.json();
    const profile = {
      id: result.id,
      name: result.name,
      companyLogoUrl: result.logoUrl,
      companyPhotoUrls: result.photoUrls,
      industry: result.industry,
      ceo: result.ceo,
      users: result.users,
      url: result.url,
      scale: result.scale,
      slogan: result.slogan,
      description: result.description,
      since: new Date(result.since),
      numOfEmployees: result.numOfEmployees,
      address: result.address,
      services: [
        {
          serviceLogoUrl: result.serviceLogoUrl,
          name: result.serviceName,
          description: result.serviceDescription,
        },
      ],
      joinedAt: new Date(result.joinedAt),
    };

    pageCache.set(page, 'profile', profile);

    return profile;
  } finally {
    setLoading(false);
  }
}

export async function fetchTalentProfile(
  props: RouteComponentProps & { token: string },
  id: string,
  setLoading: (loading: boolean) => void,
  purgeCache: boolean,
): Promise<TalentProfileData> {
  const page = props.match.url;

  if (!purgeCache) {
    const cache = pageCache.get(page, 'profile');
    if (cache) return cache as TalentProfileData;
  }

  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl(`/admin/talents/${id}/profile`),
      {
        method: 'GET',
        headers: {
          'Api-Token': props.token,
        },
      },
    );

    if (response.status !== 200) throw response;

    const result = await response.json();
    const profile = {
      name: result.name,
      thumbnailUrl: result.thumbnailUrl,
      photoUrls: result.photoUrls,
      birth: new Date(result.birth),
      phone: result.phone,
      address: result.address,
      educations: result.educations.map((education: any) => {
        if (education.beginAt) education.beginAt = new Date(education.beginAt);
        if (education.endAt) education.endAt = new Date(education.endAt);
        return education;
      }),
      mainPosition: result.mainPosition,
      mainCareer: result.mainCareer,
      totalCareer: result.totalCareer,
      skills: result.skills,
      portfolioUrl: result.portfolioUrl,
      careers: result.careers.map((career: any) => {
        if (career.beginAt) career.beginAt = new Date(career.beginAt);
        if (career.endAt) career.endAt = new Date(career.endAt);
        return career;
      }),
      projects: result.projects.map((project: any) => {
        if (project.beginAt) project.beginAt = new Date(project.beginAt);
        if (project.endAt) project.endAt = new Date(project.endAt);
        return project;
      }),
      hopeSalary: result.hopeSalary,
      hopeServiceDuration: result.hopeServiceDuration,
      joinedAt: new Date(result.joinedAt),
    };

    pageCache.set(page, 'profile', profile);

    return profile;
  } finally {
    setLoading(false);
  }
}

export async function fetchCCRecommendations(
  props: RouteComponentProps & { token: string },
  id: string,
  setLoading: (loading: boolean) => void,
  purgeCache: boolean,
): Promise<CCTalentRowModel[]> {
  const page = props.match.url;

  if (!purgeCache) {
    const cache = pageCache.get(page, 'recommendations');
    if (cache) return cache as CCTalentRowModel[];
  }

  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl(`/admin/ccs/${id}/recommendations`),
      {
        method: 'GET',
        headers: {
          'Api-Token': props.token,
        },
      },
    );

    if (response.status !== 200) throw response;

    const recommendations = ((await response.json()) as any[]).map(
      (row, index) => {
        const recomm = {
          date: new Date(row.date),
          closed: row.closed,
          positionDetail: {
            position: row.position,
            score: row.score,
          },
          profileDetail:
            Number.isInteger(row.firstImpression) &&
            Number.isInteger(row.skillSetSuitability) &&
            Number.isInteger(row.talentSimilarity)
              ? {
                  firstImpression: row.firstImpression,
                  skillSetSuitability: row.skillSetSuitability,
                  talentSimilarity: row.talentSimilarity,
                }
              : undefined,
          intentDetail: row.intentDetail
            ? {
                ...row.intentDetail,
                date: new Date(row.intentDetail.date),
              }
            : undefined,
          offerDetail: row.offerDetail
            ? {
                ...row.offerDetail,
                date: new Date(row.offerDetail.date),
                times: row.offerDetail.times.map((time: { date: string }) => ({
                  ...time,
                  date: new Date(time.date),
                })),
              }
            : undefined,
          counterOfferDetail: row.counterOfferDetail
            ? {
                ...row.counterOfferDetail,
                date: new Date(row.counterOfferDetail.date),
                times: row.counterOfferDetail.times.map(
                  (time: { date: string }) => ({
                    ...time,
                    date: new Date(time.date),
                  }),
                ),
              }
            : undefined,
          interviewDetail: row.interviewDetail
            ? {
                ...row.interviewDetail,
                date: new Date(row.interviewDetail.date),
              }
            : undefined,
          interviewReviewDetail: row.interviewReviewDetail
            ? {
                ...row.interviewReviewDetail,
                date: new Date(row.interviewReviewDetail.date),
              }
            : undefined,
          resultDetail: row.resultDetail
            ? {
                ...row.resultDetail,
                date: new Date(row.resultDetail.date),
              }
            : undefined,
        };

        return {
          id: row.id,
          talentId: row.talentId,
          index,
          name: row.name,
          recommDetail: recomm,
          recommStage: classifyRecommendationStage(recomm),
        };
      },
    );

    pageCache.set(page, 'recommendations', recommendations);

    return recommendations;
  } finally {
    setLoading(false);
  }
}

export async function fetchTalentRecommendations(
  props: RouteComponentProps & { token: string },
  id: string,
  setLoading: (loading: boolean) => void,
  purgeCache: boolean,
): Promise<TalentCCRowModel[]> {
  const page = props.match.url;

  if (!purgeCache) {
    const cache = pageCache.get(page, 'recommendations');
    if (cache) return cache as TalentCCRowModel[];
  }

  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl(`/admin/talents/${id}/recommendations`),
      {
        method: 'GET',
        headers: {
          'Api-Token': props.token,
        },
      },
    );

    if (response.status !== 200) throw response;

    const recommendations = ((await response.json()) as any[]).map(
      (row, index) => {
        const recomm = {
          date: new Date(row.date),
          closed: row.closed,
          positionDetail: {
            position: row.position,
            score: row.score,
          },
          profileDetail:
            Number.isInteger(row.firstImpression) &&
            Number.isInteger(row.skillSetSuitability) &&
            Number.isInteger(row.talentSimilarity)
              ? {
                  firstImpression: row.firstImpression,
                  skillSetSuitability: row.skillSetSuitability,
                  talentSimilarity: row.talentSimilarity,
                }
              : undefined,
          intentDetail: row.intentDetail
            ? {
                ...row.intentDetail,
                date: new Date(row.intentDetail.date),
              }
            : undefined,
          offerDetail: row.offerDetail
            ? {
                ...row.offerDetail,
                date: new Date(row.offerDetail.date),
                times: row.offerDetail.times.map((time: { date: string }) => ({
                  ...time,
                  date: new Date(time.date),
                })),
              }
            : undefined,
          counterOfferDetail: row.counterOfferDetail
            ? {
                ...row.counterOfferDetail,
                date: new Date(row.counterOfferDetail.date),
                times: row.counterOfferDetail.times.map(
                  (time: { date: string }) => ({
                    ...time,
                    date: new Date(time.date),
                  }),
                ),
              }
            : undefined,
          interviewDetail: row.interviewDetail
            ? {
                ...row.interviewDetail,
                date: new Date(row.interviewDetail.date),
              }
            : undefined,
          interviewReviewDetail: row.interviewReviewDetail
            ? {
                ...row.interviewReviewDetail,
                date: new Date(row.interviewReviewDetail.date),
              }
            : undefined,
          resultDetail: row.resultDetail
            ? {
                ...row.resultDetail,
                date: new Date(row.resultDetail.date),
              }
            : undefined,
        };

        return {
          id: row.id,
          ccId: row.ccId,
          index,
          name: row.name,
          recommDetail: recomm,
          recommStage: classifyRecommendationStage(recomm),
        };
      },
    );

    pageCache.set(page, 'recommendations', recommendations);

    return recommendations;
  } finally {
    setLoading(false);
  }
}

export async function updateTalentAllowedForRecommendation(
  props: { token: string },
  id: string,
  allowed: boolean,
  setLoading: (loading: boolean) => void,
): Promise<void> {
  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl(`/admin/talents/${id}/allowedForRecommendation`),
      {
        method: 'PUT',
        headers: {
          'Api-Token': props.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          allowedForRecommendation: allowed,
        }),
      },
    );

    if (response.status !== 200) throw response;
  } finally {
    setLoading(false);
  }

  pageCache.purge();
}

export async function updateTalentMembership(
  props: { token: string },
  id: string,
  membership: string,
  setLoading: (loading: boolean) => void,
): Promise<void> {
  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl(`/admin/talents/${id}/membership`),
      {
        method: 'PUT',
        headers: {
          'Api-Token': props.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          membership: membership,
        }),
      },
    );

    if (response.status !== 200) throw response;
  } finally {
    setLoading(false);
  }

  pageCache.purge();
}

export async function removeRecommendation(
  props: { token: string },
  id: number,
  setLoading: (loading: boolean) => void,
): Promise<void> {
  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl(`/admin/recommendations/${id}`),
      {
        method: 'DELETE',
        headers: {
          'Api-Token': props.token,
        },
      },
    );

    if (response.status !== 200) throw response;
  } finally {
    setLoading(false);
  }

  pageCache.purge();
}

export async function fetchCCApplications(
  props: RouteComponentProps & { token: string },
  setLoading: (loading: boolean) => void,
  purgeCache: boolean,
): Promise<CCApplicationRowModel[]> {
  const page = props.match.url;

  if (!purgeCache) {
    const cache = pageCache.get(page, 'applications');
    if (cache) return cache as CCApplicationRowModel[];
  }

  setLoading(true);

  try {
    const response = await fetch(generateApiUrl('/admin/applications/ccs'), {
      method: 'GET',
      headers: {
        'Api-Token': props.token,
      },
    });

    if (response.status !== 200) throw response;

    const applications = await response.json();

    for (const application of applications)
      application.appliedAt = new Date(application.appliedAt);

    pageCache.set(page, 'applications', applications);

    return applications;
  } finally {
    setLoading(false);
  }
}

export async function queryCCApplications(
  props: { token: string },
  ids: number[],
): Promise<CCApplicationRowModel[]> {
  const response = await fetch(
    generateApiUrl('/admin/applications/ccs/query'),
    {
      method: 'POST',
      headers: {
        'Api-Token': props.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ids,
      }),
    },
  );

  if (response.status !== 201) throw response;

  const applications = await response.json();

  for (const application of applications)
    application.appliedAt = new Date(application.appliedAt);

  return applications;
}

export async function fetchTalentApplications(
  props: RouteComponentProps & { token: string },
  setLoading: (loading: boolean) => void,
  purgeCache: boolean,
): Promise<TalentApplicationRowModel[]> {
  const page = props.match.url;

  if (!purgeCache) {
    const cache = pageCache.get(page, 'applications');
    if (cache) return cache as TalentApplicationRowModel[];
  }

  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl('/admin/applications/talents'),
      {
        method: 'GET',
        headers: {
          'Api-Token': props.token,
        },
      },
    );

    if (response.status !== 200) throw response;

    const applications = await response.json();

    for (const application of applications) {
      application.birth = new Date(application.birth);
      application.codingTestDate = application.codingTestDate
        ? new Date(application.codingTestDate)
        : null;
      application.codingTestSentDate = application.codingTestSentDate
        ? new Date(application.codingTestSentDate)
        : null;
      application.appliedAt = new Date(application.appliedAt);
    }

    pageCache.set(page, 'applications', applications);

    return applications;
  } finally {
    setLoading(false);
  }
}

export async function queryTalentApplications(
  props: { token: string },
  ids: number[],
): Promise<TalentApplicationRowModel[]> {
  const response = await fetch(
    generateApiUrl('/admin/applications/talents/query'),
    {
      method: 'POST',
      headers: {
        'Api-Token': props.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ids,
      }),
    },
  );

  if (response.status !== 201) throw response;

  const applications = await response.json();

  for (const application of applications) {
    application.birth = new Date(application.birth);
    application.codingTestDate = application.codingTestDate
      ? new Date(application.codingTestDate)
      : null;
    application.codingTestSentDate = application.codingTestSentDate
      ? new Date(application.codingTestSentDate)
      : null;
    application.appliedAt = new Date(application.appliedAt);
  }

  return applications;
}

export async function testTalentApplication(
  props: RouteComponentProps & { token: string },
  id: number,
  testId: number,
  cutoffScore: number,
  setLoading: (loading: boolean) => void,
): Promise<void> {
  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl('/admin/applications/talents/testing'),
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Api-Token': props.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          testId,
          cutoffScore,
        }),
      },
    );

    if (response.status !== 201) throw response;
  } finally {
    setLoading(false);
  }
}

export async function sendServiceGuideCCApplication(
  props: RouteComponentProps & { token: string },
  id: number,
  setLoading: (loading: boolean) => void,
): Promise<void> {
  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl('/admin/applications/ccs/sent-service-guide'),
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Api-Token': props.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
        }),
      },
    );

    if (response.status !== 201) throw response;
  } finally {
    setLoading(false);
  }
}

export async function sendPricingCCApplication(
  props: RouteComponentProps & { token: string },
  id: number,
  setLoading: (loading: boolean) => void,
): Promise<void> {
  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl('/admin/applications/ccs/sent-pricing'),
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Api-Token': props.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
        }),
      },
    );

    if (response.status !== 201) throw response;
  } finally {
    setLoading(false);
  }
}

export async function approveCCApplication(
  props: RouteComponentProps & { token: string },
  id: number,
  setLoading: (loading: boolean) => void,
): Promise<void> {
  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl('/admin/applications/ccs/approved'),
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Api-Token': props.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
        }),
      },
    );

    if (response.status !== 201) throw response;
  } finally {
    setLoading(false);
  }
}

export async function approveTalentApplication(
  props: RouteComponentProps & { token: string },
  id: number,
  setLoading: (loading: boolean) => void,
): Promise<void> {
  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl('/admin/applications/talents/approved'),
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Api-Token': props.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
        }),
      },
    );

    if (response.status !== 201) throw response;
  } finally {
    setLoading(false);
  }
}

export async function rejectCCApplication(
  props: RouteComponentProps & { token: string },
  id: number,
  reason: CCApplicationRejectReason | null,
  setLoading: (loading: boolean) => void,
): Promise<void> {
  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl('/admin/applications/ccs/rejected'),
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Api-Token': props.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          rejectReason: reason ?? undefined,
        }),
      },
    );

    if (response.status !== 201) throw response;
  } finally {
    setLoading(false);
  }
}

export async function rejectTalentApplication(
  props: RouteComponentProps & { token: string },
  id: number,
  reason: TalentApplicationRejectReason | null,
  setLoading: (loading: boolean) => void,
): Promise<void> {
  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl('/admin/applications/talents/rejected'),
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Api-Token': props.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          rejectReason: reason ?? undefined,
        }),
      },
    );

    if (response.status !== 201) throw response;
  } finally {
    setLoading(false);
  }
}

// export async function fetchTests(
//   props: RouteComponentProps & { token: string },
//   setLoading: (loading: boolean) => void,
//   purgeCache: boolean,
// ): Promise<{ saved: any[]; available: any[] }> {
//   const page = props.match.url;

//   if (!purgeCache) {
//     const cache = pageCache.get(page, 'tests');
//     if (cache) return cache as any;
//   }

//   setLoading(true);

//   try {
//     const response = await fetch(
//       generateApiUrl('/admin/settings/hackerrank-tests'),
//       {
//         method: 'GET',
//         headers: {
//           Accept: 'application/json',
//           'Api-Token': props.token,
//           'Content-Type': 'application/json',
//         },
//       },
//     );

//     if (response.status !== 200) throw response;

//     const result = await response.json();

//     for (const test of result.available) {
//       test.createdAt = new Date(test.createdAt);
//     }

//     pageCache.set(page, 'tests', result);

//     return result;
//   } finally {
//     setLoading(false);
//   }
// }

// export async function addTest(
//   props: RouteComponentProps & { token: string },
//   test: {
//     id: number;
//     name: string;
//     maxScore: number;
//     questions: number;
//   },
//   cutoffScore: number,
//   setLoading: (loading: boolean) => void,
// ): Promise<void> {
//   setLoading(true);

//   try {
//     const response = await fetch(
//       generateApiUrl('/admin/settings/hackerrank-tests'),
//       {
//         method: 'POST',
//         headers: {
//           Accept: 'application/json',
//           'Api-Token': props.token,
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           testId: test.id,
//           name: test.name,
//           maxScore: test.maxScore,
//           questions: test.questions,
//           cutoffScore,
//         }),
//       },
//     );

//     if (response.status !== 201) throw response;
//   } finally {
//     setLoading(false);
//   }
// }

// export async function deleteTest(
//   props: RouteComponentProps & { token: string },
//   id: number,
//   setLoading: (loading: boolean) => void,
// ): Promise<void> {
//   setLoading(true);

//   try {
//     const response = await fetch(
//       generateApiUrl(`/admin/settings/hackerrank-tests/${id}`),
//       {
//         method: 'DELETE',
//         headers: {
//           Accept: 'application/json',
//           'Api-Token': props.token,
//           'Content-Type': 'application/json',
//         },
//       },
//     );

//     if (response.status !== 200) throw response;
//   } finally {
//     setLoading(false);
//   }
// }

// export async function activateTest(
//   props: RouteComponentProps & { token: string },
//   id: number,
//   setLoading: (loading: boolean) => void,
// ): Promise<void> {
//   setLoading(true);

//   try {
//     const response = await fetch(
//       generateApiUrl('/admin/settings/hackerrank-tests/activated'),
//       {
//         method: 'PUT',
//         headers: {
//           Accept: 'application/json',
//           'Api-Token': props.token,
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           id,
//         }),
//       },
//     );

//     if (response.status !== 200) throw response;
//   } finally {
//     setLoading(false);
//   }
// }

export async function fetchNotifications(
  props: RouteComponentProps & { token: string },
  setLoading: (loading: boolean) => void,
  purgeCache: boolean,
): Promise<{
  triggers: {
    admin: any[];
    platform: any[];
  };
  notifications: {
    id: string;
    trigger: any;
    emailTemplateName?: string;
    talkTemplateName?: string;
  }[];
  templates: {
    emails: {
      templateId: string;
      name: string;
      subject: string;
      content: string;
      createdAt: Date;
    }[];
    notifications: {
      templateId: string;
      name: string;
      content: string;
      buttons: string;
      createdAt: Date;
    }[];
  };
}> {
  const page = props.match.url;

  if (!purgeCache) {
    const cache = pageCache.get(page, 'notifications');
    if (cache) return cache as any;
  }

  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl('/admin/settings/notifications'),
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Api-Token': props.token,
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status !== 200) throw response;

    const result = await response.json();

    for (const email of result.templates.emails) {
      email.templateId = String(email.templateId);
      email.createdAt = new Date(email.createdAt);
    }

    for (const notification of result.templates.notifications)
      notification.createdAt = new Date(notification.createdAt);

    pageCache.set(page, 'notifications', result);

    return result;
  } finally {
    setLoading(false);
  }
}

export async function addNotification(
  props: RouteComponentProps & { token: string },
  trigger: string,
  email:
    | {
        id: string;
        name: string;
        subject: string;
        content: string;
      }
    | undefined,
  notification:
    | {
        id: string;
        name: string;
        content: string;
        buttons: string;
        // failoverTitle: string;
        // failoverContent: string;
      }
    | undefined,
  setLoading: (loading: boolean) => void,
): Promise<void> {
  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl('/admin/settings/notifications'),
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Api-Token': props.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          trigger,
          emailTemplateId: email?.id,
          emailTemplateName: email?.name,
          emailSubject: email?.subject,
          emailContent: email?.content,
          talkTemplateId: notification?.id,
          talkTemplateName: notification?.name,
          talkContent: notification?.content,
          talkButtons: notification?.buttons,
          talkFailoverTitle: '탤런트엑스',
          talkFailoverContent: notification?.content,
        }),
      },
    );

    if (response.status !== 201) throw response;
  } finally {
    setLoading(false);
  }
}

export async function deleteNotification(
  props: RouteComponentProps & { token: string },
  id: string,
  setLoading: (loading: boolean) => void,
): Promise<void> {
  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl(`/admin/settings/notifications/${id}`),
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Api-Token': props.token,
        },
      },
    );

    if (response.status !== 200) throw response;
  } finally {
    setLoading(false);
  }
}

export async function deleteCCApplication(
  props: RouteComponentProps & { token: string },
  id: number,
  setLoading: (loading: boolean) => void,
): Promise<void> {
  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl(`/admin/applications/ccs/${id}`),
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Api-Token': props.token,
        },
      },
    );

    if (response.status !== 200) throw response;
  } finally {
    setLoading(false);
  }
}

export async function deleteTalentApplication(
  props: RouteComponentProps & { token: string },
  id: number,
  setLoading: (loading: boolean) => void,
): Promise<void> {
  setLoading(true);

  try {
    const response = await fetch(
      generateApiUrl(`/admin/applications/talents/${id}`),
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Api-Token': props.token,
        },
      },
    );

    if (response.status !== 200) throw response;
  } finally {
    setLoading(false);
  }
}

export async function listRecommCandidates(
  props: { token: string },
  talentId: number,
  prefix: string,
): Promise<
  {
    id: number;
    name: string;
    logoUrl: string | undefined;
    availability: boolean;
  }[]
> {
  const response = await fetch(
    generateApiUrl(
      `/admin/recommendations/${talentId}/ccs?${new URLSearchParams({
        'cc-prefix': prefix,
      })}`,
    ),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Api-Token': props.token,
      },
    },
  );

  if (response.status !== 200) throw response;

  return await response.json();
}

export async function listCandidatePositions(
  props: { token: string },
  talentId: number,
  ccId: number,
): Promise<
  {
    position: string;
    hiring: boolean;
    lowYears: number;
    highYears: number;
    lowSalary: number;
    highSalary: number;
    score: number;
    positionScore: number;
    skillScore: number;
    yearsScore: number;
    salaryScore: number;
  }[]
> {
  const response = await fetch(
    generateApiUrl(`/admin/recommendations/${talentId}/ccs/${ccId}`),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Api-Token': props.token,
      },
    },
  );

  if (response.status !== 200) throw response;

  return await response.json();
}

export async function generateRecommendations(
  props: { token: string },
  talentId: number,
  ccId: number,
  position: string,
): Promise<void> {
  const response = await fetch(
    generateApiUrl(`/admin/recommendations/${talentId}/ccs/${ccId}`),
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Api-Token': props.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        position,
      }),
    },
  );

  if (response.status !== 201) throw response;
}
