import {
  createMuiTheme,
  styled as mstyled,
  Backdrop,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  ThemeProvider,
  TextField,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React, { ReactElement, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

// import { activateTest, addTest, deleteTest, fetchTests } from '../helpers/Api';

import IconStarOn from 'img/icon-toggle-star-on-20.svg';
import IconStarOff from 'img/icon-toggle-star-off-20.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 960px;
  overflow-x: auto;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  padding-top: 16px;

  & > * + * {
    margin-left: 8px;
  }
`;
const Splitter = styled.hr`
  margin: 20px 0 0;
  border: none;
  height: 1px;
  background-color: #dddddd;
`;
const TestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 20px;
`;
const TestHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: solid 1px #999999;
  border-radius: 4px 4px 0 0;
  padding: 0 20px;
  height: 56px;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;

  & > * + * {
    margin-left: 8px;
  }
`;
const TestHeaderNameText = styled.p`
  flex-basis: 344px;
  flex-grow: 1;
  flex-shrink: 1;
  color: #222222;
`;
const TestHeaderCutoffScoreText = styled.p`
  flex-basis: 108px;
  flex-grow: 0;
  flex-shrink: 0;
  color: #222222;
`;
const TestHeaderMaxScoreText = styled.p`
  flex-basis: 84px;
  flex-grow: 0;
  flex-shrink: 0;
  color: #999999;
`;
const TestHeaderQuestionsText = styled.p`
  flex-basis: 130px;
  flex-grow: 0;
  flex-shrink: 0;
  color: #999999;
`;
const TestItem = styled.div`
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: solid 1px #999999;
  border-top: 0;
  padding: 0 16px;
  height: 48px;

  &:last-of-type {
    border-radius: 0 0 4px 4px;
  }

  & > * + * {
    margin-left: 8px;
  }
`;
const TestItemActivateButton = styled.img`
  cursor: pointer;
`;
const TestItemNameText = styled.h2`
  flex-basis: 324px;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: #222222;
`;
const TestItemCutoffScoreText = styled.p`
  flex-basis: 108px;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  color: #222222;
`;
const TestItemBlueText = styled.span`
  font-weight: bold;
  color: #0c46f2; ;
`;
const TestItemMaxScoreText = styled.p`
  flex-basis: 84px;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  color: #999999;
`;
const TestItemQuestionsText = styled.p`
  flex-basis: 62px;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  color: #999999;
`;

const TestSelect = mstyled(Select)({
  flexBasis: 0,
  flexGrow: 1,
  flexShrink: 1,
});
const CutoffTextField = mstyled(TextField)({
  flexBasis: 'auto',
  flexGrow: 0,
  flexShrink: 0,
  width: '125px',
});
const AddButton = mstyled(Button)({
  flexBasis: 'auto',
  flexGrow: 0,
  flexShrink: 0,
  width: '102px',
  height: 'auto',
  color: 'white',
});
const BackgroundCover = mstyled(Backdrop)({
  color: 'black',
  zIndex: 1,
});

export default withRouter(function CodingTestSetting(
  props: RouteComponentProps & {
    token: string;
    registerOnRefresh(listener: () => void): void;
  },
): ReactElement {
  const [loading, setLoading] = useState(0);
  const [tests, setTests] = useState<null | {
    saved: {
      id: number;
      testId: number;
      name: string;
      cutoffScore: number;
      maxScore: number;
      questions: number;
      activated: boolean;
    }[];
    available: {
      id: number;
      name: string;
      duration: number;
      instructions: string;
      questions: number;
      maxScore: number;
      createdAt: Date;
    }[];
  }>(null);
  const [selectedTestIndex, setSelectedTestIndex] = useState<'' | number>('');
  const [cutoffScore, setCutoffScore] = useState<number>(0);

  // const handleClickAddButton = () => {
  //   (async () => {
  //     if (!tests) return;
  //     if (typeof selectedTestIndex !== 'number') return;

  //     try {
  //       await addTest(
  //         props,
  //         tests.available[selectedTestIndex],
  //         cutoffScore,
  //         (loading) =>
  //           setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
  //       );
  //       updateData(true);
  //     } catch (response) {
  //       console.log(await response.text());
  //       if (response.status === 401) props.history.push('/login');
  //       else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
  //     }
  //   })();
  // };
  // const handleClickRemoveButton = (id: number) => {
  //   (async () => {
  //     try {
  //       await deleteTest(props, id, (loading) =>
  //         setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
  //       );
  //       updateData(true);
  //     } catch (response) {
  //       console.log(await response.text());
  //       if (response.status === 401) props.history.push('/login');
  //       else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
  //     }
  //   })();
  // };
  // const handleClickActivateButton = (id: number) => {
  //   (async () => {
  //     try {
  //       await activateTest(props, id, (loading) =>
  //         setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
  //       );
  //       updateData(true);
  //     } catch (response) {
  //       console.log(await response.text());
  //       if (response.status === 401) props.history.push('/login');
  //       else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
  //     }
  //   })();
  // };

  // const updateData = async (purgeCache?: boolean) => {
  //   try {
  //     const tests = await fetchTests(
  //       props,
  //       (loading) =>
  //         setLoading((prevLoading) => prevLoading + (loading ? 1 : -1)),
  //       purgeCache ?? false,
  //     );
  //     setTests(tests);
  //   } catch (response) {
  //     if (response.status === 401) props.history.push('/login');
  //     else alert('문제가 발생했습니다. 관리자에게 문의해주세요.');
  //   }
  // };

  useEffect(() => {
    props.registerOnRefresh(() => {
      // updateData(true);
    });
  }, []);
  useEffect(() => {
    // updateData();
  }, []);

  return (
    <>
      <Wrapper>
        <InputWrapper>
          <TestSelect
            variant="outlined"
            value={selectedTestIndex}
            onChange={(event) =>
              setSelectedTestIndex(event.target.value as number)
            }
            displayEmpty
            fullWidth
          >
            <MenuItem value="" disabled>
              테스트를 선택해주세요
            </MenuItem>
            {tests?.available.map((test, index) => (
              <MenuItem key={test.id} value={index}>
                {test.name} | 문제 {test.questions}개 총 {test.duration}분 |
                총점 {test.maxScore}점
              </MenuItem>
            ))}
          </TestSelect>
          <CutoffTextField
            label="컷오프 점수"
            variant="outlined"
            value={cutoffScore}
            onChange={(event) => {
              const score = Number(event.target.value);
              setCutoffScore(Number.isNaN(score) ? 0 : score);
            }}
          />
          <ThemeProvider
            theme={createMuiTheme({
              palette: {
                primary: grey,
              },
            })}
          >
            <AddButton
              variant="contained"
              color="primary"
              // onClick={handleClickAddButton}
            >
              추가하기
            </AddButton>
          </ThemeProvider>
        </InputWrapper>
        <Splitter />
        <TestWrapper>
          <TestHeader>
            <TestHeaderNameText>코딩테스트 명</TestHeaderNameText>
            <TestHeaderCutoffScoreText>컷오프 점수</TestHeaderCutoffScoreText>
            <TestHeaderMaxScoreText>총점</TestHeaderMaxScoreText>
            <TestHeaderQuestionsText>문항 수</TestHeaderQuestionsText>
          </TestHeader>
          {tests?.saved.map((test) => (
            <TestItem key={test.id}>
              <TestItemActivateButton
                src={test.activated ? IconStarOn : IconStarOff}
                width="20"
                height="20"
                // onClick={() => handleClickActivateButton(test.id)}
              />
              <TestItemNameText>{test.name}</TestItemNameText>
              <TestItemCutoffScoreText>
                <TestItemBlueText>{test.cutoffScore}</TestItemBlueText>점
              </TestItemCutoffScoreText>
              <TestItemMaxScoreText>
                총점 {test.maxScore}점
              </TestItemMaxScoreText>
              <TestItemQuestionsText>
                문항 {test.questions}개
              </TestItemQuestionsText>
              <Button
                variant="text"
                // onClick={() => handleClickRemoveButton(test.id)}
              >
                삭제
              </Button>
            </TestItem>
          ))}
        </TestWrapper>
      </Wrapper>
      <BackgroundCover open={loading !== 0}>
        <CircularProgress />
      </BackgroundCover>
    </>
  );
});
